<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">재고조회</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <!--span class="search-title">일자</span>
            <dx-date-box v-model:value="vars.dataSource.targetdate" /-->
            <span class="search-bar">창고선택</span>
            <dx-lookup
              v-model:value="vars.filter.warehouse"
              :data-source="vars.dataSource.warehouse"
              value-expr="wh_code"
              display-expr="wh_name"
            />

            <span class="search-bar">자산구분</span>
            <dx-lookup
              v-model:value="vars.filter.assetType"
              :data-source="vars.dataSource.assetType"
              value-expr="code_name"
              display-expr="code_name"
            />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchStock()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-tree-list
          height="calc(100vh - 200px)"
          id="id"
          key-expr="id"
          parent-id-expr="parent_id"
          :on-initialized="evt => methods.onGridInitialized(evt, 'stock-list')"
          :data-source="vars.stock.item"
          :show-borders="true"
          :column-auto-width="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
        >
          <dx-column
            data-field="warehouse"
            caption="창고구분"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="item.asset_type"
            caption="자산구분"
            :filter-operations="['contain', '=']"
          />
          <dx-column caption="품목코드" data-field="item_code" />
          <dx-column caption="품명" data-field="item.item_name" />
          <dx-column caption="규격" data-field="item.item_standard" />
          <dx-column caption="현재고" data-field="current_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="할당재고" data-field="assign_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="가용재고" data-field="available_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="안전재고" data-field="item.safety_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="품목대분류" data-field="item.main_category" />
          <dx-column caption="품목중분류" data-field="item.middle_category" />
          <dx-column caption="품목소분류" data-field="item.sub_category" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-tree-list>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxColumnChooser,
} from 'devextreme-vue/tree-list';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import DxLookup from 'devextreme-vue/select-box';
import { getSetupBasicStock } from '../../data-source/setup';
import { reactive, onMounted } from 'vue';
import { baseCodeLoader } from '../../data-source/base';
import authService from '../../auth';
import { loadWarehouse } from '../../utils/data-loader';
import ApiService from '../../utils/api-service';
import moment from 'moment';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxButton,
    DxColumnChooser,
    DxLookup,
  },
  setup() {
    const api = new ApiService('/api/mes/v1/stock/search');
    const router = useRouter();
    const vars = {};
    let targetdate = new Date();
    targetdate.setDate(targetdate.getDate());
    vars.filter = reactive({
      assetType: '',
      warehouse: '',
    });
    vars.stock = reactive({
      grid: null,
      selectedCell: null,
      item: [],
      filter: [],
    });
    vars.dataSource = reactive({
      targetdate: targetdate,
      warehouse: [],
      assetType: [],
    });
    vars.grid = {};

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadWarehouse(vars.dataSource);
      vars.dataSource.warehouse.unshift({ wh_name: '전체', wh_code: '전체' });
      vars.filter.warehouse = vars.dataSource.warehouse[0].wh_code;
      vars.filter.assetType = vars.dataSource.assetType[0].code_name;
    });

    const methods = {
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
      },
      async searchStock() {
        vars.stock.filter = [];
        vars.stock.filter.push({
          name: 'fk_company_id',
          op: 'eq',
          val: authService._user.fk_company_id || 0,
        });

        if (
          vars.filter.warehouse &&
          vars.filter.warehouse != '전체' &&
          vars.filter.warehouse != ''
        ) {
          vars.stock.filter.push({
            name: 'wh_code',
            op: 'eq',
            val: vars.filter.warehouse,
          });
        }

        if (
          vars.filter.assetType &&
          vars.filter.assetType != '전체' &&
          vars.filter.assetType != ''
        ) {
          vars.stock.filter.push({
            name: 'item',
            op: 'has',
            val: {
              name: 'asset_type',
              op: 'eq',
              val: vars.filter.assetType,
            },
          });
        }

        //vars.stock.item = getSetupBasicStock(vars.stock.filter);
        const params = {
          warehouse: vars.filter.warehouse,
          asset_type: vars.filter.assetType,
        };
        const { data } = await api.post('', params);
        vars.stock.item = [...data.data];
      },
      loadBaseCode() {
        return baseCodeLoader(['자산구분'], authService.getCompanyId()).then(
          response => {
            vars.dataSource.assetType = response['자산구분'];
            vars.dataSource.assetType.unshift({ code_name: '전체' });
          }
        );
      },
    };

    return { vars, methods };
  },
};
</script>
