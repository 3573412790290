<template>
  <dx-data-grid
    :data-source="baseClient"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @initialized="onInitialized"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="alias" caption="업체약칭" data-type="string" />
    <dx-column data-field="name" caption="업체명" data-type="string" />
    <dx-column
      data-field="business_number"
      caption="사업자번호"
      data-type="string"
    />
    <dx-column data-field="ceo_name" caption="대표자" data-type="string" />
    <dx-column data-field="address" caption="주소" data-type="string" />
    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { baseClient } from '../../data-source/base';
import { ref, watch } from 'vue'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object
    }
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };
    let component = undefined

    function onInitialized (e) {
      component = e.component
      setFilter()
    }

    function setFilter () {
      if (!component) return
      if (!props.filters || !props.filters.name) {
        component.clearFilter()
        return
      }
      component.filter(['name', 'contain', props.filters.name])
    }

    watch(
      () => props.filters,
      () => setFilter()
    );

    return {
      mode: props.multiple ? 'multiple' : 'single',
      baseClient,
      onSelectionChanged, onInitialized
    };
  },
};
</script>
