<template>
  <dx-data-grid
    :data-source="purchaseOrderPlan"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="order_plan_number" caption="발주계획번호" />
    <dx-column
      data-field="order_plan_date"
      caption="발주계획일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="order_plan_department" caption="발주계획부서" />
    <dx-column data-field="order_plan_manager" caption="발주계획담당자" />
    <dx-column data-field="note" caption="참고사항" />
    <dx-column data-field="etc" caption="비고" />
    <dx-column
      data-field="supply_price"
      caption="공급가"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="vat"
      caption="부가세"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="total_price"
      caption="합계금액"
      data-type="number"
      format="currency"
    />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { purchaseOrderPlan } from '../../data-source/purchase';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      purchaseOrderPlan,
      onSelectionChanged,
    };
  },
};
</script>
