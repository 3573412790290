<template>
  <div>
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">BOM 관리</div>
            </dx-item>
          </dx-toolbar>
        </div>
        <div class="standard-code-container">
          <div class="standard-code-body">
            <div class="standard-code-item">
              <dx-data-grid
                height="calc(100vh - 150px)"
                :data-source="baseItem"
                :column-auto-width="true"
                :show-borders="true"
                :show-row-lines="true"
                :remote-operations="true"
                :focused-row-enabled="true"
                :on-initialized="
                  evt => methods.onGridInitialized(evt, 'base-bom')
                "
              >
                <dx-editing
                  :allow-adding="false"
                  :allow-updating="false"
                  :allow-deleting="false"
                  :use-icons="true"
                />
                <dx-filter-row :visible="true" />
                <dx-paging :page-size="20" />
                <dx-row-dragging
                  :allow-drop-inside-item="false"
                  :allow-reordering="false"
                  :show-drag-icons="false"
                  group="bom"
                />

                <dx-column
                  data-field="id"
                  caption="ITEM ID"
                  :visible="false"
                  :allow-sorting="false"
                >
                </dx-column>
                <dx-column
                  data-field="created"
                  caption="생성일"
                  :visible="false"
                >
                </dx-column>
                <dx-column
                  data-field="item_code"
                  caption="품목코드"
                  :allow-sorting="false"
                />
                <dx-column
                  data-field="item_name"
                  caption="품명"
                  :allow-sorting="false"
                />
                <dx-column data-field="item_standard" caption="규격" />
              </dx-data-grid>
            </div>
            <div class="bom-editor">
              <!-- 버튼 -->
              <div class=""></div>

              <!-- BOM 정보 -->
              <div class="">
                <dx-form
                  :form-data="vars.formData"
                  @initialized="
                    evt => methods.onGridInitialized(evt, 'base-bom-form')
                  "
                  @field-data-changed="methods.onFieldDataChanged"
                >
                  <dx-group-item :col-count="2">
                    <dx-simple-item
                      data-field="item.item_code"
                      :editor-options="{
                        buttons: [
                          {
                            name: 'search',
                            location: 'after',
                            options: {
                              stylingMode: 'text',
                              icon: 'search',
                              onClick: () => (vars.dlg.addItem.show = true),
                            },
                          },
                        ],
                      }"
                    >
                      <dx-label text="품목코드" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="bom_depth"
                      editor-type="dxSelectBox"
                      :editor-options="{
                        dataSource: [
                          { value: 0, text: '사용안함' },
                          { value: 1, text: '사용함' },
                        ],
                        displayExpr: 'text',
                        valueExpr: 'value',
                        readOnly: !vars.formData.id,
                      }"
                    >
                      <dx-label text="원자재 사용여부" :show-colon="false" />
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-group-item :col-count="3">
                    <dx-simple-item
                      data-field="item.item_name"
                      :editor-options="{ readOnly: true }"
                    >
                      <dx-label text="품명" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="item.item_standard"
                      :editor-options="{ readOnly: true }"
                    >
                      <dx-label text="규격" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="item.asset_type"
                      :editor-options="{ readOnly: true }"
                    >
                      <dx-label text="자산구분" :show-colon="false" />
                    </dx-simple-item>
                  </dx-group-item>
                </dx-form>
              </div>

              <!-- 품목 정보 -->
              <div class="bom-tree">
                <dx-tree-list
                  height="calc(100vh - 500px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'base-bom-tree')
                  "
                  :data-source="vars.bomTreeList"
                  :columns="vars.bomTreeColumns"
                  :show-borders="true"
                  :column-auto-width="true"
                  :auto-expand-all="true"
                  :focused-row-enabled="true"
                  :editing="{
                    allowUpdating: true,
                    allowDeleting: true,
                    useIcons: true,
                    mode: 'row',
                  }"
                  key-expr="treekey"
                  items-expr="children"
                  data-structure="tree"
                  @focused-row-changed="methods.onFocusedRowChanged"
                  @editing-start="methods.editingStart"
                  @row-updating="methods.rowUpdating"
                  @row-updated="methods.rowUpdated"
                  @row-removing="methods.rowRemoving"
                  @row-removed="methods.rowRemoved"
                >
                  <dx-row-dragging
                    :allow-drop-inside-item="true"
                    :allow-reordering="false"
                    :show-drag-icons="false"
                    :on-add="methods.onAddItemToBom"
                    group="bom"
                  />
                </dx-tree-list>
              </div>

              <!-- 공정관리 -->
              <div class="bom-process-grid">
                <dx-data-grid
                  height="228px"
                  :data-source="baseBomProcess"
                  :column-auto-width="true"
                  :show-borders="true"
                  :show-row-lines="true"
                  :focused-row-enabled="true"
                  :editing="{
                    allowAdding: false,
                    allowUpdating: false,
                    allowDeleting: false,
                    useIcons: true,
                    mode: 'row',
                  }"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'base-bom-process')
                  "
                  @init-new-row="methods.newBomProcess"
                >
                  <dx-paging :enabled="true" :page-size="1000" />

                  <dx-column
                    data-field="process.process_code"
                    caption="공정코드"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="process_id"
                    caption="공정명"
                    :allow-editing="true"
                    :set-cell-value="methods.setBomProcessItem"
                  >
                    <dx-lookup
                      :data-source="vars.common.process"
                      display-expr="process_name"
                      value-expr="id"
                    />
                  </dx-column>
                  <dx-column
                    data-field="process.ct"
                    caption="C/T"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="process.unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="process.unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="check_yn"
                    caption="검사여부"
                    data-type="boolean"
                    :allow-editing="true"
                  />
                  <dx-column
                    data-field="outsource_yn"
                    caption="외주발주여부"
                    data-type="boolean"
                    :allow-editing="true"
                  />
                  <dx-column
                    data-field="order"
                    caption="공정순서"
                    data-type="number"
                    :allow-editing="true"
                  />
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목선택"
      :close-on-outside-click="true"
      :width="680"
      :height="460"
      :resize-enabled="true"
    >
      <template #popup-content>
        <dx-data-grid
          :on-initialized="
            evt => methods.onGridInitialized(evt, 'base_bom_item_in_popup')
          "
          :data-source="vars.dlg.addItem.dataSource"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
          :hover-state-enabled="true"
          @row-click="methods.selectBaseItem"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxRowDragging,
  DxLookup,
} from 'devextreme-vue/data-grid';
import { DxTreeList } from 'devextreme-vue/tree-list';
import { DxPopup } from 'devextreme-vue/popup';
import notify from 'devextreme/ui/notify';
import authService from '@/auth';
import {
  baseItem,
  baseBom,
  baseBomLink,
  baseBomProcess,
  baseProcess,
} from '@/data-source/base';
import { ref, reactive } from '@vue/reactivity';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTreeList,
    DxPopup,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxFilterRow,
    DxRowDragging,
    DxLookup,
    DxLoadPanel,
  },
  setup() {
    const vars = {},
      methods = {};
    baseItem.defaultFilters = [
      { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
    ];
    baseBom.defaultFilters = [
      { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
    ];
    baseBomProcess.defaultFilters = [{ name: 'bom_id', op: 'eq', val: 0 }];

    // Vars
    vars.dataGridInstance = {};
    vars.loading = ref(false);
    vars.dlg = {};
    vars.dlg.addItem = reactive({ show: false, dataSource: baseItem.clone() });
    vars.formData = reactive({
      id: null,
    });
    vars.bomTreeList = reactive([]);
    vars.common = reactive({ process: [] });
    vars.bomTreeColumns = [
      {
        dataField: 'id',
        caption: 'BOM ID',
        visible: false,
        allowEditing: false,
      },
      { dataField: 'item.item_code', caption: '품목코드', allowEditing: false },
      { dataField: 'item.item_name', caption: '품명', allowEditing: false },
      { dataField: 'item.item_standard', caption: '규격', allowEditing: false },
      { dataField: 'lrate', caption: 'L/Rate', dataType: 'number' },
      { dataField: 'requirement', caption: '소요량', dataType: 'number' },
      {
        calculateCellValue: row => {
          if (!('lrate' in row)) return '';
          if (row.lrate == 0) {
            return row.requirement;
          } else {
            return row.requirement + row.requirement * (row.lrate / 100.0);
          }
        },
        caption: '총소요량',
        dataType: 'number',
        allowEditing: false,
      },
      {
        dataField: 'item.purchase_price',
        caption: '단가',
        dataType: 'number',
        format: 'currency',
        allowEditing: false,
      },
      {
        calculateCellValue: row => {
          if (!row.item) return 0;
          if (!('requirement' in row)) return row.item.purchase_price;
          if (row.lrate == 0) {
            return row.item.purchase_price * row.requirement;
          } else {
            return (
              row.item.purchase_price *
              (row.requirement + row.requirement * (row.lrate / 100.0))
            );
          }
        },
        caption: '금액',
        dataType: 'number',
        format: 'currency',
        allowEditing: false,
      },
    ];

    baseProcess
      .load()
      .then(response => (vars.common.process = response.data))
      .catch(ex => console.error(ex.message));

    // vars.baseBomItem = baseBom.clone()
    // vars.baseBomItem.defaultFilters = [{name: 'parent_item_id', op: 'is_null'}]
    // vars.baseBomItem.load().then(result => { console.log(result) })

    // Methods
    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.getChildBom = async (parent, parentKeys, prevTreekey) => {
      const result = await baseBomLink.load({
        filter: ['parent_id', '=', parent.id],
        skip: 0,
        take: 1000,
      });
      if (result.data.length) {
        parentKeys.push(parent.id);
        if (!prevTreekey) prevTreekey = String(parent.id);
        parent.children = result.data
          .filter(v => {
            if (prevTreekey.split('-').includes(String(v.child_id))) {
              notify(
                `포함될 수 없는 항목이 있습니다(${v.child_id})`,
                'warning'
              );
              return false;
            }
            return true;
          })
          .map(v => ({
            id: v.child_id,
            treekey: `${prevTreekey}-${v.child_id}`,
            link_id: v.link_id,
            lrate: v.lrate,
            requirement: v.requirement,
          }));

        for (let child of parent.children) {
          let { data } = await baseBom.byKey(child.id);
          Object.assign(child, data);
          console.debug(
            `tree - parent: ${parent.id}, child: ${child.id}, treekey: ${child.treekey}`
          );
          await methods.getChildBom(child, parentKeys, child.treekey);
        }
      }
    };

    methods.selectBomByItemId = async data => {
      let result = await baseBom.load({ filter: ['item_id', '=', data.id] });
      if (!result.data.length) {
        await baseBom.insert({
          item_id: data.id,
          bom_depth: 0,
          fk_company_id: authService.getCompanyId(),
        });
        result = await baseBom.load({ filter: ['item_id', '=', data.id] });
      }
      return result;
    };

    // methods.selectBomById = async (id) => {
    //   let result = await baseBom.load({filter: ['item_id', '=', data.id]})
    //   if (!result.data.length) {
    //     await baseBom.insert({item_id: data.id})
    //     result = await baseBom.load({filter: ['item_id', '=', data.id]})
    //   }
    //   return result
    // }

    methods.selectBaseItem = async evt => {
      const data = evt.data;
      vars.dataGridInstance['base-bom-tree'].beginCustomLoading();
      vars.dataGridInstance['base-bom-tree'].beginUpdate();
      vars.dataGridInstance['base-bom-tree'].option('focusedRowIndex', -1);
      const result = await methods.selectBomByItemId(data);
      result.treekey = result.id;
      const parentKeys = [];
      result.data[0].treekey = result.data[0].id.toString();
      vars.bomTreeList = result.data;

      if (vars.bomTreeList.length) {
        vars.formData = vars.bomTreeList[0];
        if (!evt.key) {
          vars.dataGridInstance['base-bom-form'].beginUpdate();
          vars.dataGridInstance['base-bom-form'].updateData(vars.formData);
          vars.dataGridInstance['base-bom-form'].endUpdate();
        }
        await methods.getChildBom(vars.bomTreeList[0], parentKeys);
      }

      vars.dataGridInstance['base-bom-tree'].endUpdate();
      vars.dataGridInstance['base-bom-tree'].option(
        'dataSource',
        vars.bomTreeList
      );
      // await?
      for (let key of parentKeys)
        vars.dataGridInstance['base-bom-tree'].expandRow(key);
      vars.dlg.addItem.show = false;
      vars.dataGridInstance['base-bom-tree'].endCustomLoading();
    };

    methods.onAddItemToBom = async ({
      dropInsideItem,
      itemData,
      toComponent,
      toIndex,
    }) => {
      if (toIndex === null) return console.warn('toIndex is null');

      if (toIndex === 0 && !vars.formData.id) {
        console.log('item set root');
        methods.selectBaseItem({ data: itemData });
        return;
      }

      let bom = await methods.selectBomByItemId(itemData);
      let bomId = bom.data[0].id;

      if (dropInsideItem) {
        let key = toComponent.getKeyByRowIndex(toIndex);
        if (!key)
          return console.warn(
            `key: ${typeof key}(${key}), toIndex: ${typeof toIndex}(${toIndex})`
          );

        const { data: toData, children: toChildren } = toComponent.getNodeByKey(
          key
        );
        if (key.split('-').includes(String(bomId))) {
          notify('상위에 있는 항목을 하위에 포함할 수 없습니다', 'warning');
          return;
        }

        if (toChildren.map(v => v.data.id).includes(bomId)) {
          notify('동일한 항목을 추가할 수 없습니다', 'warning');
          return;
        }

        console.log(
          `dropInsideItem: ${dropInsideItem}, key: ${key}, parent_id: ${toData.id}, bom: ${bomId}`
        );
        await baseBomLink.insert({
          parent_id: toData.id,
          child_id: bomId,
          root_id: vars.formData.id,
          lrate: 0,
          requirement: 1,
        });
      } else {
        toIndex = toIndex === 0 ? toIndex : toIndex - 1;
        let key = toComponent.getKeyByRowIndex(toIndex);
        let toData = null,
          toChildren = null;
        if (key) {
          const node = toComponent.getNodeByKey(key);
          if (node.parent.data) {
            toData = node.parent.data;
            toChildren = node.parent.children;
          } else {
            toData = node.data;
            toChildren = node.children;
          }

          if (key.split('-').includes(String(bomId))) {
            notify('상위에 있는 항목을 하위에 포함할 수 없습니다', 'warning');
            return;
          }

          if (toChildren.map(v => v.data.id).includes(bomId)) {
            notify('동일한 항목을 추가할 수 없습니다', 'warning');
            return;
          }
        } else {
          notify('다시 한 번 품목을 드롭해 주세요', 'warning');
          return;
        }

        await baseBomLink.insert({
          parent_id: toData.id,
          child_id: bomId,
          root_id: vars.formData.id,
          lrate: 0,
          requirement: 1,
        });
      }
      methods.selectBaseItem({ data: vars.formData.item });
    };

    methods.onFocusedRowChanged = async evt => {
      if (!evt.row) {
        baseBomProcess.defaultFilters = [{ name: 'bom_id', op: 'eq', val: 0 }];
        vars.dataGridInstance['base-bom-process'].refresh();
        vars.dataGridInstance['base-bom-process'].option('editing', {
          allowAdding: false,
          allowUpdating: false,
          allowDeleting: false,
          useIcons: true,
          mode: 'row',
        });
        return;
      }
      const row = evt.row;
      baseBomProcess.defaultFilters = [
        { name: 'bom_id', op: 'eq', val: row.data.id },
      ];
      vars.dataGridInstance['base-bom-process'].refresh();
      vars.dataGridInstance['base-bom-process'].option('editing', {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        useIcons: true,
        mode: 'row',
      });
    };

    methods.editingStart = evt => {
      if (vars.formData.id === evt.data.id) {
        evt.cancel = true;
        notify('최상위 품목은 수정할 수 없습니다.', 'warning');
      }
    };

    methods.rowUpdating = async evt => {
      // TODO:
      // items-expr을 이용하는 tree-list에서 업데이트 시 store의 byKey에서 에러가 발생하는 문제가 있음
      // parent-id-expr을 이용하거나 custom arraystore를 만들어서 해결해야 될 것 같으나 추후 개선
      // 임시로 업데이트 상태를 취소하고 새로 불러오는 걸로 조치
      evt.cancel = true;
      evt.component.cancelEditData();

      console.log('rowUpdating');
      await baseBomLink.update(evt.oldData.link_id, evt.newData);
      methods.selectBaseItem({ data: vars.formData.item });
    };

    methods.rowUpdated = evt => {
      console.log('rowUpdated');
      console.log(evt);
    };

    methods.rowRemoving = evt => {
      if (vars.formData.id === evt.data.id) {
        evt.cancel = true;
        notify('최상위 품목은 삭제할 수 없습니다.', 'warning');
      } else {
        const isCanceled = new Promise((resolve, reject) => {
          baseBomLink
            .remove(evt.data.link_id)
            .then(() => {
              resolve(false);
            })
            .catch(err => {
              reject(err);
            });
        });
        evt.cancel = isCanceled;
      }
    };

    methods.rowRemoved = async ({ key }) => {
      console.log(`${key} removed`);
      methods.selectBaseItem({ data: vars.formData.item });
    };

    methods.newBomProcess = evt => {
      evt.data.bom_id = baseBomProcess.defaultFilters[0].val;
      evt.data.process_id = null;
      evt.data.process = {};
    };

    methods.setBomProcessItem = (newData, value) => {
      const process = vars.common.process.find(v => v.id === value);
      newData.process_id = process.id;
      newData.process = process;
    };

    methods.onFieldDataChanged = async ({ dataField, value }) => {
      if (dataField !== 'bom_depth' || !vars.formData.id) return;
      vars.loading.value = true;
      await baseBom.update(vars.formData.id, { bom_depth: value });
      vars.loading.value = false;
    };

    return {
      vars,
      methods,
      baseItem,
      baseBom,
      baseBomProcess,
    };
  },
};
</script>

<style lang="scss" scoped>
.standard-code-container {
}
.standard-code-body {
  display: flex;
  flex-wrap: nowrap;
}
.standard-code-item {
  width: 350px;
  flex: 0 0 350px;
}
.standard-code-child {
  margin-left: 10px;
}
.bom-editor {
  width: 400px;
  flex: 1 1 auto;
  padding-left: 24px;
}
.bom-tree {
  margin-top: 20px;
}
.bom-process-grid {
  margin-top: 20px;
}
</style>
