<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">발주계획현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">발주계획일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status')"
          :data-source="purchaseOrderPlanItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="order_plan.order_plan_number"
            caption="발주계획번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="order_plan.order_plan_date"
            caption="발주계획일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="main_supplier" caption="주공급업체" />
          <dx-column data-field="end_user" caption="실수요자" />
          <dx-column
            data-field="order_plan.order_plan_department"
            caption="발주계획부서"
          />
          <dx-column
            data-field="order_plan.order_plan_manager"
            caption="발주계획담당자"
          />
          <dx-column data-field="warehouse.wh_name" caption="입고창고" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="order_plan_quantity"
            caption="발주계획수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="unordered_quantity"
            caption="미발주수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
            :calculate-cell-value="methods.calcSupplyPrice"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="order_number" caption="수주번호" />
          <dx-column
            data-field="order_date"
            caption="수주일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column
            data-field="item.safety_stock"
            caption="안전재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="project_number" caption="프로젝트번호" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSorting,
  DxFilterRow,
  DxColumnChooser,
  DxExport,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { purchaseOrderPlanItem } from '../../data-source/purchase';
import { reactive } from 'vue';
import stateStore from '@/utils/state-store';
import moment from 'moment';
import { alert } from 'devextreme/ui/dialog';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxFilterRow,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
    DxExport
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.grid = {};
    vars.formData = reactive({
      startDate: new Date(),
      endDate: new Date(),
    });
    vars.formData.startDate.setDate(vars.formData.startDate.getDate() - 7);

    const methods = {
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`purchase-order-plan-${key}`, evt.component);

        methods.initSorting();
        methods.searchDateRange();
      },
      initSorting() {
        const columns = vars.grid['status'].getVisibleColumns();
        const col = columns.filter(item => item.sortOrder);
        if (col.length == 0) {
          const defaultName = 'order_plan.order_plan_date';
          const defaultSort = columns.filter(item => item.name == defaultName);
          if (defaultSort.length > 0) {
            vars.grid['status'].columnOption(
              defaultSort[0].index,
              'sortOrder',
              'desc'
            );
          }
        }
      },
      goDetail({ data }) {
        router.push({ path: `/purchase/order-plan/${data.order_plan.id}` });
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.order_plan_quantity && rowData.unit_price) {
          supply_price = rowData.order_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        return supply_price;
      },
      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        vars.grid['status'].filter([
          [
            'order_plan',
            'has',
            {
              name: 'order_plan_date',
              op: 'gte',
              val: moment(vars.formData.startDate)
                .startOf('day')
                .format('YYYY-MM-DD 00:00:00'),
            },
          ],
          'and',
          [
            'order_plan',
            'has',
            {
              name: 'order_plan_date',
              op: 'lte',
              val: moment(vars.formData.endDate)
                .startOf('day')
                .format('YYYY-MM-DD 23:59:59'),
            },
          ],
        ]);
      },
      onExporting (evt) {
        purchaseOrderPlanItem.exportData(evt.component, '발주계획현황', `발주계획현황_${Date.now()}.xlsx`)
        evt.cancel = true
      }
    };

    return { vars, methods, purchaseOrderPlanItem };
  },
};
</script>
