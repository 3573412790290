<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before"
              ><div class="content-title">창고관리</div></dx-item
            >
          </dx-toolbar>
        </div>
        <dx-tree-list
          id="warehouse"
          v-model:selected-row-keys="selectedRowKeys"
          :data-source="baseWarehouse"
          :root-value="-1"
          :selection="{ mode: 'single' }"
          :hover-state-enabled="true"
          :show-borders="true"
          :show-row-lines="true"
          :column-auto-width="true"
          :expanded-row-keys="expandedRowKeys"
          :focused-row-enabled="true"
          :on-initialized="evt => methods.onInitialized(evt, 'warehouse')"
          @row-inserting="methods.onRowInserting"
          @row-removing="methods.onRowRemoving"
        >
          <dx-row-dragging
            :on-reorder="methods.onReorder"
            :allow-drop-inside-item="false"
            :allow-reordering="true"
            :show-drag-icons="true"
          />
          <dx-editing
            mode="row"
            :allow-adding="true"
            :allow-updating="true"
            :allow-deleting="true"
            :use-icons="true"
          />
          <dx-paging :enabled="true" :page-size="1000" />
          <dx-column
            data-field="wh_code"
            caption="창고 코드"
            :allow-sorting="false"
            ><dx-required-rule message="창고 코드를 입력하세요"
          /></dx-column>
          <dx-column
            data-field="wh_name"
            caption="창고명"
            :allow-sorting="false"
            ><dx-required-rule message="창고명을 입력하세요"
          /></dx-column>
          <dx-column
            data-field="wh_detail"
            caption="설명"
            :allow-sorting="false"
          />
          <dx-column
            data-field="wh_order"
            caption="순서"
            sort-order="asc"
            :visible="false"
          />
          <dx-column type="buttons">
            <dx-button name="edit" />
            <dx-button name="delete" />
          </dx-column>
        </dx-tree-list>
      </div>
    </div>
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {
  DxTreeList,
  DxColumn,
  DxRowDragging,
  DxEditing,
  DxPaging,
  DxRequiredRule,
  DxButton,
} from 'devextreme-vue/tree-list';
import { ref, reactive, onMounted } from 'vue';
import { baseWarehouse } from '../../data-source/base';
import authService from '../../auth';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxRowDragging,
    DxEditing,
    DxPaging,
    DxToolbar,
    DxItem,
    DxRequiredRule,
    DxButton,
  },
  setup() {
    const vars = {};
    const expandedRowKeys = reactive([1]);
    const selectedRowKeys = reactive([]);
    vars.tree = {
      warehouse: null,
    };

    onMounted(() => {});

    var methods = {
      onInitialized(evt, key) {
        vars.tree[key] = evt.component;
      },
      onRowInserting(e) {
        delete e.data['parentId'];
        e.data['wh_order'] = e.component.totalCount() + 1;
        e.data['fk_company_id'] = authService.getCompanyId();
      },
      onRowRemoving(e) {
        const visibleRows = e.component.getVisibleRows();
        visibleRows.forEach(function(item) {
          if (item.data['wh_order'] > e.data['wh_order']) {
            methods.requestUpdateOrder(
              item.data['id'],
              item.data['wh_order'] - 1
            );
          }
        });
      },
      onReorder(e) {
        const visibleRows = e.component.getVisibleRows();

        let isNewRow = false;
        visibleRows.forEach(function(item) {
          if (item.isNewRow == true) {
            isNewRow = true;
          }
        });
        if (isNewRow == true) return;

        if (e.toIndex > e.fromIndex) {
          for (let i = e.fromIndex + 1; i <= e.toIndex; i++) {
            const itemData = visibleRows[i]['data'];
            methods.requestUpdateOrder(
              itemData['id'],
              itemData['wh_order'] - 1
            );
          }
        } else {
          for (let i = e.toIndex; i < e.fromIndex; i++) {
            const itemData = visibleRows[i]['data'];
            methods.requestUpdateOrder(
              itemData['id'],
              itemData['wh_order'] + 1
            );
          }
        }

        methods.requestUpdateOrder(e.itemData['id'], e.toIndex + 1);
      },
      requestUpdateOrder(id, order) {
        baseWarehouse.update(id, { wh_order: order }).then(() => {
          vars.tree.warehouse.refresh();
        });
      },
    };

    return {
      vars,
      methods,
      baseWarehouse,
      expandedRowKeys,
      selectedRowKeys,
    };
  },
};
</script>

<style></style>
