<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">소요량계산</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'add',
                icon: 'add',
                disabled: vars.disabled.new,
                onClick: methods.newItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'rename',
                icon: 'rename',
                disabled: vars.disabled.edit,
                onClick: methods.editItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'remove',
                icon: 'remove',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'save',
                icon: 'save',
                disabled: vars.disabled.save,
                onClick: methods.saveItem,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  placeholder: '(자동 or 직접입력)',
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn(
                      'measure-requirement',
                      '소요량계산 조회'
                    )
                  ),
                }"
              >
                <dx-label text="소요량 산출번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="purchase_order_plan_number"
                :editor-options="{
                  readOnly: true,
                  buttons: [
                    {
                      name: 'order_plan_number',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'link',
                        disabled: false,
                        onClick: methods.redirectOrderPlanNumber,
                      },
                    },
                  ],
                }"
              >
                <dx-label text="발주계획번호" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel
          :animation-enabled="false"
          :swipe-enabled="false"
          v-model:selected-index="vars.tab.index"
        >
          <dx-item title="생산계획">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item1')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.item1"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  :disabled="vars.disabled.items"
                  @saving="methods.onSavingItem"
                  @data-error-occurred="methods.onDataError"
                  @cell-dbl-click="methods.itemPopupClick"
                  @focused-cell-changed="methods.onFocusedCellChanged"
                >
                  <dx-grid-toolbar>
                    <dx-item
                      location="before"
                      template="addFromPlan"
                      :visible="
                        !vars.formData.closing_yn && !vars.formState.readOnly
                      "
                    />
                    <dx-item
                      name="addRowButton"
                      template="addItem"
                      :visible="
                        !vars.formData.closing_yn && !vars.formState.readOnly
                      "
                    />
                    <dx-item name="saveButton" :visible="!!vars.formData.id" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromPlan>
                    <dx-button
                      icon="add"
                      text="생산계획에서 가져오기"
                      @click="methods.showAddPlanItem"
                    />
                  </template>
                  <template #addItem>
                    <dx-button icon="add" @click="methods.showAddBaseItem" />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.bom_yn"
                    caption="BOM여부"
                    data-type="boolean"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="production_plan_quantity"
                    caption="생산계획수량"
                    data-type="number"
                    format="fixedPoint"
                    :set-cell-value="methods.setQuantity"
                  />
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                    :set-cell-value="methods.setUnitPrice"
                  />
                  <dx-column
                    data-field="item.unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="unordered_quantity"
                    caption="미작지수량"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="basic_stock.available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="basic_stock.current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="warehouse.wh_name"
                    caption="입고창고"
                    :set-cell-value="methods.setWarehouse"
                  >
                    <dx-lookup
                      :data-source="vars.dataSource.warehouse"
                      value-expr="wh_name"
                      display-expr="wh_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="order_number"
                    caption="수주번호"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="project_number"
                    caption="프로젝트번호"
                    :editor-options="{
                      ...generateItemButtonOption(
                        'search',
                        methods.createFindPopupFn('project', '프로젝트조회')
                      ),
                    }"
                  />
                  <dx-column
                    data-field="client_company"
                    caption="고객업체"
                    :editor-options="{
                      ...generateItemButtonOption(
                        'search',
                        methods.createFindPopupFn('client', '고객업체조회')
                      ),
                    }"
                  />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                  />
                  <dx-column
                    data-field="end_user"
                    caption="실수요자"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_detail"
                    caption="품목설명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="fk_measure_requirement_id"
                    caption="소요량계산 아이디"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />
                  <dx-column
                    data-field="fk_plan_item_id"
                    caption="생산계획품목 아이디"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />
                  <dx-column
                    data-field="warehouse_code"
                    caption="창고코드"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />

                  <dx-editing
                    :allow-adding="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    :allow-updating="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    :allow-deleting="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="소요량계산">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item2')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.item2"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :disabled="vars.disabled.items"
                  @init-new-row="methods.onItem2Insert"
                  @cell-dbl-click="methods.itemPopupClick"
                  @focused-cell-changed="methods.onFocusedCellChanged2"
                >
                  <dx-grid-toolbar>
                    <dx-item
                      location="before"
                      template="calculate"
                      :visible="!vars.formData.closing_yn"
                    />
                    <dx-item
                      location="before"
                      template="exportPlan"
                      :visible="!vars.formData.closing_yn"
                    />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #calculate>
                    <dx-button
                      icon="formula"
                      text="소요량 재계산하기"
                      @click="methods.calculateConsume"
                    />
                  </template>
                  <template #exportPlan>
                    <dx-button
                      icon="export"
                      text="발주계획으로 보내기"
                      @click="methods.exportOrderPlan"
                    />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.asset_type"
                    caption="자산구분"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.safety_stock"
                    caption="안전재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.moq"
                    caption="MOQ"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="requirement_quantity"
                    caption="소요량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="order_plan_quantity"
                    caption="발주계획량"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                    :set-cell-value="methods.setQuantity2"
                  />
                  <dx-column
                    data-field="unordered_plan_quantity"
                    caption="미발주 계획수량"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="basic_stock.available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="basic_stock.current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="warehouse.wh_name"
                    caption="입고창고"
                    :set-cell-value="methods.setWarehouse"
                  >
                    <dx-lookup
                      :data-source="vars.dataSource.warehouse"
                      value-expr="wh_name"
                      display-expr="wh_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                    :set-cell-value="methods.setUnitPrice2"
                  />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.main_category"
                    caption="대분류"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.middle_category"
                    caption="중분류"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.sub_category"
                    caption="소분류"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="client_company"
                    caption="공급업체"
                    :editor-options="{
                      ...generateItemButtonOption(
                        'search',
                        methods.createFindPopupFn('client2', '공급업체조회')
                      ),
                    }"
                  />
                  <dx-column data-field="order_number" caption="수주번호" />
                  <dx-column
                    data-field="project_number"
                    caption="프로젝트번호"
                  />
                  <dx-column data-field="end_user" caption="실수요자" />
                  <dx-column
                    data-field="delivery_date"
                    caption="납기일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="fk_measure_requirement_id"
                    caption="소요량계산 아이디"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />
                  <dx-column
                    data-field="fk_plan_item_id"
                    caption="생산계획품목 아이디"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />
                  <dx-column
                    data-field="warehouse_code"
                    caption="창고코드"
                    :visible="false"
                    :allow-editing="false"
                    :show-in-column-chooser="false"
                  />

                  <dx-editing
                    :allow-adding="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    :allow-updating="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    :allow-deleting="
                      !vars.formData.closing_yn && !vars.formState.readOnly
                    "
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>

        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addPlanItem.show"
      content-template="popup-content"
      title="생산계획에서 가져오기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'load-plan-popup')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addPlanItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'planItem')"
          :data-source="vars.dataSource.planItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column
            data-field="production_plan.number"
            caption="생산계획번호"
          />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column
            data-field="production_plan_quantity"
            caption="생산계획수량"
          />
          <dx-column data-field="unordered_quantity" caption="미작지수량" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
          />
          <dx-column data-field="basic_stock.current_stock" caption="현재고" />

          <dx-paging :page-size="20" />
          <dx-selection
            select-all-mode="page"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'base-item-popup')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addBaseItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.availableStock"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.currentStock"
          />

          <dx-paging :page-size="20" />
          <dx-selection
            select-all-mode="page"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'multiuse-popup')"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-else-if="vars.dlg.finder.key === 'client2'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-measure-requirement
          v-else-if="vars.dlg.finder.key === 'measure-requirement'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>

    <popup-item-detail
      v-model:visible="vars.itemDetail.visible"
      :item-id="vars.itemDetail.id"
    />
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar as DxGridToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { confirm, alert } from 'devextreme/ui/dialog';

import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  measureRequirement,
  getMeasureRequirementItem1,
  getMeasureRequirementItem2,
} from '../../data-source/produce';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import { getStock } from '../../data-source/setup';
import { producePlanItem, getProducePlanItem } from '../../data-source/produce';
import { purchaseOrderPlan } from '../../data-source/purchase';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrderPlan from '../../components/purchase/data-order-plan.vue';
import DataGridProject from '../../components/project/data-project.vue';
import DataGridMeasureRequirement from '../../components/produce/data-measure-requirement.vue';
import authService from '../../auth';
import ApiService from '../../utils/api-service';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import {
  loadDepartment,
  loadEmployee,
  loadWarehouse,
} from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';
import PopupItemDetail from '@/components/base/popup-item-detail';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLookup,
    DxGridToolbar,
    DxButton,
    DxTabPanel,
    DataGridClient,
    DataGridOrderPlan,
    DataGridProject,
    DataGridMeasureRequirement,
    PopupItemDetail,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.tab = reactive({
      index: 0,
    });
    vars.formState = reactive({ readOnly: true });
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.addPlanItem = reactive({ show: false });
    vars.grid = {
      baseItem: null,
      item1: null,
      item2: null,
      planItem: null,
    };
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.warehouse = {};
    vars.filter = reactive({
      baseItem: {
        clientId: null,
      },
      item1: [
        { name: 'fk_measure_requirement_id', op: 'eq', val: props.id || 0 },
      ],
      item2: [
        { name: 'fk_measure_requirement_id', op: 'eq', val: props.id || 0 },
      ],
    });
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      order_type: [],
      vat_type: [],
      delivery_place: [],
      client_company: [],
      client_manager: [],
      department: [],
      employee: [],
      warehouse: [],
      baseItem: null,
      planItem: null,
      item1: getMeasureRequirementItem1(vars.filter.item1),
      item2: getMeasureRequirementItem2(vars.filter.item2),
    });
    vars.focus = reactive({
      item1: null,
      item2: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.itemDetail = reactive({ visible: false, id: 0 });

    onMounted(async () => {
      await loadDepartment(vars.dataSource);
      await methods.loadBaseCode();
      methods.initById(props.id);
      loadWarehouse(vars.dataSource);
    });

    // public methods
    const methods = {
      async initById(id) {
        await methods.gridItem1Refresh(id);
        await methods.gridItem2Refresh(id);
        if (!id) {
          methods.clearFormData();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await measureRequirement.byKey(id);
        Object.assign(vars.formData, data);

        methods.enableDelete();
        vars.disabled.edit = false;
        if (vars.formData.department) {
          methods.enableSave();
          vars.disabled.items = false;
        }
      },
      clearFormData() {
        vars.formData.id = null;
        vars.formData.created = null;
        vars.formData.number = '';
        vars.formData.target_date = '';
        vars.formData.department = '';
        vars.formData.manager = '';
        vars.formData.note = '';
        vars.formData.etc = '';
        vars.formData.supply_price = 0;
        vars.formData.vat = 0;
        vars.formData.total_price = 0;
        vars.formData.closing_yn = false;
        vars.formData.purchase_order_plan_number = null;
        vars.formData.fk_company_id = authService.getCompanyId();
      },
      async addPlanItem() {
        const rows = [...(await vars.grid.planItem.getSelectedRowsData())];
        for (let row of rows) {
          // 생산계획
          const grid = vars.grid.item1;
          await grid.addRow();
          const data = await grid.byKey(grid.getKeyByRowIndex(0));
          data.item_code = row.item_code; // 품목코드
          data.item = { ...row.item }; // 품목
          data.production_plan_quantity = row.production_plan_quantity; // 생산계획수량
          data.unit_price = row.unit_price; // 단가
          data.supply_price = row.supply_price; // 공급가
          data.request_delivery_date = row.request_delivery_date; // 요청납기
          data.unordered_quantity = row.unordered_quantity; // 미작지수량
          data.basic_stock = { ...row.basic_stock }; // 기초재고
          data.warehouse = { ...vars.warehouse }; // 입고창고
          data.warehouse_code = vars.warehouse.wh_code; // 창고코드
          data.order_number = row.order_number; // 수주번호
          data.client_company = row.client_company; // 고객업체
          data.client_item_number = row.client_item_number; // 고객사품번
          data.end_user = row.end_user; // 실수요자
          data.project_number = row.project_number; // 프로젝트번호
          data.fk_plan_item_id = row.id; // 생산계획품목 아이디
          data.fk_measure_requirement_id = vars.formData.id; // 소요량계산 아이디
        }
        vars.dlg.addItem.show = false;
        vars.dlg.addPlanItem.show = false;
        methods.calcSummary();
      },
      async addBaseItem() {
        const grid = vars.grid.item1;
        const rows = [...(await vars.grid.baseItem.getSelectedRowsData())];
        for (let row of rows) {
          const clientItemNumber = row.client_item
            ? row.client_item.client_item_code
            : null;
          const basicStock = row.basic_stock
            ? { ...row.basic_stock }
            : {
                current_stock: 0,
                available_stock: 0,
              };

          await grid.addRow();
          const data = await grid.byKey(grid.getKeyByRowIndex(0));
          data.item_code = row.item_code; // 품목코드
          data.item = { ...row }; // 품목
          data.production_plan_quantity = 0; // 생산계획수량
          data.unit_price = row.sales_price ? row.sales_price : 0; // 단가
          data.supply_price = 0; // 공급가
          data.request_delivery_date = null; // 요청납기
          data.unordered_quantity = 0; // 미작지수량
          data.basic_stock = { ...basicStock }; // 기초재고
          data.warehouse = { ...vars.warehouse }; // 입고창고
          data.warehouse_code = vars.warehouse.wh_code; // 창고코드
          data.order_number = null; // 수주번호
          data.client_company = null; // 고객업체
          data.client_item_number = clientItemNumber; // 고객사품번
          data.end_user = null; // 실수요자
          data.project_number = null; // 프로젝트번호
          data.fk_plan_item_id = null; // 생산계획품목 아이디
          data.fk_measure_requirement_id = vars.formData.id; // 소요량계산 아이디
        }
        vars.dlg.addItem.show = false;
        vars.dlg.addPlanItem.show = false;
        methods.calcSummary();
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key;
        const _title = title;
        const _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddBaseItem() {
        methods.gridClear(vars.grid.baseItem);
        vars.dlg.addItem.show = true;
      },
      showAddPlanItem() {
        const notContains = [];
        for (const item of vars.grid.item1.getVisibleRows()) {
          if (!item.id && item.data.fk_plan_item_id) {
            notContains.push(['id', '<>', item.data.fk_plan_item_id], 'and');
          }
        }
        notContains.pop()

        if (vars.grid.planItem) {
           vars.grid.planItem.filter(notContains);
          methods.gridClear(vars.grid.planItem);
        }
        vars.dlg.addPlanItem.show = true;
      },
      gridClear(grid) {
        if (grid) {
          grid.clearSelection();
          grid.refresh();
        }
      },
      async gridItem1Refresh(id) {
        if (!id) id = 0;
        vars.filter.item1[0].val = id;
        vars.dataSource.item1.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) {
          vars.grid.item1.cancelEditData();
          vars.grid.item1.refresh();
        }
      },
      async gridItem2Refresh(id) {
        if (!id) id = 0;
        vars.filter.item2[0].val = id;
        vars.dataSource.item2.defaultFilters = vars.filter.item2;
        if (vars.grid.item2) {
          vars.grid.item2.cancelEditData();
          vars.grid.item2.refresh();
        }
      },
      async gridSaveEdit(grid) {
        if (grid && grid.hasEditData()) {
          await grid.saveEditData();
        }
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`produce-mea-req-${key}`, evt.component);
      },
      async calculateConsume() {
        const rows = vars.grid.item1.getVisibleRows();
        for (let row of rows) {
          if (!row.data.item.bom_yn) {
            await alert('BOM이 구성되지 않은 품목이 있습니다', 'BOM 확인');
            return;
          }
        }

        const result = await confirm(
          '소요량 재계산 시 기존에 계산된 내용이 모두 삭제됩니다.<br/>계속하시겠습니까?',
          '소요량 재계산하기'
        );
        if (result) {
          const params = {
            mea_req_id: vars.formData.id,
            warehouse_code: vars.warehouse.wh_code,
          };
          const apiService = new ApiService('/api/mes/v1/update-mea-req');
          await apiService.post('', params);
          vars.grid.item2.refresh();
        }
      },
      async exportOrderPlan() {
        const result = await confirm(
          '발주계획으로 보내기 후 품목 변경이 불가능합니다.<br/>계속하시겠습니까?',
          '발주계획으로 보내기'
        );
        if (result) {
          const params = {
            mea_req_id: vars.formData.id,
            company_id: authService.getCompanyId(),
          };
          const apiService = new ApiService('/api/mes/v1/export-mea-req');
          await apiService.post('', params);
          await alert(
            '발주계획으로 보내기가 완료되었습니다',
            '발주계획으로 보내기'
          );
          router.go();
        }
      },
      async newItem() {
        methods.gridItem1Refresh();
        methods.gridItem2Refresh();
        if (vars.formData.id) {
          methods.clearFormData();
          methods.redirect();
        }
        setTimeout(() => {
          methods.clearFormData();
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editItem() {
        if (!vars.formData.id) return;
        if (vars.formState.readOnly) {
          let isSelect = await confirm('수정하시겠습니까?', '수정');
          if (!isSelect) {
            return;
          }
        }

        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        methods.enableSave();
        methods.enableDelete();

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          try {
            await measureRequirement.remove(vars.formData.id);
            await alert('삭제되었습니다', '삭제 확인');
            methods.redirect();
            vars.formState.readOnly = true;
          } catch (ex) {
            if (ex.response.status != 403) {
              await alert(
                '연결된 데이터가 있어서 삭제가 안됩니다',
                '삭제 확인'
              );
            }
          }
        }
      },
      async saveItem() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await measureRequirement.update(vars.formData.id, vars.formData);
            await methods.gridSaveEdit(vars.grid.item1);
            await methods.gridSaveEdit(vars.grid.item2);

            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');

            methods.enableSave();
            methods.enableDelete();
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await measureRequirement.insert(vars.formData);
            vars.formData.id = data.id;

            await methods.gridSaveEdit(vars.grid.item1);
            await methods.gridSaveEdit(vars.grid.item2);

            methods.redirect(data.id);
            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 소요량산출번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'measure-requirement': {
            methods.redirect(data.id);
            vars.formState.readOnly = true;
            break;
          }
          case 'client': {
            vars.grid.item1.cellValue(
              vars.focus.item1.rowIndex,
              vars.focus.item1.columnIndex,
              data.name
            );
            break;
          }
          case 'client2': {
            vars.grid.item2.cellValue(
              vars.focus.item2.rowIndex,
              vars.focus.item2.columnIndex,
              data.name
            );
            break;
          }
          case 'project': {
            vars.grid.item1.cellValue(
              vars.focus.item1.rowIndex,
              vars.focus.item1.columnIndex,
              data.project_number
            );
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectedItem = e.component.option('selectedItem');
        if (selectedItem) {
          vars.warehouse = { ...selectedItem.warehouse };
        } else {
          vars.warehouse = {};
        }
        methods.loadBaseItem();
        methods.loadPlanItem();
      },
      onFocusedCellChanged(e) {
        vars.focus.item1 = e;
      },
      onFocusedCellChanged2(e) {
        vars.focus.item2 = e;
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_measure_requirement_id = vars.formData.id;
            delete element.data.item;
            delete element.data.warehouse;
            delete element.data.basic_stock;
            delete element.data.plan_item;
            delete element.data.measure_requirement;
          }
        });
        methods.saveSummary();
      },
      onDataError(e) {
        if (e.error.response.status == 605) {
          e.error.message = '연결된 데이터가 있어서 삭제가 안됩니다';
        } else if (e.error.response.status == 403) {
          e.error.message = '권한이 없습니다';
        }
      },
      availableStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.available_stock;
      },
      currentStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.current_stock;
      },
      saveSummary() {
        const priceData = {
          supply_price: vars.formData.supply_price,
          vat: vars.formData.vat,
          total_price: vars.formData.total_price,
        };
        measureRequirement.update(vars.formData.id, priceData);
      },
      async setWarehouse(newData, value, currentRowData) {
        const warehouseList = vars.dataSource.warehouse;
        for (let i = 0; i < warehouseList.length; i++) {
          if (warehouseList[i].wh_name == value) {
            newData.warehouse = { ...warehouseList[i] };
            newData.warehouse_code = warehouseList[i].wh_code;

            const { basicStock } = await getStock(
              currentRowData.item_code,
              newData.warehouse_code
            );
            newData.basic_stock = { ...basicStock };
            break;
          }
        }
      },
      loadBaseCode() {
        return baseCodeLoader([
          '부가세구분',
          '수주구분',
          '결재조건',
          '납품장소',
        ])
          .then(response => {
            vars.dataSource.payment_terms = [...response['결재조건']];
            vars.dataSource.order_type = [...response['수주구분']];
            vars.dataSource.vat_type = [...response['부가세구분']];
            vars.dataSource.delivery_place = [...response['납품장소']];
          })
          .then(() => (vars.init.value = true));
      },
      setQuantity(newData, value, currentRowData) {
        newData.unordered_quantity =
          currentRowData.unordered_quantity +
          (value - currentRowData.production_plan_quantity);
        newData.production_plan_quantity = value;
        newData.unit_price = currentRowData.unit_price;
        newData.supply_price = value * newData.unit_price;
        methods.calcSummary();
      },
      setUnitPrice(newData, value, currentRowData) {
        newData.production_plan_quantity =
          currentRowData.production_plan_quantity;
        newData.unit_price = value;
        newData.supply_price = newData.production_plan_quantity * value;
        methods.calcSummary();
      },
      setQuantity2(newData, value, currentRowData) {
        newData.unordered_plan_quantity =
          currentRowData.unordered_plan_quantity +
          (value - currentRowData.order_plan_quantity);
        newData.order_plan_quantity = value;
        newData.unit_price = currentRowData.unit_price;
        newData.supply_price = value * newData.unit_price;
        methods.calcSummary();
      },
      setUnitPrice2(newData, value, currentRowData) {
        newData.order_plan_quantity = currentRowData.order_plan_quantity;
        newData.unit_price = value;
        newData.supply_price = newData.order_plan_quantity * value;
        methods.calcSummary();
      },
      calcSummary() {
        setTimeout(() => {
          let total =
            sumSupplyPrice(
              vars.grid.item1,
              'production_plan_quantity',
              'unit_price'
            ) +
            sumSupplyPrice(
              vars.grid.item2,
              'order_plan_quantity',
              'unit_price'
            );

          const response = calcPriceSummary(vars.formData.vat_type, total);
          vars.formData.supply_price = response.supply_price;
          vars.formData.vat = response.vat;
          vars.formData.total_price = response.total_price;
        }, 200);
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          null,
          vars.warehouse.wh_code
        );
      },
      loadPlanItem() {
        vars.dataSource.planItem = getProducePlanItem();
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          methods.enableSave();
          vars.disabled.items = false;
        }
      },
      redirect(id) {
        if (id) {
          router.replace({ path: `/produce/measure-requirement/${id}` });
        } else {
          router.replace({ path: `/produce/measure-requirement` });
        }
      },
      async redirectOrderPlanNumber() {
        if (!vars.formData.purchase_order_plan_number) {
          await alert(
            '발주계획번호가 존재하지 않습니다',
            '재고이동요청으로 이동'
          );
          return;
        }
        const response = await purchaseOrderPlan.load({
          filter: [
            [
              'order_plan_number',
              '=',
              vars.formData.purchase_order_plan_number,
            ],
            ['fk_company_id', '=', authService.getCompanyId()],
          ],
        });
        if (response.data.length > 0) {
          router.replace({
            path: `/purchase/order-plan/${response.data[0].id}`,
          });
        } else {
          await alert('발주계획번호가 존재하지 않습니다', '발주계획으로 이동');
        }
      },
      itemPopupClick({ column, data }) {
        if (column.name === 'item_code') {
          vars.itemDetail.id = data.item.id;
          vars.itemDetail.visible = true;
        }
      },
      enableDelete() {
        if (vars.formState.readOnly) {
          vars.disabled.delete = true;
        } else {
          vars.disabled.delete = false;
        }
      },
      enableSave() {
        if (vars.formState.readOnly) {
          vars.disabled.save = true;
        } else {
          vars.disabled.save = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      generateItemButtonOption,
      producePlanItem,
    };
  },
};
</script>
