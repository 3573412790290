<template>
  <dx-data-grid
    :data-source="performanceItem1"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @initialized="onInitialized"
    @selection-changed="onSelectionChanged"
  >
    <dx-column
      data-field="performance_registration.number"
      caption="생산입고번호"
    />
    <dx-column data-field="work_order.number" caption="작업지시번호" />
    <dx-column data-field="item_code" caption="품목코드" width="180" />
    <dx-column data-field="item.item_name" caption="품명" />
    <dx-column data-field="item.item_standard" caption="규격" />
    <dx-column
      data-field="work_order_item.required_quantity"
      caption="작지수량"
      data-type="number"
    />
    <dx-column
      data-field="production_receiving_quantity"
      caption="생산입고수량"
      data-type="number"
    />
    <dx-column data-field="item.unit" caption="단위" />
    <dx-column
      data-field="request_delivery_date"
      caption="요청납기"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column
      data-field="unproduced_quantity"
      caption="미생산수량"
      data-type="number"
    />
    <dx-column
      data-field="basic_stock.available_stock"
      caption="가용재고"
      data-type="number"
    />
    <dx-column
      data-field="basic_stock.current_stock"
      caption="현재고"
      data-type="number"
    />
    <dx-column data-field="order_number" caption="수주번호" />
    <dx-column data-field="client_company" caption="고객업체" />
    <dx-column data-field="client_item_number" caption="고객사품번" />
    <dx-column data-field="end_user" caption="실수요자" />
    <dx-column data-field="warehouse.wh_name" caption="입고창고" />
    <dx-column
      data-field="fk_performance_registration_id"
      caption="생산입고 아이디"
      :visible="false"
    />
    <dx-column
      data-field="fk_work_order_item"
      caption="작업지시품목 아이디"
      :visible="false"
    />
    <dx-column
      data-field="warehouse_code"
      caption="창고코드"
      :visible="false"
    />

    <dx-column
      data-field="work_order.target_date"
      caption="일자"
      data-type="date"
      format="yyyy-MM-dd"
    />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { performanceItem1 } from '../../data-source/produce';
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: Object,
  },
});
const emit = defineEmits(['change']);
const mode = ref(props.multiple ? 'multiple' : 'single');
let component = undefined;

function onInitialized(e) {
  component = e.component;
  setFilter();
}

function setFilter() {
  if (!component) return;
  if (!props.filters || !props.filters.length) {
    component.clearFilter();
    return;
  }
  component.filter(props.filters);
}

function onSelectionChanged({ selectedRowsData }) {
  if (!props.multiple && selectedRowsData)
    selectedRowsData = selectedRowsData[0];
  emit('change', selectedRowsData);
}

watch(
  () => props.filters,
  () => setFilter()
);
</script>
