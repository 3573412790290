<template>
  <dx-data-grid
    :data-source="shipmentLend"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="lend_number" caption="가출고번호" />
    <dx-column
      data-field="lend_date"
      caption="가출고일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="client_company" caption="관련업체" />
    <dx-column data-field="lend_manager" caption="가출고담당자" />
    <dx-column data-field="item_code" caption="품목코드" />
    <dx-column data-field="item.item_name" caption="품명" />
    <dx-column data-field="item.item_standard" caption="규격" />
    <dx-column data-field="quantity" caption="가출고수량" />
    <dx-column data-field="not_retrieved_quantity" caption="미회수수량" />
    <dx-column data-field="note" caption="참고사항" />
    <dx-column data-field="etc" caption="비고" />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { shipmentLend } from '../../data-source/shipment';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      shipmentLend,
      onSelectionChanged,
    };
  },
};
</script>
