<template>
  <dx-data-grid
    :data-source="stockEtcItem"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="stock_etc.number" caption="기타입출고 번호" />
    <dx-column
      data-field="stock_etc.target_date"
      caption="입출고 일자"
      data-type="date"
      format="yyyy-MM-dd"
    />

    <dx-column data-field="item.item_code" caption="품목번호" />
    <dx-column data-field="item.item_name" caption="품목이름" />
    <dx-column data-field="item.item_standard" caption="규격" />

    <dx-column data-field="stock_etc.department" caption="담당부서" />
    <dx-column data-field="stock_etc.manager" caption="담당자" />
    <dx-column data-field="stock_etc.note" caption="참고사항" />
    <dx-column data-field="stock_etc.etc" caption="비고" />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { stockEtcItem } from '../../data-source/stock';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array
    }
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    if (props.filter) {
      stockEtcItem.defaultFilters = [...props.filter]
    }

    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      stockEtcItem,
      onSelectionChanged,
    };
  },
};
</script>
