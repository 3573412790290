<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings fit-height-content">
        <div class="standard-code-container">
          <div class="standard-code-body">
            <div class="standard-code-item pr pb">
              <div class="content-header">
                <dx-toolbar>
                  <dx-item location="before">
                    <div class="content-title">공지사항</div>
                  </dx-item>
                </dx-toolbar>
              </div>
              <dx-data-grid
                class="fixed-header-table"
                height="calc(100% - 40px)"
                :data-source="store.projectNotice"
                :show-borders="true"
                :hover-state-enabled="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :remote-operations="true"
                @initialized="evt => methods.initialized(evt, 'notice-grid')"
                @row-click="methods.rowClick"
              >
                <!--dx-column data-field="id" caption="번호" sort-order="desc" :width="60" /-->
                <dx-column
                  data-field="created"
                  caption="생성시간"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :allow-editing="false"
                  :visible="false"
                  :width="120"
                />
                <dx-column
                  data-field="important"
                  caption="중요여부"
                  data-type="boolean"
                  :width="80"
                />
                <dx-column data-field="title" caption="제목" />
                <dx-column
                  data-field="start_date"
                  caption="공지시작일"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :width="100"
                />
                <dx-column
                  data-field="end_date"
                  caption="공지종료일"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :width="100"
                />
                <dx-column
                  data-field="content_html"
                  caption="내용"
                  :visible="false"
                />
                <dx-column
                  data-field="user.user_name"
                  caption="최초등록자"
                  :allow-editing="false"
                  :width="100"
                />
                <dx-column
                  data-field="fk_company_id"
                  caption="회사"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-filter-row :visible="false" />
                <dx-paging :page-size="20" />
              </dx-data-grid>
            </div>
            <div class="standard-code-item pl pb">
              <div class="content-header">
                <dx-toolbar>
                  <dx-item location="before">
                    <div class="content-title">재고이동요청</div>
                  </dx-item>
                </dx-toolbar>
              </div>
              <dx-data-grid
                class="fixed-header-table"
                height="calc(100% - 40px)"
                :data-source="store.stockMoveRequestItem"
                :show-borders="true"
                :hover-state-enabled="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :remote-operations="true"
                @initialized="
                  evt =>
                    methods.initialized(
                      evt,
                      'dashboard-stock-move-request-item-grid'
                    )
                "
                @cell-dbl-click="methods.stockMoveRequestItemDblClk"
              >
                <!--dx-column data-field="id" caption="번호" sort-order="desc" :width="60" /-->
                <dx-column
                  data-field="created"
                  caption="생성시간"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :allow-editing="false"
                  :visible="false"
                  :width="120"
                />
                <dx-column
                  data-field="stock_move_request.number"
                  caption="이동요청번호"
                />
                <dx-column
                  data-field="stock_move_request.target_date"
                  caption="요청일자"
                  data-type="date"
                  format="yyyy-MM-dd"
                />
                <dx-column
                  data-field="stock_move_request.manager"
                  caption="담당자"
                />
                <dx-column data-field="item_code" caption="품목코드" />
                <dx-column data-field="item.item_name" caption="품명" />
                <dx-column
                  data-field="quantity"
                  caption="수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column
                  data-field="not_shipped"
                  caption="미출고수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column data-field="out_warehouse" caption="출고창고" />
                <dx-column data-field="in_warehouse" caption="입고창고" />
                <dx-column
                  data-field="fk_company_id"
                  caption="회사"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-filter-row :visible="false" />
                <dx-paging :page-size="20" />
              </dx-data-grid>
            </div>
          </div>
          <div class="standard-code-body">
            <div class="standard-code-item pr pt">
              <div class="content-header">
                <dx-toolbar>
                  <dx-item location="before">
                    <div class="content-title">미발주</div>
                  </dx-item>
                </dx-toolbar>
              </div>
              <dx-data-grid
                class="fixed-header-table"
                height="calc(100% - 40px)"
                :data-source="store.purchaseOrderPlanItem"
                :show-borders="true"
                :hover-state-enabled="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :remote-operations="true"
                @initialized="
                  evt =>
                    methods.initialized(
                      evt,
                      'dashboard-purhcase-order-plan-item-grid'
                    )
                "
                @cell-dbl-click="methods.purchaseOrderPlanItemDblClk"
              >
                <!--dx-column data-field="id" caption="번호" sort-order="desc" :width="60" /-->
                <dx-column
                  data-field="created"
                  caption="생성시간"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-column
                  data-field="order_plan.order_plan_number"
                  caption="발주계획번호"
                />
                <dx-column
                  data-field="order_plan.order_plan_date"
                  caption="계획일자"
                  data-type="date"
                  format="yyyy-MM-dd"
                />
                <dx-column
                  data-field="order_plan.order_plan_manager"
                  caption="담당자"
                />
                <dx-column data-field="item_code" caption="품목코드" />
                <dx-column data-field="item.item_name" caption="품명" />
                <dx-column
                  data-field="order_plan_quantity"
                  caption="수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column
                  data-field="unordered_quantity"
                  caption="미발주수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column data-field="project_number" caption="프로젝트번호" />
                <dx-column
                  data-field="fk_company_id"
                  caption="회사"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-filter-row :visible="false" />
                <dx-paging :page-size="20" />
              </dx-data-grid>
            </div>
            <div class="standard-code-item pl pt">
              <div class="content-header">
                <dx-toolbar>
                  <dx-item location="before">
                    <div class="content-title">미입고</div>
                  </dx-item>
                </dx-toolbar>
              </div>
              <dx-data-grid
                class="fixed-header-table"
                height="calc(100% - 40px)"
                :data-source="store.purchaseOrderItem"
                :show-borders="true"
                :hover-state-enabled="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :remote-operations="true"
                @initialized="
                  evt =>
                    methods.initialized(
                      evt,
                      'dashboard-purchase-order-item-grid'
                    )
                "
                @cell-dbl-click="methods.purchaseOrderItemDblClk"
              >
                <!--dx-column data-field="id" caption="번호" sort-order="desc" :width="60" /-->
                <dx-column
                  data-field="created"
                  caption="생성시간"
                  data-type="date"
                  format="yyyy-MM-dd"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-column data-field="order.order_number" caption="발주번호" />
                <dx-column
                  data-field="order.order_date"
                  caption="발주일자"
                  data-type="date"
                  format="yyyy-MM-dd"
                />
                <dx-column data-field="order.order_manager" caption="담당자" />
                <dx-column data-field="item_code" caption="품목코드" />
                <dx-column data-field="item.item_name" caption="품명" />
                <dx-column
                  data-field="order_quantity"
                  caption="발주수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column
                  data-field="not_shipped"
                  caption="미입고수량"
                  data-type="number"
                  format="fixedPoint"
                />
                <dx-column data-field="project_number" caption="프로젝트번호" />
                <dx-column
                  data-field="fk_company_id"
                  caption="회사"
                  :allow-editing="false"
                  :visible="false"
                />
                <dx-filter-row :visible="false" />
                <dx-paging :page-size="20" />
              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.noticeDetail.visible"
      :title="vars.noticeDetail.title"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :resize-enabled="true"
      :width="740"
      :height="540"
      @initialized="evt => methods.initialized(evt, 'notice-detail')"
      @hiding="methods.popupClose"
    >
      <div class="popup-notice" v-html="vars.noticeDetail.context"></div>
    </dx-popup>
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { projectNotice } from '@/data-source/project';
import { getStockMoveRequestItem } from '@/data-source/stock';
import {
  getPurchaseOrderPlanItem,
  getPurchaseOrderItem,
} from '@/data-source/purchase';
import authService from '@/auth';
import stateStore from '@/utils/state-store';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxPopup,
    DxToolbarItem,
  },
  setup() {
    const router = useRouter();
    const vars = {},
      store = {},
      methods = {};
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    projectNotice.defaultFilters = [
      { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
      { name: 'end_date', op: '>=', val: todayDate },
    ];
    store.projectNotice = projectNotice;

    store.stockMoveRequestItem = getStockMoveRequestItem([
      {
        name: 'stock_move_request',
        op: 'has',
        val: {
          name: 'fk_company_id',
          op: 'eq',
          val: authService.getCompanyId(),
        },
      },
      { name: 'not_shipped', op: 'gt', val: 0 },
    ]);

    store.purchaseOrderPlanItem = getPurchaseOrderPlanItem([
      {
        name: 'order_plan',
        op: 'has',
        val: {
          name: 'fk_company_id',
          op: 'eq',
          val: authService.getCompanyId(),
        },
      },
      { name: 'unordered_quantity', op: 'gt', val: 0 },
    ]);

    store.purchaseOrderItem = getPurchaseOrderItem([
      {
        name: 'order',
        op: 'has',
        val: {
          name: 'fk_company_id',
          op: 'eq',
          val: authService.getCompanyId(),
        },
      },
      { name: 'not_shipped', op: 'gt', val: 0 },
    ]);

    vars.components = {};
    vars.noticeDetail = reactive({
      visible: false,
      title: '',
      context: '',
    });

    methods.initialized = (evt, key) => {
      vars.components[key] = evt.component;
      stateStore.bind(key, evt.component);
    };
    methods.rowClick = evt => {
      vars.noticeDetail.title = evt.data.title;
      vars.noticeDetail.context = evt.data.content_html;
      vars.noticeDetail.visible = true;
    };
    methods.popupClose = () => {
      vars.noticeDetail.title = '';
      vars.noticeDetail.context = '';
    };
    methods.stockMoveRequestItemDblClk = ({ column, data }) => {
      router.replace({
        path: `/stock/move-request/${data.stock_move_request.id}`,
      });
    };
    methods.purchaseOrderPlanItemDblClk = ({ column, data }) => {
      router.replace({ path: `/purchase/order-plan/${data.order_plan.id}` });
    };
    methods.purchaseOrderItemDblClk = ({ column, data }) => {
      router.replace({ path: `/purchase/order/${data.order.id}` });
    };

    return { vars, store, methods };
  },
};
</script>

<style lang="scss">
.standard-code-container {
  height: calc(100vh - 120px);
}
.standard-code-body {
  display: flex;
  flex-wrap: nowrap;
  height: 50%;
}
.standard-code-item {
  width: 50%;
}
.pl {
  padding-left: 10px;
}
.pr {
  padding-right: 10px;
}
.pt {
  padding-top: 10px;
}
.pb {
  padding-bottom: 10px;
}
</style>
