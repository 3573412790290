<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">품목관리</div>
            </dx-item>
          </dx-toolbar>
        </div>
        <dx-data-grid
          class="fixed-header-table"
          :data-source="vars.baseItem"
          :remote-operations="true"
          :show-borders="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :select-text-on-edit-start="true"
          :focused-row-enabled="true"
          :on-initialized="evt => methods.onInitialized(evt, 'gridItem')"
        >
          <dx-grid-toolbar>
            <dx-item location="after" template="addItem" />
            <dx-item name="columnChooserButton" />
            <dx-item location="after" template="excelUploadButton" />
            <dx-item location="after" template="excelSampleDownloadButton" />
          </dx-grid-toolbar>
          <template #addItem>
            <dx-button icon="add" @click="methods.newItem" />
          </template>
          <template #excelUploadButton>
            <dx-button icon="upload" @click="methods.excelUpload" />
          </template>
          <template #excelSampleDownloadButton>
            <dx-button icon="download" @click="methods.excelSampleDownload" />
          </template>
          <dx-column
            data-field="item_code"
            caption="품목코드"
            :allow-editing="false"
          />
          <dx-column
            data-field="item_name"
            caption="품명"
            :allow-editing="true"
          />
          <dx-column
            data-field="item_standard"
            caption="규격"
            :allow-editing="true"
          />
          <dx-column data-field="unit" caption="단위" :allow-editing="true">
            <dx-lookup
              :data-source="vars.dataSource.unit"
              display-expr="code_name"
              value-expr="code_name"
            />
          </dx-column>
          <dx-column
            data-field="main_category"
            caption="대분류"
            :allow-editing="true"
          >
            <dx-lookup
              :data-source="vars.dataSource.main_category"
              display-expr="code_name"
              value-expr="code_name"
            />
          </dx-column>
          <dx-column
            data-field="middle_category"
            caption="중분류"
            :allow-editing="true"
          >
            <dx-lookup
              :data-source="vars.dataSource.middle_category_all"
              display-expr="code_name"
              value-expr="code_name"
            />
          </dx-column>
          <dx-column
            data-field="sub_category"
            caption="소분류"
            :allow-editing="true"
          >
            <dx-lookup
              :data-source="vars.dataSource.sub_category_all"
              display-expr="code_name"
              value-expr="code_name"
            />
          </dx-column>
          <dx-column
            data-field="asset_type"
            caption="자산구분"
            :allow-editing="true"
          >
            <dx-lookup
              :data-source="vars.dataSource.asset_type"
              display-expr="code_name"
              value-expr="code_name"
            />
          </dx-column>
          <dx-column
            data-field="item_detail"
            caption="품목설명"
            :allow-editing="true"
          />
          <dx-column
            data-field="id"
            caption="품목번호"
            :allow-editing="false"
            :visible="false"
          />
          <dx-column
            data-field="created"
            caption="최초등록일자"
            data-type="date"
            format="yyyy-MM-dd"
            :allow-editing="false"
            :visible="false"
          />
          <dx-column data-field="moq" caption="MOQ" :visible="false" />
          <dx-column
            data-field="packing_quantity"
            caption="포장단위수량"
            :visible="false"
          />
          <dx-column
            data-field="transfer_quantity"
            caption="이송단위수량"
            :visible="false"
          />
          <dx-column
            data-field="delivery_date"
            caption="표준납기일"
            :visible="false"
          />
          <dx-column
            data-field="import_check"
            caption="수입검사"
            :visible="false"
          />
          <dx-column
            data-field="shipment_check"
            caption="출하검사"
            :visible="false"
          />
          <dx-column
            data-field="safety_stock"
            caption="안전재고"
            :visible="false"
          />
          <dx-column
            data-field="sales_price"
            caption="매출단가"
            data-type="number"
            format="currency"
            :visible="false"
          />
          <dx-column
            data-field="purchase_price"
            caption="매입단가"
            data-type="number"
            format="currency"
            :visible="false"
          />
          <dx-column data-field="note1" caption="참고1" :visible="false" />
          <dx-column data-field="note2" caption="참고2" :visible="false" />
          <dx-column data-field="etc" caption="비고" :visible="false" />
          <dx-column
            data-field="before_item_code"
            caption="변경전 품목코드"
            :visible="false"
          />
          <dx-column
            data-field="after_item_code"
            caption="변경후 품목코드"
            :visible="false"
          />
          <dx-column
            data-field="end_of_use"
            caption="사용종료"
            :visible="false"
          />
          <dx-column
            data-field="end_date"
            caption="종료일자"
            data-type="date"
            format="yyyy-MM-dd"
            :visible="false"
          />
          <dx-column
            data-field="register_id"
            caption="최초등록자"
            :visible="false"
            :allow-editing="false"
          />
          <dx-column
            data-field="modify_id"
            caption="최종수정자"
            :visible="false"
            :allow-editing="false"
          />
          <dx-column
            data-field="modify_date"
            caption="최종수정일자"
            data-type="date"
            format="yyyy-MM-dd"
            :visible="false"
            :allow-editing="false"
          />
          <dx-column type="buttons">
            <dx-grid-button
              hint="상세설정"
              icon="edit"
              :visible="true"
              @click="methods.editItem"
            />
            <dx-grid-button name="delete" />
          </dx-column>

          <dx-editing
            :allow-adding="false"
            :allow-updating="false"
            :allow-deleting="true"
            :use-icons="true"
            mode="cell"
          >
          </dx-editing>
          <dx-column-chooser mode="select" :enabled="true" />
          <dx-filter-row :visible="true" />
          <dx-paging :page-size="20" />
        </dx-data-grid>

        <dx-popup
          v-model:visible="vars.addItem.visible"
          content-template="popup-content"
          title="품목관리"
          width="70%"
          height="80%"
          :resize-enabled="true"
          :close-on-outside-click="true"
          @initialized="evt => methods.onInitialized(evt, 'detail-popup')"
        >
          <dx-toolbar-item
            widget="dxButton"
            toolbar="top"
            location="after"
            :options="{
              icon: 'print',
              text: 'QR라벨인쇄',
              onClick: methods.prepareQRLabel,
            }"
          />
          <dx-toolbar-item
            widget="dxButton"
            toolbar="top"
            location="after"
            :options="{
              icon: 'save',
              text: '저장',
              onClick: methods.onClickItemSave,
            }"
          />

          <template #popup-content>
            <dx-scroll-view width="100%" height="100%">
              <dx-form
                :form-data="vars.formData"
                :col-count="1"
                :show-colon-after-label="false"
                :on-initialized="
                  evt => methods.onInitialized(evt, 'formEditItem')
                "
              >
                <template #image-template="{}">
                  <div class="form-image">
                    <img
                      class="item-image"
                      :src="vars.itemImage.value"
                      v-if="vars.formData.item_img"
                      alt=""
                    />
                  </div>
                </template>
                <dx-group-item :col-count="4">
                  <dx-group-item :col-span="2" :col-count="2">
                    <dx-group-item :col-span="1">
                      <dx-simple-item template="image-template" />
                      <dx-simple-item
                        :button-options="
                          generateItemButtonOption(
                            '',
                            methods.saveCompany,
                            'after',
                            {
                              text: '이미지 변경',
                              type: 'default',
                              width: '90%',
                              onClick: methods.onClickAddImage,
                            }
                          )
                        "
                        item-type="button"
                        horizontal-alignment="left"
                      ></dx-simple-item>
                    </dx-group-item>
                    <dx-group-item :col-span="1" :col-count="1">
                      <dx-simple-item
                        data-field="item_code"
                        :col-span="1"
                        :editor-options="{
                          readOnly: vars.item.readOnly,
                        }"
                      >
                        <dx-label text="품목코드" :show-colon="false" />
                        <dx-required-rule message="품목코드를 입력하세요" />
                      </dx-simple-item>
                      <dx-simple-item
                        data-field="main_category"
                        editor-type="dxSelectBox"
                        :editor-options="{
                          dataSource: vars.dataSource.main_category,
                          displayExpr: 'code_name',
                          valueExpr: 'code_name',
                          onValueChanged: methods.onMainCategoryChanged,
                        }"
                      >
                        <dx-label text="대분류" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item
                        data-field="asset_type"
                        editor-type="dxSelectBox"
                        :editor-options="{
                          dataSource: vars.dataSource.asset_type,
                          displayExpr: 'code_name',
                          valueExpr: 'code_name',
                        }"
                      >
                        <dx-label text="자산구분" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item
                        data-field="safety_stock"
                        editor-type="dxNumberBox"
                        :col-span="1"
                        :editor-options="{
                          format: 'fixedPoint',
                        }"
                      >
                        <dx-label text="안전재고" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item data-field="import_check" :col-span="1">
                        <dx-label text="수입검사" :show-colon="false" />
                      </dx-simple-item>
                    </dx-group-item>
                    <dx-group-item :col-span="2" :col-count="2">
                      <dx-simple-item data-field="item_detail" :col-span="2">
                        <dx-label text="품목설명" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item data-field="note1" :col-span="2">
                        <dx-label text="참고1" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item data-field="note2" :col-span="2">
                        <dx-label text="참고2" :show-colon="false" />
                      </dx-simple-item>
                      <dx-simple-item
                        data-field="etc"
                        :col-span="2"
                        :editor-options="{ height: 82 }"
                      >
                        <dx-label text="비고" :show-colon="false" />
                      </dx-simple-item>
                    </dx-group-item>
                  </dx-group-item>
                  <dx-group-item :col-span="2" :col-count="2">
                    <dx-simple-item data-field="item_name" :col-span="1">
                      <dx-label text="품명" :show-colon="false" />
                      <dx-required-rule message="품명을 입력하세요" />
                    </dx-simple-item>
                    <dx-simple-item data-field="item_standard" :col-span="1">
                      <dx-label text="규격" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="middle_category"
                      editor-type="dxSelectBox"
                      :editor-options="{
                        dataSource: vars.dataSource.middle_category,
                        displayExpr: 'code_name',
                        valueExpr: 'code_name',
                        onValueChanged: methods.onMiddleCategoryChanged,
                      }"
                    >
                      <dx-label text="중분류" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="sub_category"
                      editor-type="dxSelectBox"
                      :editor-options="{
                        dataSource: vars.dataSource.sub_category,
                        displayExpr: 'code_name',
                        valueExpr: 'code_name',
                      }"
                    >
                      <dx-label text="소분류" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="moq"
                      editor-type="dxNumberBox"
                      :col-span="1"
                      :editor-options="{
                        format: 'fixedPoint',
                      }"
                    >
                      <dx-label text="MOQ" :show-colon="false" />
                    </dx-simple-item>

                    <dx-simple-item
                      data-field="unit"
                      editor-type="dxSelectBox"
                      :editor-options="{
                        dataSource: vars.dataSource.unit,
                        displayExpr: 'code_name',
                        valueExpr: 'code_name',
                      }"
                    >
                      <dx-label text="단위" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="packing_quantity"
                      editor-type="dxNumberBox"
                      :col-span="1"
                      :editor-options="{
                        format: 'fixedPoint',
                      }"
                    >
                      <dx-label text="포장단위수량" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="transfer_quantity"
                      editor-type="dxNumberBox"
                      :col-span="1"
                      :editor-options="{
                        format: 'fixedPoint',
                      }"
                    >
                      <dx-label text="이송단위수량" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item data-field="shipment_check" :col-span="1">
                      <dx-label text="출하검사" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item data-field="delivery_date" :col-span="1">
                      <dx-label text="표준납기일" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="sales_price"
                      editor-type="dxNumberBox"
                      :col-span="1"
                      :editor-options="{
                        format: 'currency',
                      }"
                    >
                      <dx-label text="매출단가" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="purchase_price"
                      editor-type="dxNumberBox"
                      :col-span="1"
                      :editor-options="{
                        format: 'currency',
                      }"
                    >
                      <dx-label text="매입단가" :show-colon="false" />
                    </dx-simple-item>

                    <dx-simple-item data-field="before_item_code" :col-span="1">
                      <dx-label text="변경전 품목코드" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item data-field="after_item_code" :col-span="1">
                      <dx-label text="변경후 품목코드" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item data-field="end_of_use" :col-span="1">
                      <dx-label text="사용종료" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="end_date"
                      editor-type="dxDateBox"
                      :col-span="1"
                      :editor-options="{
                        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                      }"
                    >
                      <dx-label text="종료일자" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="register_id"
                      :editor-options="{ readOnly: true }"
                    >
                      <dx-label text="최초등록자" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="created"
                      editor-type="dxDateBox"
                      :col-span="1"
                      :editor-options="{
                        readOnly: true,
                        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                      }"
                    >
                      <dx-label text="최초등록일자" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="modify_id"
                      :editor-options="{ readOnly: true }"
                    >
                      <dx-label text="최종수정자" :show-colon="false" />
                    </dx-simple-item>
                    <dx-simple-item
                      data-field="modify_date"
                      editor-type="dxDateBox"
                      :col-span="1"
                      :editor-options="{
                        readOnly: true,
                        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                      }"
                    >
                      <dx-label text="최종수정일자" :show-colon="false" />
                    </dx-simple-item>
                  </dx-group-item>
                </dx-group-item>
                <dx-group-item :visible="!!vars.formData.id">
                  <dx-tabbed-item>
                    <dx-tab-panel-options :defer-rendering="false" />
                    <dx-tab title="거래처 품목코드">
                      <dx-data-grid
                        class="fixed-header-table"
                        height="420px"
                        :data-source="vars.clientItem"
                        :remote-operations="true"
                        :show-borders="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                        :on-initialized="
                          evt => methods.onInitialized(evt, 'gridClientItem')
                        "
                      >
                        <dx-grid-toolbar>
                          <dx-item name="addRowButton" template="addButton" />
                          <dx-item name="saveButton" />
                          <dx-item name="revertButton" />
                        </dx-grid-toolbar>
                        <template #addButton>
                          <dx-button
                            icon="add"
                            @click="methods.showAddClientPopup"
                          />
                        </template>

                        <dx-column
                          data-field="client.name"
                          caption="거래처명"
                          :allow-editing="false"
                        />
                        <dx-column
                          data-field="client_item_code"
                          caption="거래처품번"
                        />
                        <dx-column
                          data-field="unit_price_type"
                          caption="단가구분"
                        >
                          <dx-lookup
                            :data-source="vars.dataSource.unit_price_type"
                            value-expr="code_name"
                            display-expr="code_name"
                          />
                        </dx-column>
                        <dx-column
                          data-field="unit_price"
                          caption="단가"
                          data-type="number"
                          format="currency"
                        />
                        <dx-column data-field="note" caption="참고" />
                        <dx-column
                          data-field="created"
                          caption="등록일자"
                          data-type="date"
                          format="yyyy-MM-dd"
                          :allow-editing="false"
                        />
                        <dx-column data-field="etc" caption="비고" />
                        <dx-column
                          data-field="client_id"
                          caption="거래처ID"
                          :visible="false"
                        />
                        <dx-column
                          data-field="item_code"
                          caption="품목코드"
                          :visible="false"
                        />
                        <dx-column
                          data-field="fk_company_id"
                          caption="회사ID"
                          :visible="false"
                        />
                        <dx-editing
                          :allow-adding="true"
                          :allow-updating="true"
                          :allow-deleting="true"
                          :use-icons="true"
                          mode="batch"
                        >
                        </dx-editing>
                        <dx-paging :page-size="20" />
                      </dx-data-grid>
                    </dx-tab>
                    <dx-tab title="단가이력정보">
                      <dx-data-grid
                        class="fixed-header-table"
                        height="420px"
                        :remote-operations="true"
                        :show-borders="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                      >
                        <dx-column
                          data-field="id"
                          caption="아이디"
                          :allow-editing="false"
                          :visible="false"
                        />
                        <dx-column data-field="name" caption="거래처명" />
                        <dx-column data-field="price_sel" caption="단가구분" />
                        <dx-column data-field="price" caption="표준단가" />
                        <dx-column caption="최초거래">
                          <dx-column
                            data-field="price_date_1"
                            caption="거래일자"
                          />
                          <dx-column data-field="price_1" caption="단가" />
                        </dx-column>
                        <dx-column caption="최종거래">
                          <dx-column
                            data-field="price_date_2"
                            caption="거래일자"
                          />
                          <dx-column data-field="price_2" caption="단가" />
                        </dx-column>
                        <dx-column caption="최저가거래">
                          <dx-column
                            data-field="price_date_3"
                            caption="거래일자"
                          />
                          <dx-column data-field="price_3" caption="단가" />
                        </dx-column>
                        <dx-column caption="최고가거래">
                          <dx-column
                            data-field="price_date_4"
                            caption="거래일자"
                          />
                          <dx-column data-field="price_4" caption="단가" />
                        </dx-column>
                        <dx-paging :page-size="20" />
                      </dx-data-grid>
                    </dx-tab>
                    <dx-tab title="창고별재고">
                      <dx-data-grid
                        class="fixed-header-table"
                        height="420px"
                        :data-source="vars.stock"
                        :remote-operations="true"
                        :show-borders="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                      >
                        <dx-column
                          data-field="warehouse.wh_name"
                          caption="창고명"
                        />
                        <dx-column
                          data-field="current_stock"
                          caption="현재고"
                          data-type="number"
                          format="fixedPoint"
                        />
                        <dx-column
                          data-field="assign_stock"
                          caption="할당재고"
                          data-type="number"
                          format="fixedPoint"
                        />
                        <dx-column
                          data-field="available_stock"
                          caption="가용재고"
                          data-type="number"
                          format="fixedPoint"
                        />
                        <dx-paging :page-size="20" />
                      </dx-data-grid>
                    </dx-tab>
                  </dx-tabbed-item>
                </dx-group-item>
              </dx-form>
            </dx-scroll-view>
          </template>
        </dx-popup>
        <dx-popup
          id="popup"
          content-template="popup-content"
          v-model:visible="vars.addImage.visible"
          :close-on-outside-click="true"
          :show-close-button="false"
          :show-title="false"
          :width="350"
          :height="350"
        >
          <template #popup-content>
            <div class="widget-container flex-box">
              <div
                id="dropzone-external"
                class="flex-box"
                :class="[
                  vars.imageUpload.isDropZoneActive
                    ? 'dx-theme-accent-as-border-color dropzone-active'
                    : 'dx-theme-border-color',
                ]"
              >
                <img
                  id="dropzone-image"
                  :src="vars.itemImage.value"
                  v-if="vars.formData.item_img"
                  alt=""
                />
                <div
                  id="dropzone-text"
                  class="flex-box"
                  v-if="vars.imageUpload.textVisible"
                >
                  <span>새로운 이미지를 끌어다 놓으세요.</span>
                </div>
              </div>
              <dx-file-uploader
                id="file-uploader"
                dialog-trigger="#dropzone-external"
                drop-zone="#dropzone-external"
                :multiple="false"
                :allowed-file-extensions="
                  vars.imageUpload.allowedFileExtensions
                "
                upload-mode="useButtons"
                :visible="false"
                @drop-zone-enter="methods.onDropZoneEnter"
                @drop-zone-leave="methods.onDropZoneLeave"
                @value-changed="methods.onValueChangedImage($event)"
              />
            </div>
          </template>
        </dx-popup>

        <dx-popup
          v-model:visible="vars.addClient.visible"
          content-template="popup-content"
          title="거래처찾기"
          :close-on-outside-click="true"
          :width="680"
          :height="460"
          :resize-enabled="true"
          @initialized="evt => methods.onInitialized(evt, 'popupClient')"
        >
          <dx-toolbar-item
            widget="dxButton"
            toolbar="top"
            location="after"
            :options="{
              icon: 'add',
              text: '선택된 항목 추가',
              onClick: methods.addClients,
            }"
          />

          <template #popup-content>
            <dx-data-grid
              :on-initialized="evt => methods.onInitialized(evt, 'gridClient')"
              :data-source="vars.client"
              :show-borders="true"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :remote-operations="true"
            >
              <dx-column data-field="name" caption="업체명" />
              <dx-column data-field="alias" caption="업체약칭" />
              <dx-column data-field="corp_number" caption="법인번호" />
              <dx-column data-field="business_number" caption="사업자번호" />
              <dx-column data-field="ceo_name" caption="대표자명" />
              <dx-paging :page-size="20" />
              <dx-selection
                select-all-mode="page"
                show-check-boxes-mode="onClick"
                mode="multiple"
              />
              <dx-filter-row :visible="true" />
            </dx-data-grid>
          </template>
        </dx-popup>

        <dx-popup
          content-template="popup-content"
          v-model:visible="vars.printQr.visible"
          :close-on-outside-click="true"
          :show-close-button="false"
          :show-title="false"
          :width="650"
          :height="300"
          @initialized="evt => methods.onInitialized(evt, 'multiuse-popup')"
        >
          <dx-toolbar-item location="after" widget="dxButton" :options="{icon: 'close', text: '닫기', onClick: methods.closeQRLabel}" />
          <dx-toolbar-item location="after" widget="dxButton" :options="{icon: 'print', text: '확인', onClick: methods.printQRLabel}" />
          <template #popup-content>
            <dx-form :form-data="vars.printQr" :col-count="2">
              <dx-simple-item>
                <template #default>
                  <img class="qr-image" :src="vars.printQr.image" />
                </template>
              </dx-simple-item>
              <dx-group-item>
                <dx-simple-item>
                  <template #default>
                    품목코드: {{vars.formData.item_code}}
                  </template>
                </dx-simple-item>
                <dx-simple-item>
                  <template #default>
                    품목이름: {{vars.formData.item_name}}
                  </template>
                </dx-simple-item>
                <dx-simple-item 
                  data-field="text"
                  :editor-options="{
                    buttons: [{
                      location: 'after',
                      name: 'refresh',
                      options: {
                        icon: 'refresh',
                        onClick: methods.refreshQRImage
                      }
                    }]
                  }">
                  <dx-label text="추가입력" />
                </dx-simple-item>
                <dx-simple-item 
                  data-field="quantity" 
                  editor-type="dxNumberBox"
                  :editor-options="{showSpinButtons: true}">
                  <dx-label text="인쇄수량" />
                </dx-simple-item>
              </dx-group-item>
            </dx-form>
          </template>
        </dx-popup>
      </div>
    </div>
    <input
      hidden
      type="file"
      ref="excelRef"
      accept=".xlsx,.xls"
      @change="methods.excelFileChange"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxLookup,
  DxToolbar as DxGridToolbar,
  DxColumnChooser,
  DxFilterRow,
  DxButton as DxGridButton,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxTabbedItem,
  DxTabPanelOptions,
  DxTab,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { DxNumberBox } from 'devextreme-vue/number-box'
import { DxTextBox } from 'devextreme-vue/text-box'
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { notifyInfo, notifyError } from '../../utils/notify';
import {
  baseItem,
  baseCode,
  getBaseItem,
  baseCodeLoader,
  getBaseCode,
  getBaseClient,
  getBaseClientItem,
} from '../../data-source/base';
import { getSetupBasicStock } from '../../data-source/setup';
import { setupGroup } from '../../data-source/setup';
import { ref, reactive, onMounted, nextTick, computed } from 'vue';
import moment, { now } from 'moment';
import authService from '../../auth';
import stateStore from '@/utils/state-store';
import { generateItemButtonOption } from '../../utils/util';
import ApiService from '../../utils/api-service';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode'
import printDocument from '@/utils/print-document'

export default {
  components: {
    DxToolbar,
    DxGridToolbar,
    DxItem,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxPopup,
    DxToolbarItem,
    DxDataGrid,
    DxSelection,
    DxColumn,
    DxEditing,
    DxPaging,
    DxColumnChooser,
    DxLookup,
    DxTabbedItem,
    DxTabPanelOptions,
    DxTab,
    DxRequiredRule,
    DxFilterRow,
    DxScrollView,
    DxFileUploader,
    DxButton,
    DxGridButton,
    DxNumberBox,
    DxTextBox
  },
  setup() {
    const excelRef = ref(null);
    const apiService = new ApiService('/api/mes/v1/excel/base/item');
    const vars = {};
    vars.excelRef = ref(null);
    vars.formData = reactive({});
    vars.addItem = reactive({
      visible: false,
    });
    vars.addImage = reactive({
      visible: false,
    });
    vars.addClient = reactive({
      visible: false,
    });
    vars.printQr = reactive({
      visible: false,
      image: null,
      text: '',
      quantity: 1
    });
    vars.dataSource = reactive({
      asset_type: [],
      unit: [],
      unit_price_type: [],
      main_category: [],
      middle_category: [],
      sub_category: [],
      middle_category_all: [],
      sub_category_all: [],
    });
    vars.imageUpload = reactive({
      textVisible: true,
      popupVisible: false,
      isDropZoneActive: false,
      allowedFileExtensions: ['.jpg', '.jpeg', '.gif', '.png'],
    });
    vars.baseItem = getBaseItem([
      { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
    ]);
    vars.stock = null;
    vars.client = null;
    vars.clientItem = null;
    vars.component = {};
    vars.item = reactive({
      readOnly: false,
    });
    vars.itemImage = computed(() => {
      if (!vars.formData.item_img) return null;
      if (vars.formData.item_img.startsWith('data:'))
        return vars.formData.item_img;
      return `/api/mes/v1/base/item-image/${vars.formData.item_img}`;
    });

    onMounted(() => {
      methods.loadBaseCode();

      vars.client = getBaseClient([
        { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
      ]);
    });

    // Methods
    const methods = {
      onInitialized(e, key) {
        vars.component[key] = e.component;
        if (key.includes('grid')) {
          stateStore.bind(key, e.component);
        }
      },
      showAddItemPopup() {
        vars.addItem.visible = true;
      },
      showAddClientPopup() {
        if (vars.component.gridClient) {
          vars.component.gridClient.deselectAll();
          vars.component.gridClient.refresh().then(function() {
            vars.addClient.visible = true;
          });
        } else {
          vars.addClient.visible = true;
        }
      },
      hideAddClientPopup() {
        vars.addClient.visible = false;
      },
      async addClients() {
        const rows = await vars.component.gridClient.getSelectedRowsData();

        for (let row of rows) {
          await vars.component.gridClientItem.addRow();
          vars.component.gridClientItem.cellValue(0, 'client.name', row.name);
          vars.component.gridClientItem.cellValue(0, 'client_id', row.id);
          vars.component.gridClientItem.cellValue(
            0,
            'item_code',
            vars.formData.item_code
          );
          vars.component.gridClientItem.cellValue(
            0,
            'fk_company_id',
            authService.getCompanyId()
          );
          vars.component.gridClientItem.cellValue(0, 'created', new Date());
        }
        methods.hideAddClientPopup();
      },
      onClickAddImage() {
        vars.addImage.visible = true;
      },
      async onMainCategoryChanged(e) {
        vars.formData.middle_category = null;
        vars.formData.sub_category = null;
        await methods.loadMiddleCategory(e.value);
        if (vars.component.formEditItem) vars.component.formEditItem.repaint();
      },
      async onMiddleCategoryChanged(e) {
        vars.formData.sub_category = null;
        await methods.loadSubCategory(e.value);
        if (vars.component.formEditItem) vars.component.formEditItem.repaint();
      },
      loadMiddleCategory(main_category) {
        return baseCodeLoader([main_category]).then(response => {
          vars.dataSource.middle_category = response[main_category];
        });
      },
      loadSubCategory(middle_category) {
        return baseCodeLoader([middle_category]).then(response => {
          vars.dataSource.sub_category = response[middle_category];
        });
      },
      loadBaseCode() {
        return baseCodeLoader(
          ['자산구분', '단위', '품목구분', '단가구분'],
          authService.getCompanyId()
        ).then(response => {
          vars.dataSource.asset_type = response['자산구분'];
          vars.dataSource.unit = response['단위'];
          vars.dataSource.main_category = response['품목구분'];
          vars.dataSource.unit_price_type = response['단가구분'];

          let middleFilter = [];
          vars.dataSource.main_category.forEach(item => {
            middleFilter.push({
              name: 'parent_code_id',
              op: 'eq',
              val: item.id,
            });
          });
          const middleCategory = getBaseCode([{ or: middleFilter }]);
          middleCategory.load().then(response => {
            vars.dataSource.middle_category_all = response.data;

            let subFilter = [];
            response.data.forEach(item => {
              subFilter.push({
                name: 'parent_code_id',
                op: 'eq',
                val: item.id,
              });
            });
            const subCategory = getBaseCode([{ or: subFilter }]);
            subCategory.load().then(response => {
              vars.dataSource.sub_category_all = response.data;
              // vars.component.gridItem.refresh();
            });
          });
        });
      },
      onDropZoneEnter(e) {
        if (e.dropZoneElement.id === 'dropzone-external') {
          vars.imageUpload.isDropZoneActive = true;
        }
      },
      onDropZoneLeave(e) {
        if (e.dropZoneElement.id === 'dropzone-external') {
          vars.imageUpload.isDropZoneActive = false;
        }
      },
      onValueChangedImage(e) {
        const reader = new FileReader();
        reader.onload = () => {
          vars.imageUpload.isDropZoneActive = false;
          vars.imageUpload.textVisible = false;
          vars.formData.item_img = reader.result;
        };
        reader.readAsDataURL(e.value[0]);
      },
      newItem() {
        methods.initImageUploader();
        vars.formData.id = undefined
        vars.formData.item_code = '';
        vars.formData.item_name = '';
        vars.formData.item_standard = '';
        vars.formData.packing_quantity = 0;
        vars.formData.transfer_quantity = 0;
        vars.formData.main_category = '';
        vars.formData.middle_category = '';
        vars.formData.sub_category = '';
        vars.formData.delivery_date = '';
        vars.formData.asset_type = '';
        vars.formData.moq = 0;
        vars.formData.etc = '';
        vars.formData.import_check = false;
        vars.formData.shipment_check = false;
        vars.formData.before_item_code = '';
        vars.formData.after_item_code = '';
        vars.formData.item_detail = '';
        vars.formData.end_of_use = false;
        vars.formData.end_date = '';
        vars.formData.safety_stock = 0;
        vars.formData.unit = '';
        vars.formData.register_id = '';
        vars.formData.created = new Date();
        vars.formData.sales_price = 0;
        vars.formData.purchase_price = 0;
        vars.formData.modify_id = '';
        vars.formData.modify_date = '';
        vars.formData.note1 = '';
        vars.formData.note2 = '';
        vars.formData.item_img = '';
        vars.formData.fk_company_id = authService.getCompanyId();

        vars.item.readOnly = false;
        if (vars.component.formEditItem) {
          vars.component.formEditItem.repaint();
        }
        methods.showAddItemPopup();
      },
      async editItem(e) {
        methods.initImageUploader();

        vars.formData.id = e.row.data.id;
        vars.stock = getSetupBasicStock([
          { name: 'item_code', op: 'eq', val: e.row.data.item_code },
          { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
        ]);
        vars.clientItem = getBaseClientItem([
          { name: 'item_code', op: 'eq', val: e.row.data.item_code },
          { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
        ]);

        const { data } = await baseItem.load({
          filter: [['id', '=', vars.formData.id]],
        });

        await methods.loadMiddleCategory(data[0].main_category);
        console.log(vars.dataSource.middle_category);

        await methods.loadSubCategory(data[0].middle_category);
        console.log(vars.dataSource.sub_category);
        await nextTick();

        Object.assign(vars.formData, data[0]);
        vars.item.readOnly = true;
        if (vars.component.formEditItem) {
          vars.component.formEditItem.repaint();
        }
        methods.showAddItemPopup();
      },
      async onClickItemSave() {
        if (!vars.formData.item_code || !vars.formData.item_name) {
          notifyError('필수 정보를 입력하세요');
          return;
        }

        try {
          const userId = authService._user.user_id;
          if (!vars.formData.register_id) {
            vars.formData.register_id = userId;
          }
          vars.formData.modify_id = userId;
          vars.formData.modify_date = new Date();

          if (vars.formData.id) {
            await baseItem.update(vars.formData.id, vars.formData);
          } else {
            await baseItem.insert(vars.formData);
          }
          notifyInfo('저장되었습니다');
          vars.component.gridItem.refresh();
        } catch (ex) {
          console.error(ex);
          notifyError('저장 할 내용이 없습니다');
        }
      },
      initImageUploader() {
        vars.imageUpload.textVisible = true;
        vars.imageUpload.popupVisible = false;
        vars.imageUpload.isDropZoneActive = false;
      },
      newClient() {},
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      excelUpload() {
        excelRef.value.click();
      },
      async excelFileChange({ target }) {
        if (!target.files.length) return;

        vars.component.gridItem.beginCustomLoading(
          '엑셀 데이터를 읽고 있습니다'
        );
        const fd = new FormData();
        fd.append('file', target.files[0]);

        try {
          await apiService.post('', fd);
          vars.component.gridItem.refresh();
        } catch (ex) {
          if (ex.response.data) {
            notifyError(ex.response.data);
          }
        } finally {
          vars.component.gridItem.endCustomLoading();
          target.value = null;
        }
      },
      excelSampleDownload() {
        saveAs('/api/mes/v1/excel/base/item', '품목입력양식.xlsx');
      },
      refreshQRImage () {
        const code = [
          vars.formData.item_code,
          vars.formData.item_name,
          vars.printQr.text
        ].join('|')

        QRCode.toDataURL(code, {margin: 0}, (err, url) => {
          if (err) return alert(err.message)
          vars.printQr.image = url
        })
      },
      prepareQRLabel () {
        vars.printQr.visible = true
        methods.refreshQRImage()
      },
      closeQRLabel () {
        vars.printQr.visible = false
        vars.printQr.image = null
        vars.printQr.text = ''
        vars.printQr.quantity = 1
      },
      printQRLabel () {
        const params = {paper: '20x20mm', label: '2x2', items: []}
        for (let i=0; i<vars.printQr.quantity; i++) {
          params.items.push({
            qr: vars.printQr.image
          })
        }
        printDocument('qr', params)
      }
    };

    return {
      vars,
      excelRef,
      methods,
      setupGroup,
      baseItem,
      generateItemButtonOption,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-image {
  height: 160px;
  width: 90%;
  border: 1px solid #d2d3d5;
  border-radius: 5%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}
.item-image {
  height: 100%;
  width: 100%;
  border-radius: 5%;
  background-repeat: no-repeat;
  background-position: center;
}

.qr-image {
  width: 50%;
}
</style>
