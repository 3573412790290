<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">기타입출고현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">기타입출고일자</span>
            <dx-date-box v-model:value="vars.formdata.startdate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formdata.enddate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 240px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="stockEtcItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goStockEtcDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="stock_etc.number"
            caption="입출고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="stock_etc.target_date"
            caption="일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="stock_etc.manager" caption="담당자" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="quantity" caption="수량" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column
            data-field="supply_price"
            caption="금액"
            format="currency"
          />
          <dx-column data-field="inout_type" caption="입출고유형" />
          <dx-column data-field="warehouse.wh_name" caption="입출고창고" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
          />
          <dx-column data-field="basic_stock.current_stock" caption="현재고" />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column data-field="item.safety_stock" caption="안전재고" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxColumnChooser,
  DxExport
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { stockEtcItem } from '../../data-source/stock';
import { reactive } from 'vue';
import moment from 'moment';
import stateStore from '@/utils/state-store';
import { alert } from 'devextreme/ui/dialog';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxButton,
    DxColumnChooser,
    DxExport
  },
  setup() {
    const router = useRouter();
    const vars = {};
    let startdate = new Date();
    startdate.setDate(startdate.getDate() - 7);
    vars.formdata = reactive({
      startdate: startdate,
      enddate: new Date(),
    });
    vars.dataGridInstance = {};

    const methods = {
      onGridInitialized(evt, key) {
        vars.dataGridInstance[key] = evt.component;
        stateStore.bind(key, evt.component);

        methods.searchDateRange();
      },
      goStockEtcDetail({ data }) {
        router.push({ path: `/stock/etc/${data.fk_stock_etc_id}` });
      },

      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        vars.dataGridInstance['status-list'].filter([
          [
            'stock_etc.target_date',
            '>=',
            moment(vars.formdata.startdate)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss'),
          ],
          'and',
          [
            'stock_etc.target_date',
            '<=',
            moment(vars.formdata.enddate)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss'),
          ],
        ]);
      },
      onExporting (evt) {
        stockEtcItem.exportData(evt.component, '기타입출고현황', `기타입출고현황_${Date.now()}.xlsx`)
        evt.cancel = true
      }
    };

    return { vars, methods, stockEtcItem };
  },
};
</script>
