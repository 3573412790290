<template>
  <dx-data-grid
    height="340px"
    :data-source="processPerformanceRegistration"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @initialized="onInitialized"
    @selection-changed="onSelectionChanged"
  >
    <dx-column
      data-field="number"
      caption="실적번호"
    />
    <dx-column data-field="process.process_name" caption="공정명" />
    <dx-column data-field="work_order_item.order_number" caption="작지번호" />
    <dx-column data-field="process_quantity" caption="완료수량" />
    <dx-column data-field="item_code" caption="품목코드" width="180" />
    <dx-column data-field="item.item_name" caption="품명" />
    <dx-column
      data-field="work_order_item.required_quantity"
      caption="작지수량"
      data-type="number"
    />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { processPerformanceRegistration } from '../../data-source/produce';
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: Object
  }
});
const emit = defineEmits(['change']);
const mode = ref(props.multiple ? 'multiple' : 'single');
let component = undefined

function onInitialized (e) {
  component = e.component
  setFilter()
}

function setFilter () {
  if (!component) return
  if (!props.filters || !props.filters.length) {
    component.clearFilter()
    return
  }
  component.filter(props.filters)
}

function onSelectionChanged({ selectedRowsData }) {
  if (!props.multiple && selectedRowsData)
    selectedRowsData = selectedRowsData[0];
  emit('change', selectedRowsData);
}

watch(
  () => props.filters,
  () => setFilter()
);
</script>
