<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">부적합조치현황</div>
            </dx-item>
          </dx-toolbar>
        </div>
        <div>
          <dx-form :form-data="formdata">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="조치일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: searchDateRange,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 234px)"
          :data-source="qualityNonconformanceActionItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @initialized="evt => initialized(evt, 'status-list')"
          @row-dbl-click="goDetail"
          @exporting="onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="work_order.number"
            caption="작지번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="performance.number"
            caption="실적번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="quality_management.qa_number"
            caption="검사번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="work_order.target_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="non_conformance_action.lot_no"
            caption="LOT No"
          />
          <dx-column data-field="item.item_code" caption="제품코드" />
          <dx-column data-field="item.item_name" caption="제품명" />
          <dx-column
            data-field="non_conformance_action.process_name"
            caption="공정명"
          />
          <dx-column
            data-field="non_conformance_action.equipment_name"
            caption="설비명"
          />
          <dx-column
            data-field="non_conformance_action.qa_manager"
            caption="검사자"
          />
          <dx-column
            data-field="non_conformance_action.bad_type"
            caption="불량유형"
          />
          <dx-column
            data-field="quality_management.process_quantity"
            caption="검사수량"
          />
          <dx-column
            data-field="quality_management.bad_quantity"
            caption="불량수량"
          />
          <dx-column
            data-field="project_number"
            caption="조치일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="action_manager" caption="조치담당자" />
          <dx-column data-field="action_quantity" caption="조치수량" />
          <dx-column data-field="action_detail" caption="조치내용" />
          <dx-column data-field="note" caption="적요" />
          <dx-column data-field="action_checker" caption="조치확인자" />
          <dx-column
            data-field="confirm_date"
            caption="확인일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column data-field="item.main_category" caption="대분류" />
          <dx-column data-field="item.middle_category" caption="중분류" />
          <dx-column data-field="item.sub_category" caption="소분류" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxExport
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { qualityNonconformanceActionItem } from '../../data-source/quality';
import { reactive } from 'vue';
import moment from 'moment';

const router = useRouter();
const formdata = reactive({
  startdate: new Date(),
  enddate: new Date(),
});
const components = {};
qualityNonconformanceActionItem.defaultFilters = undefined;

function initialized(evt, key) {
  components[key] = evt.component;
}

function goDetail({ column, data }) {
  if (column.name === 'work_order.number') {
    router.push({ path: `/produce/work-order/${data.fk_work_order_id}` });
  } else if (column.name === 'quality_management.qa_number') {
    router.push({
      path: `/quality/test-registration/${data.fk_quality_management_id}`,
    });
  } else if (column.name === 'performance.number') {
    router.push({
      path: `/produce/performance-registration/${data.fk_performance_id}`,
    });
  }
}

async function searchDateRange() {
  components['status-list'].filter([
    [
      'action_date',
      '>=',
      moment(formdata.startdate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss'),
    ],
    'and',
    [
      'action_date',
      '<=',
      moment(formdata.enddate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss'),
    ],
  ]);
}

function onExporting (evt) {
  qualityNonconformanceActionItem.exportData(evt.component, '부적합조치현황', `부적합조치현황_${Date.now()}.xlsx`)
  evt.cancel = true
}
</script>
