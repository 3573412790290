<script setup>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import {
  DxForm,
  DxLabel,
  DxSimpleItem,
  DxEmptyItem,
} from 'devextreme-vue/form'
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import {projectRegistration} from '@/data-source/project'
import {baseBom, baseBomProcess, baseCode} from '@/data-source/base'
import {processPerformanceRegistration} from '@/data-source/produce'
import DataGridWorkOrderItem from '@/components/produce/data-work-order-item.vue'
import DataGridProcPermReg from '@/components/produce/data-process-performance-registration.vue'
import { generateItemButtonOption } from '@/utils/util'
import { notifyInfo, notifyError } from '@/utils/notify'

import authService from '@/auth'
import moment from 'moment'
import {ref, onBeforeUnmount} from 'vue'

const formState = ref('read')
const loading = ref(false)
const popup = ref({show: false, type: 'work', filter: null})
const confirm = ref({show: false})
const popupRefreshId = ref(Date.now())
let formComponent = null
const zoom = '1.8';
document.body.style.zoom = zoom

function onShowing(e) {
    setTimeout(() => {
      const transformValue = e.component._$content[0].style.transform;
      const translateMatch = transformValue.match(/translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/);
      e.component._$content[0].style.transform = `translate(${translateMatch[1] / zoom}px, ${translateMatch[2] / zoom}px)`;
    }, 7);
}
const formData = ref({
  work_order: null,
  project: null,
  performance: {
    fk_manager_id: authService.user.emp_name
  }
})

const itemList = ref(null)
const progressItems = ref(null)
const employeeList = ref([])
const progressItemCount = {}

// token 기간 늘리기
let authtoken = null
const tmk = setInterval(() => { authtoken = authService.token }, 60 * 1000)
onBeforeUnmount(() => { clearInterval(tmk) })
loadManager()

function initialized (evt) {
  formComponent = evt.component
}

async function loadManager () {
  const {data: parentItem} = await baseCode.load({filter: [['code_name', '=', '현장공정담당자'], 'and', ['fk_company_id', '=', authService.getCompanyId()]]})
  if (!parentItem.length) return
  const parentId = parentItem[0].id

  const {data: codes} = await baseCode.load({filter: ['parent_code_id', '=', parentId]})
  employeeList.value = codes
}

function checkState (flags) {
  return flags.includes(formState.value)
}

function addItem () {
  clearForm()
  formState.value = 'add'
}

async function editItem () {
  if (!formData.value.performance) return
  formState.value = 'edit'
}

async function updateItem () {
  loading.value = true
  if (formState.value === 'add') {
    if (formData.value.performance.required_quantity < formData.value.performance.process_quantity) {
      notifyInfo('완료수량이 작지수량보다 클수 없습니다')
      loading.value = false
      return
    }
    try {
      const params = Object.assign({}, formData.value.performance)
      const worker = employeeList.value.find(v => v.code_name === params.fk_manager_id)
      params.worker = worker.code_name
      params.fk_company_id = authService.getCompanyId()
      delete params.fk_manager_id
      delete params.work_order_item
      await processPerformanceRegistration.insert(params)

      notifyInfo('저장되었습니다')
      clearForm()
      formState.value = 'read'
    }
    catch (ex) {
      console.error(ex)
      notifyError('저장에 실패하였습니다')
    }
    finally {
      loading.value = false
    }
  }
  else {
    // const process_quantity = (progressItemCount[formData.value.performance.fk_process_id] || 0) + formData.value.performance.process_quantity
    // if (formData.value.performance.required_quantity < process_quantity) {
    //   notifyInfo('완료수량이 작지수량보다 클수 없습니다')
    //   loading.value = false
    //   return
    // }
    try {
      const manager = employeeList.value.find(v => v.code_name === formData.value.performance.fk_manager_id)

      await processPerformanceRegistration.update(
        formData.value.performance.number, 
        {
          worker: manager.emp_name, 
          process_quantity: formData.value.performance.process_quantity,
          fk_process_id: formData.value.performance.fk_process_id
        }
      )

      notifyInfo('저장되었습니다')
      clearForm()
      formState.value = 'read'
    }
    catch (ex) {
      console.error(ex)
      notifyError('저장에 실패하였습니다')
    }
    finally {
      loading.value = false
    }
  }
}

function clearForm () {
  if (formData.value.work_order) {
    location.reload()
  }
  else{
    formComponent.beginUpdate()
    formData.value.work_order = null
    formData.value.project = null
    formData.value.performance = {fk_manager_id: authService.user.emp_name}
    progressItems.value = null
    popupRefreshId.value = Date.now()
    formComponent.endUpdate()
    setProgressCount([])
  }
}

function removeItem () {
  if (formData.value.performance.number) {
    confirm.value.show = true
  }
  else {
    clearForm()
    formState.value = 'read'
  }
}

async function removeItemExec () {
  try {
    await processPerformanceRegistration.remove(formData.value.performance.number)
    notifyInfo('삭제되었습니다')
    clearForm()
    formState.value = 'read'
  }
  catch (ex) {
    console.error(ex)
    notifyError('삭제에 실패하였습니다')
  }
}

function getPopupCb (name) {
  return () => {
    if (name === 'perm') {
      popup.value.filter = [
        ['created', '>=', moment().startOf('d').format('YYYY-MM-DD HH:mm:ss')]
      ]
    }
    else {
      popup.value.filter = null
    }
    popup.value.type = name
    popup.value.show = true
  }
}

function setProgressCount (progress) {
  for (const key in progressItemCount) {
    delete progressItemCount[key]
  }
  for (const prc of progress) {
    if (progressItemCount[prc.fk_process_id] === undefined) progressItemCount[prc.fk_process_id] = 0
    progressItemCount[prc.fk_process_id] += prc.process_quantity
  }
}

async function loadItem (item) {
  if (popup.value.type === 'work') {
    console.log(item)
    formData.value.work_order = item.work_order
    formData.value.performance.item_code = item.item_code
    itemList.value = [item]
    // const {data: workOrderItems} = await produceWorkOrderItem1.load({filter: ['fk_work_order_id', '=', item.fk_work_order_id]})
    // itemList.value = workOrderItems
  }
  else if (popup.value.type === 'perm') {
    // const filter = [
    //   ['item_code', '=', item.item_code],
    //   ['created', '>=', moment().startOf('d').format('YYYY-MM-DD HH:mm:ss')],
    //   'and', ['fk_company_id', '=', authService.getCompanyId()]
    // ]
    // const {data: progress} = await processPerformanceRegistration.load({filter, skip: 0, take: 1000})
    // setProgressCount(progress)
    setProgressCount([item])
    formData.value.work_order = { number: item.work_order_item.order_number }
    formData.value.performance = item
    itemList.value = [item]
    const worker = employeeList.value.find(v => v.code_name === formData.value.performance.worker)
    if (worker) formData.value.performance.fk_manager_id = worker.code_name
  }
  
  popup.value.show = false
  popup.value.type = null
}

function progressItemColor (idx = 0) {
  const colors = [
    '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
    '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92']

  return {'backgroundColor': colors[idx % 9]}
}

async function onFormDataChange (evt) {
  if (evt.dataField === 'performance.item_code') {
    if (formData.value.performance && formData.value.performance.item_code) {
      let itemId = null
      if (!formData.value.performance.number) {
        const item = itemList.value.find(v => v.item_code === formData.value.performance.item_code)
        const {data: project} = await projectRegistration.load({filter: ['project_number', '=', item.project_number]})
        formData.value.project = project.length ? project[0] : null

        const filter = [
          ['item_code', '=', item.item_code],
          ['created', '>=', moment().startOf('d').format('YYYY-MM-DD HH:mm:ss')],
          'and', ['fk_company_id', '=', authService.getCompanyId()]
        ]
        // const {data: progress} = await processPerformanceRegistration.load({filter, skip: 0, take: 1000})
        // setProgressCount(progress)
        setProgressCount([])

        formData.value.performance.work_order_item = item
        formData.value.performance.fk_work_order_item = item.id
        formData.value.performance.project_number = item.project_number
        formData.value.performance.process_quantity = item.required_quantity
        formData.value.performance.item = item.item

        itemId = item.item.id
      }
      else {
        const {data: project} = await projectRegistration.load({filter: ['project_number', '=', formData.value.performance.project_number]})
        formData.value.project = project.length ? project[0] : null
        itemId = formData.value.performance.item.id
      }

      const {data: bom} = await baseBom.load({
          filter: [
            ['item_id', '=', itemId], 
            'and', ['fk_company_id', '=', authService.getCompanyId()]
          ]
      })

      if (bom.length) {
        const {data: itemProcess} = await baseBomProcess.load({filter: ['bom_id', '=', bom[0].id]})
        progressItems.value = itemProcess.map(v => v.process)
      }
    }
    else {
      formData.value.project = null
    }
  }
}

function logout () {
  authService.logOut()
  location.href = '/'
}
</script>

<template>
  <div class="mobile-container back-colored">
    <dx-load-panel v-model:visible="loading" :show-pane="true" />
    <dx-toolbar class="back-colored">
      <dx-item location="before">
        <div class="content-title" id="process-performance-registration">공정실적등록</div>
      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto"
        widget="dxButton"
        :options="{
          text: '신규',
          type: 'copy',
          disabled: checkState(['add', 'edit']),
          onClick: addItem,
        }"
      />
      <dx-item
        location="after"
        locate-in-menu="auto"
        widget="dxButton"
        :options="{
          text: '수정',
          type: 'normal',
          disabled: !formData.performance,
          onClick: editItem,
        }"
      />
      <dx-item
        location="after"
        locate-in-menu="auto"
        widget="dxButton"
        :options="{
          text: '저장',
          type: 'success',
          disabled: checkState(['read']),
          onClick: updateItem,
        }"
      />
      <dx-item
        location="after"
        locate-in-menu="auto"
        widget="dxButton"
        :options="{
          text: checkState(['add']) ? '취소' : '삭제',
          type: 'danger',
          onClick: removeItem,
        }"
      />
      <dx-item
        location="after"
        locate-in-menu="auto"
        widget="dxButton"
        :options="{
          text: '로그아웃',
          type: 'default',
          onClick: logout,
        }"
      />
    </dx-toolbar>
    <dx-form 
      :form-data="formData" 
      :show-colon-after-label="false" class="form-style" 
      :col-count="2"
      @initialized="evt => initialized(evt)"
      @field-data-changed="onFormDataChange">
      <dx-simple-item
        data-field="work_order.number"
        css-class="reg-form-input"
        :editor-options="{
          placeholder: '(QR 코드 스캔 or 찾기)',
          readOnly: !checkState(['add']),
          ...generateItemButtonOption('search', getPopupCb('work'))
        }">
        <dx-label text="작지번호" />
      </dx-simple-item>
      <dx-empty-item />
      <dx-simple-item
        data-field="performance.number"
        css-class="reg-form-input"
        :editor-options="{
          placeholder: '(자동 채번)', readOnly: checkState(['add']),
          ...generateItemButtonOption('search', getPopupCb('perm'))
        }">
        <dx-label text="실적번호" />
      </dx-simple-item>
      <dx-simple-item
        data-field="project.contract_name"
        css-class="reg-form-input"
        :editor-options="{readOnly: true}">
        <dx-label text="프로젝트명" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.item_code"
        css-class="reg-form-input"
        editor-type="dxSelectBox"
        :col-span="2"
        :editor-options="{
          dropDownOptions:{
            onShowing: onShowing
          },
          dataSource: itemList, 
          displayExpr: 'item.item_name',
          valueExpr: 'item.item_code',
          readOnly: itemList === null
        }">
        <dx-label text="생산품목" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.item.item_code"
        css-class="reg-form-input"
        :editor-options="{readOnly: true}">
        <dx-label text="품목코드" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.item.item_standard"
        css-class="reg-form-input"
        :editor-options="{readOnly: true}">
        <dx-label text="규격" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.work_order_item.required_quantity"
        css-class="reg-form-input"
        :editor-options="{readOnly: true}">
        <dx-label text="작지수량" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.process_quantity"
        css-class="reg-form-input"
        editor-type="dxNumberBox"
        :editor-options="{
          showSpinButtons: true,
          readOnly: !checkState(['add', 'edit'])
        }">
        <dx-label text="완료수량" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.fk_process_id"
        css-class="reg-form-input"
        editor-type="dxSelectBox"
        :editor-options="{
          dropDownOptions:{
            onShowing: onShowing
          },
          dataSource: progressItems,
          displayExpr: 'process_name',
          valueExpr: 'id',
          readOnly: !progressItems
        }">
        <dx-label text="처리공정" />
      </dx-simple-item>
      <dx-simple-item
        data-field="performance.fk_manager_id"
        css-class="reg-form-input"
        editor-type="dxSelectBox"
        :editor-options="{
          dropDownOptions:{
            onShowing: onShowing
          },
          dataSource: employeeList,
          displayExpr: 'code_name',
          valueExpr: 'code_name',
          readOnly: !checkState(['add', 'edit'])
        }">
        <dx-label text="담당자" />
      </dx-simple-item>
    </dx-form>

    <div class="progress-panel">
      <div class="progress-title">전체 공정 진행 현황</div>
      <div class="progress-item-wrap" v-if="progressItems">
        <div class="progress-item" :style="progressItemColor(idx)" v-for="(item, idx) in progressItems" :key="idx">
          <div class="progress-item-bar"></div>
          <div class="progress-item-name">
            <div>{{item.process_name}}</div>
            <div class="progress-item-count" v-if="progressItemCount[item.id]">({{progressItemCount[item.id]}})</div>
          </div>
        </div>
      </div>
      <div class="progress-item-wrap empty" v-else>
        선택된 작업이 없습니다
      </div>
    </div>

    <dx-popup
      v-model:visible="popup.show"
      content-template="popup-content"
      title="항목선택"
      position="left top"
      width="55vw" height="55vh"
      :fullscreen="true"
      :close-on-outside-click="true"
      :resize-enabled="true">
      <template #popup-content>
        <data-grid-work-order-item :key="popupRefreshId" v-show="popup.type === 'work'" height="340px" :filters="['unproduced_quantity', '>', 0]" @change="loadItem" />
        <data-grid-proc-perm-reg :key="popupRefreshId" v-show="popup.type === 'perm'" :filters="popup.filter" @change="loadItem" />
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="confirm.show"
      content-template="popup-content"
      title="삭제 확인"
      width="14rem" height="10rem"
      :wrapper-attr="{class: 'delete-confirm'}"
      :close-on-outside-click="false"
      :show-close-button="false"
      :drag-enabled="false"
      :resize-enabled="false">
      <dx-position my="left top" of="#process-performance-registration" />
      <dx-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="{text: '닫기', onClick: () => { confirm.show = false }}" />
      <dx-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="{text: '삭제', onClick: removeItemExec}" />
      <template #popup-content>
        삭제하시겠습니까?
      </template>
    </dx-popup>
  </div>
</template>

<style lang="scss" scoped>
:deep(.mobile-container .delete-confirm .dx-popup-normal) {
  margin: 100px 0 0 0;
}
.mobile-container {
  padding: 10px; margin: 10px;
}
.form-style {
  margin: 10px 0 0;
}

.progress-panel {
  position: absolute;
  bottom: 14px; left: 14px; right: 14px;
  padding: 14px;
  background-color: #e3e3e3;
  border-radius: 10px;
}
.progress-title {
  font-size: 1.4em;
}
.progress-item-wrap {
  // width: 300px;
  display: flex;
  align-items: center;
  height: 100px;
  border: 1px dashed #c7c7c7;
  border-radius: 6px;

  &.empty {
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    color: #808080;
  }
}
.progress-item {
  height: 60px;
  flex: 1 1 auto;
  margin: 0 10px;
  border-radius: 5px;
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;

  color: white;
  border: 1px solid white;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
}
.progress-item-bar {
  position: absolute;
  top: 0; bottom: 0; left: 0;
  background-color: #c2cf9f;
  z-index: 1;
}
.progress-item-name {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  display: flex; justify-content: center; align-items: center;
  text-align: center;
  z-index: 2;
}
.progress-item-count {
  color: #b4b4b4;
  margin-left: 4px;
}
</style>