<template>
  <dx-data-grid
    :data-source="shipmentQuote"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="quote_number" caption="견적번호" />
    <dx-column
      data-field="quote_date"
      caption="견적일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="client_company" caption="고객업체" />
    <dx-column data-field="client_manager" caption="업체담당" />
    <dx-column data-field="quote_department" caption="견적부서" />
    <dx-column data-field="quote_manager" caption="견적담당" />
    <dx-column data-field="quote_type" caption="견적구분" />
    <dx-column data-field="vat_type" caption="부가세구분" />
    <dx-column data-field="payment_terms" caption="결재조건" />
    <dx-column
      data-field="delivery_date"
      caption="납품기한"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="delivery_place" caption="납품장소" />
    <dx-column data-field="previous_quote_number" caption="이전견적" />
    <dx-column data-field="project_number" caption="프로젝트" />
    <dx-column data-field="end_user" caption="EndUser" />
    <dx-column data-field="note" caption="참고사항" />
    <dx-column data-field="etc" caption="비고" />
    <dx-column
      data-field="supply_price"
      caption="공급가"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="vat"
      caption="부가세"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="total_price"
      caption="합계금액"
      data-type="number"
      format="currency"
    />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { shipmentQuote } from '../../data-source/shipment';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      shipmentQuote,
      onSelectionChanged,
    };
  },
};
</script>
