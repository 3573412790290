import RestlessStore from './restless-store';

const shipmentQuote = new RestlessStore('/api/mes/v1/shipment/quote');
const shipmentQuoteItem = new RestlessStore('/api/mes/v1/shipment/quote_item');
const shipmentQuoteItem2 = new RestlessStore(
  '/api/mes/v1/shipment/quote_item2'
);

const shipmentOrder = new RestlessStore('/api/mes/v1/shipment/order');
const shipmentOrderItem = new RestlessStore('/api/mes/v1/shipment/order_item');

const shipmentRelease = new RestlessStore('/api/mes/v1/shipment/release');
const shipmentReleaseItem = new RestlessStore(
  '/api/mes/v1/shipment/release_item'
);

const shipmentLend = new RestlessStore('/api/mes/v1/shipment/lend');
const shipmentRetrieve = new RestlessStore('/api/mes/v1/shipment/retrieve');

const getShipmentQuoteItem = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/shipment/quote_item');
  instance.defaultFilters = defaultFilters;
  return instance;
};

const getShipmentQuoteItem2 = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/shipment/quote_item2');
  instance.defaultFilters = defaultFilters;
  return instance;
};

const getShipmentOrderItem = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/shipment/order_item');
  instance.defaultFilters = defaultFilters;
  return instance;
};

const getShipmentReleaseItem = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/shipment/release_item');
  instance.defaultFilters = defaultFilters;
  return instance;
};

export {
  shipmentQuote,
  shipmentQuoteItem,
  shipmentQuoteItem2,
  shipmentOrder,
  shipmentOrderItem,
  shipmentRelease,
  shipmentReleaseItem,
  shipmentLend,
  shipmentRetrieve,
  getShipmentQuoteItem,
  getShipmentQuoteItem2,
  getShipmentOrderItem,
  getShipmentReleaseItem,
};
