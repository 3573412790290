<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">생산입고현황</div>
            </dx-item>
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">생산입고일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onInitialized(evt, 'status')"
          :data-source="performanceItem1"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="performance_registration.number"
            caption="생산입고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="performance_registration.target_date"
            caption="입고일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column data-field="work_order_number" caption="작지번호" />
          <dx-column
            data-field="work_order.target_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
            :allow-sorting="false"
          />
          <dx-column
            data-field="performance_registration.manager"
            caption="담당자"
          />
          <dx-column data-field="item.item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="performance_registration.required_quantity"
            caption="작업지시수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.workOrderQuantity"
          />
          <dx-column
            data-field="production_receiving_quantity"
            caption="생산입고수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.productionReceivingQuantity"
          />
          <dx-column
            data-field="check_quantity"
            caption="검수수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.checkQuantity"
          />
          <dx-column
            data-field="bad_quantity"
            caption="불량수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.badQuantity"
          />
          <dx-column
            data-field="action_quantity"
            caption="재작업수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.actionQuantity"
          />
          <dx-column
            data-field="good_quantity"
            caption="양품수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.goodQuantity"
          />
          <dx-column
            data-field="check_yn"
            caption="검수완료"
            data-type="boolean"
          />
          <dx-column data-field="warehouse.wh_name" caption="입고창고" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.availableStock"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.currentStock"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column data-field="item.safety_stock" caption="안전재고" />
          <dx-column data-field="project_number" caption="프로젝트번호" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSorting,
  DxFilterRow,
  DxColumnChooser,
  DxExport,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { performanceItem1 } from '../../data-source/produce';
import { reactive } from 'vue';
import stateStore from '@/utils/state-store';
import moment from 'moment';
import { alert } from 'devextreme/ui/dialog';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxFilterRow,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
    DxExport,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.grid = {};
    vars.formData = reactive({
      startDate: new Date(),
      endDate: new Date(),
    });
    vars.formData.startDate.setDate(vars.formData.startDate.getDate() - 7);

    const methods = {
      onInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`produce-perform-reg-${key}`, evt.component);

        methods.initSorting();
        methods.searchDateRange();
      },
      initSorting() {
        const columns = vars.grid['status'].getVisibleColumns();
        const col = columns.filter(item => item.sortOrder);
        if (col.length == 0) {
          const defaultName = 'performance_registration.target_date';
          const defaultSort = columns.filter(item => item.name == defaultName);
          if (defaultSort.length > 0) {
            vars.grid['status'].columnOption(
              defaultSort[0].index,
              'sortOrder',
              'desc'
            );
          }
        }
      },
      goDetail({ data }) {
        router.push({
          path: `/produce/performance-registration/${data.performance_registration.id}`,
        });
      },
      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        vars.grid['status'].filter([
          [
            'performance_registration.target_date',
            '>=',
            moment(vars.formData.startDate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'performance_registration.target_date',
            '<=',
            moment(vars.formData.endDate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ]);
      },
      workOrderQuantity(rowData) {
        if (!rowData.performance_registration) return '0';
        return rowData.performance_registration.required_quantity;
      },
      productionReceivingQuantity(rowData) {
        if (!rowData.production_receiving_quantity) return '0';
        return rowData.production_receiving_quantity;
      },
      checkQuantity(rowData) {
        if (!rowData.check_quantity) return '0';
        return rowData.check_quantity;
      },
      badQuantity(rowData) {
        if (!rowData.bad_quantity) return '0';
        return rowData.bad_quantity;
      },
      actionQuantity(rowData) {
        if (!rowData.action_quantity) return '0';
        return rowData.action_quantity;
      },
      goodQuantity(rowData) {
        if (!rowData.good_quantity) return '0';
        return rowData.good_quantity;
      },
      availableStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.available_stock;
      },
      currentStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.current_stock;
      },
      onExporting(evt) {
        performanceItem1.exportData(
          evt.component,
          '생산입고현황',
          `생산입고현황_${Date.now()}.xlsx`
        );
        evt.cancel = true;
      },
    };

    return { vars, methods, performanceItem1 };
  },
};
</script>
