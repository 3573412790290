<script setup>
import { DxPopup } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxTabbedItem,
  DxTabPanelOptions,
  DxTab,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { ref, reactive, watch, computed, nextTick, defineProps, defineEmits } from 'vue';
import { getSetupBasicStock } from '@/data-source/setup'
import { baseItem, getBaseClientItem } from '@/data-source/base';
import authService from '@/auth';
import stateStore from '@/utils/state-store';

const props = defineProps({
  itemId: {type: Number},
})
const emit = defineEmits(['update:visible'])
const init = ref(false)
const formData = reactive({})
const components = {}

const clientItem = getBaseClientItem([
  { name: 'item_code', op: 'eq', val: '' },
  { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
])
const stock = getSetupBasicStock([
  { name: 'item_code', op: 'eq', val: '' },
  { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() },
])

watch(
  () => props.itemId,
  () => initById(props.itemId)
);

const itemImage = computed(() => {
  if (!formData.item_img) return null
  if (formData.item_img.startsWith('data:')) return formData.item_img
  return`/api/mes/v1/base/item-image/${formData.item_img}`
})

async function initById (itemId) {
  init.value = false
  if (!itemId) return
  const {data: itemData} = await baseItem.byKey(props.itemId)
  Object.assign(formData, itemData)
  clientItem.defaultFilters[0].val = itemData.item_code
  stock.defaultFilters[0].val = itemData.item_code
  await nextTick()

  if (components['popup-client-item']) components['popup-client-item'].refresh() 
  if (components['stock']) components['stock'].refresh()
  // if (components['price']) components['price'].refresh()
  init.value = true
}

function initialized(e, key) {
  components[key] = e.component;
  stateStore.bind(key, e.component);
}

initById()
</script>

<template>
<dx-popup
    :visible="props.visible"
    content-template="popup-content"
    title="품목상세정보"
    width="70%"
    height="80%"
    :resize-enabled="true"
    :close-on-outside-click="true"
    @update:visible="value => emit('update:visible', value)">

    <template #popup-content>
      <dx-scroll-view width="100%" height="100%">
        <dx-form
          :form-data="formData"
          :col-count="1"
          :show-colon-after-label="false"
          :read-only="true"
          @initialized="evt => initialized(evt, 'popup-item-detail')">
          <template #image-template="{}">
            <div class="form-image">
              <img
                class="item-image"
                :src="itemImage.value"
                v-if="formData.item_img"
                alt=""
              />
            </div>
          </template>
          <dx-group-item :col-count="4">
            <dx-group-item :col-span="2" :col-count="2">
              <dx-group-item :col-span="1">
                <dx-simple-item template="image-template" />
              </dx-group-item>
              <dx-group-item :col-span="1" :col-count="1">
                <dx-simple-item
                  data-field="item_code"
                  :col-span="1">
                  <dx-label text="품목코드" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item
                  data-field="main_category">
                  <dx-label text="대분류" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item
                  data-field="asset_type">
                  <dx-label text="자산구분" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item
                  data-field="safety_stock"
                  editor-type="dxNumberBox"
                  :col-span="1">
                  <dx-label text="안전재고" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item data-field="import_check" :col-span="1">
                  <dx-label text="수입검사" :show-colon="false" />
                </dx-simple-item>
              </dx-group-item>
              <dx-group-item :col-span="2" :col-count="2">
                <dx-simple-item data-field="item_detail" :col-span="2">
                  <dx-label text="품목설명" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item data-field="note1" :col-span="2">
                  <dx-label text="참고1" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item data-field="note2" :col-span="2">
                  <dx-label text="참고2" :show-colon="false" />
                </dx-simple-item>
                <dx-simple-item
                  data-field="etc"
                  :col-span="2"
                  :editor-options="{ height: 82 }">
                  <dx-label text="비고" :show-colon="false" />
                </dx-simple-item>
              </dx-group-item>
            </dx-group-item>
            <dx-group-item :col-span="2" :col-count="2">
              <dx-simple-item data-field="item_name" :col-span="1">
                <dx-label text="품명" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="item_standard" :col-span="1">
                <dx-label text="규격" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="middle_category">
                <dx-label text="중분류" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="sub_category">
                <dx-label text="소분류" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="moq"
                editor-type="dxNumberBox"
                :col-span="1"
                :editor-options="{
                  format: 'fixedPoint',
                }">
                <dx-label text="MOQ" :show-colon="false" />
              </dx-simple-item>

              <dx-simple-item data-field="unit">
                <dx-label text="단위" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="packing_quantity"
                editor-type="dxNumberBox"
                :col-span="1"
                :editor-options="{
                  format: 'fixedPoint',
                }"
              >
                <dx-label text="포장단위수량" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="transfer_quantity"
                editor-type="dxNumberBox"
                :col-span="1"
                :editor-options="{
                  format: 'fixedPoint',
                }"
              >
                <dx-label text="이송단위수량" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="shipment_check" :col-span="1">
                <dx-label text="출하검사" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="delivery_date" :col-span="1">
                <dx-label text="표준납기일" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="sales_price"
                editor-type="dxNumberBox"
                :col-span="1"
                :editor-options="{
                  format: 'currency',
                }"
              >
                <dx-label text="매출단가" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="purchase_price"
                editor-type="dxNumberBox"
                :col-span="1"
                :editor-options="{
                  format: 'currency',
                }"
              >
                <dx-label text="매입단가" :show-colon="false" />
              </dx-simple-item>

              <dx-simple-item data-field="before_item_code" :col-span="1">
                <dx-label text="변경전 품목코드" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="after_item_code" :col-span="1">
                <dx-label text="변경후 품목코드" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="end_of_use" :col-span="1">
                <dx-label text="사용종료" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="end_date"
                editor-type="dxDateBox"
                :col-span="1"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                }"
              >
                <dx-label text="종료일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="register_id">
                <dx-label text="최초등록자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="created"
                editor-type="dxDateBox"
                :col-span="1"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                }"
              >
                <dx-label text="최초등록일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="modify_id">
                <dx-label text="최종수정자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="modify_date"
                editor-type="dxDateBox"
                :col-span="1"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                }"
              >
                <dx-label text="최종수정일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
          <dx-group-item>
            <dx-tabbed-item>
              <dx-tab-panel-options :defer-rendering="false" />
              <dx-tab title="거래처 품목코드">
                <dx-data-grid
                  class="fixed-header-table"
                  height="420px"
                  :data-source="clientItem"
                  :remote-operations="true"
                  :show-borders="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  @initialized="evt => initialized(evt, 'popup-client-item')">

                  <dx-column
                    data-field="client.name"
                    caption="거래처명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="client_item_code"
                    caption="거래처품번"
                  />
                  <dx-column
                    data-field="unit_price_type"
                    caption="단가구분"
                  />
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                  />
                  <dx-column data-field="note" caption="참고" />
                  <dx-column
                    data-field="created"
                    caption="등록일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="false"
                  />
                  <dx-column data-field="etc" caption="비고" />
                  <dx-column
                    data-field="client_id"
                    caption="거래처ID"
                    :visible="false"
                  />
                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    :visible="false"
                  />
                  <dx-column
                    data-field="fk_company_id"
                    caption="회사ID"
                    :visible="false"
                  />
                  <dx-paging :page-size="20" />
                </dx-data-grid>
              </dx-tab>
              <dx-tab title="단가이력정보">
                <dx-data-grid
                  class="fixed-header-table"
                  height="420px"
                  :remote-operations="true"
                  :show-borders="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  @initialized="evt => initialized(evt, 'price')"
                >
                  <dx-column
                    data-field="id"
                    caption="아이디"
                    :allow-editing="false"
                    :visible="false"
                  />
                  <dx-column data-field="name" caption="거래처명" />
                  <dx-column data-field="price_sel" caption="단가구분" />
                  <dx-column data-field="price" caption="표준단가" />
                  <dx-column caption="최초거래">
                    <dx-column
                      data-field="price_date_1"
                      caption="거래일자"
                    />
                    <dx-column data-field="price_1" caption="단가" />
                  </dx-column>
                  <dx-column caption="최종거래">
                    <dx-column
                      data-field="price_date_2"
                      caption="거래일자"
                    />
                    <dx-column data-field="price_2" caption="단가" />
                  </dx-column>
                  <dx-column caption="최저가거래">
                    <dx-column
                      data-field="price_date_3"
                      caption="거래일자"
                    />
                    <dx-column data-field="price_3" caption="단가" />
                  </dx-column>
                  <dx-column caption="최고가거래">
                    <dx-column
                      data-field="price_date_4"
                      caption="거래일자"
                    />
                    <dx-column data-field="price_4" caption="단가" />
                  </dx-column>
                  <dx-paging :page-size="20" />
                </dx-data-grid>
              </dx-tab>
              <dx-tab title="창고별재고">
                <dx-data-grid
                  class="fixed-header-table"
                  height="420px"
                  :data-source="stock"
                  :remote-operations="true"
                  :show-borders="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  @initialized="evt => initialized(evt, 'stock')"
                >
                  <dx-column
                    data-field="warehouse.wh_name"
                    caption="창고명"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="assign_stock"
                    caption="할당재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-paging :page-size="20" />
                </dx-data-grid>
              </dx-tab>
            </dx-tabbed-item>
          </dx-group-item>
        </dx-form>
      </dx-scroll-view>
    </template>
  </dx-popup>
</template>