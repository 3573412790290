<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before"
              ><div class="content-title">셋업관리</div></dx-item
            >
          </dx-toolbar>
        </div>
        <dx-tab-panel>
          <dx-item title="사용자관리" icon="user">
            <template #default>
              <div class="tab-first">
                <setup-user />
              </div>
            </template>
          </dx-item>
          <dx-item title="기초재고관리" icon="cart">
            <template #default>
              <div class="tab-second">
                <setup-stock />
              </div>
            </template>
          </dx-item>
          <dx-item title="자동채번관리" icon="bulletlist">
            <template #default>
              <div class="tab-third">
                <setup-numbering />
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</template>

<script>
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
import DxToolbar from 'devextreme-vue/toolbar';

import SetupUser from './setup-user.vue';
import SetupStock from './setup-stock.vue';
import SetupNumbering from './setup-numbering.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxToolbar,
    SetupUser,
    SetupStock,
    SetupNumbering,
  },
  setup() {
    return {};
  },
};
</script>

<style>
.tab-first,
.tab-second,
.tab-third {
  padding: 8px;
}
</style>
