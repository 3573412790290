<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">가입고현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">가입고일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status')"
          :data-source="purchasePreReceivingItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goReceivingDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="prereceiving.prereceiving_number"
            caption="가입고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="prereceiving.prereceiving_date"
            caption="가입고일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column
            data-field="prereceiving.client_company"
            caption="공급업체"
          />
          <dx-column
            data-field="prereceiving.receiving_department"
            caption="입고부서"
          />
          <dx-column
            data-field="prereceiving.receiving_manager"
            caption="입고담당자"
          />
          <dx-column
            data-field="prereceiving.receiving_type"
            caption="입고구분"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="order_quantity"
            caption="발주수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="prereceiving_quantity"
            caption="가입고수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="order_number" caption="발주번호" />
          <dx-column data-field="client_item_number" caption="공급사품번" />
          <dx-column data-field="item.item_detail" caption="품목설명" />
          <dx-column data-field="lot_number" caption="LOT번호" />
          <dx-column data-field="project_number" caption="프로젝트번호" />
          <dx-column data-field="check_yn" caption="검사완료" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSorting,
  DxFilterRow,
  DxColumnChooser,
  DxExport
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { purchasePreReceivingItem } from '../../data-source/purchase';
import { reactive } from 'vue';
import stateStore from '@/utils/state-store';
import moment from 'moment';
import { alert } from 'devextreme/ui/dialog';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxFilterRow,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
    DxExport,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.grid = {};
    vars.formData = reactive({
      startDate: new Date(),
      endDate: new Date(),
    });
    vars.formData.startDate.setDate(vars.formData.startDate.getDate() - 7);

    const methods = {
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`purchase-prereceiving-${key}`, evt.component);

        methods.initSorting();
        methods.searchDateRange();
      },
      initSorting() {
        const columns = vars.grid['status'].getVisibleColumns();
        const col = columns.filter(item => item.sortOrder);
        if (col.length == 0) {
          const defaultName = 'prereceiving.prereceiving_date';
          const defaultSort = columns.filter(item => item.name == defaultName);
          if (defaultSort.length > 0) {
            vars.grid['status'].columnOption(
              defaultSort[0].index,
              'sortOrder',
              'desc'
            );
          }
        }
      },
      goReceivingDetail({ data }) {
        router.push({
          path: `/purchase/pre-receiving/${data.prereceiving.id}`,
        });
      },
      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        vars.grid['status'].filter([
          [
            'prereceiving.prereceiving_date',
            '>=',
            moment(vars.formData.startDate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'prereceiving.prereceiving_date',
            '<=',
            moment(vars.formData.endDate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ]);
      },
      onExporting (evt) {
        purchasePreReceivingItem.exportData(evt.component, '가입고현황', `가입고현황_${Date.now()}.xlsx`)
        evt.cancel = true
      }
    };

    return { vars, methods, purchasePreReceivingItem };
  },
};
</script>
