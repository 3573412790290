import { template } from 'lodash'
import ApiService from './api-service'
import authService from '../auth';
import {businessNumberFormat} from './util'

const apiService = new ApiService('/api/mes/v1/common/companies');
const _host = location.origin
let _company = null
        
const documentWrapper = (body, title='문서출력') => {
  return `
    <html lang="ko">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
      <style>
        body { font-family: sans-serif; }
        .print-document {position: relative;}
        table {width: 100%; border-collapse: collapse;}
        th, td { padding-top: 4px; padding-bottom: 3px; font-size: 12px; }
        th { padding-left: 8px; padding-right: 8px; text-align: justify; text-align-last: justify; white-space: nowrap; }
        td { padding-left: 8px; padding-right: 8px; }
        table.lined-all th,
        table.lined-all td { border: 1px solid #000; }
        table.lined-outer { border: 1px solid #000; } 
        .title {font-size: 24px; max-width: 240px; margin: 20px auto;}
        .subtitle {font-size: 21px;}
        .align-left { text-align: left; }
        .align-center { text-align: center; }
        .align-right { text-align: right; }
        .print-button {
          border: 1px solid #b1b1b1;
          border-radius: 8px;
          padding: 0 20px;
          height: 30px;
          position: fixed;
          top: 10px;
          left: 10px;
          background-color: #d8d8d8;
          color: #414141;
          box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
          z-index: 10;
        }

        @media print {
          .print-button { display: none; }
        }
      </style>
    </head>
    <body>
      <button type="button" class="print-button" onclick="window.print()">인쇄</button>
      ${body}
    </body>
    </html>
  `
}

const templates = {
  quote: `
    <table class="print-document">
      <colgroup>
        <col style="width:50%"/>
        <col style="width:50%"/>
      </colgroup>
      <thead>
        <tr>
          <th colspan="2">
            <div class="title">견 적 서</div>
            <img src="<%= _host %>/img/logo.gif" style="position:absolute;top:0;right:20px;height:60px;"/>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table>
              <tr>
                <th>수 신</th>
                <td>:</td>
                <td><%= client_company %></td>
              </tr>
              <tr>
                <th>참 조</th>
                <td>:</td>
                <td><%= client_manager.name %> <%= client_manager.position %> 님</td>
              </tr>
              <tr>
                <th>T E L</th>
                <td>:</td>
                <td><%= client_manager.mobile %></td>
              </tr>
              <tr>
                <th>F A X</th>
                <td>:</td>
                <td><%= client_manager.fax %></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="lined-all">
              <colgroup>
                <col style="width:20px"/>
                <col/>
                <col/>
                <col/>
                <col/>
              </colgroup>
              <tr>
                <td rowspan="7" class="">공급자</td>
                <th>등 록 번 호</th>
                <td colspan="3"><%= _company.business_number %></td>
              </tr>
              <tr>
                <th>상 호</th>
                <td><%= _company.name %></td>
                <th>대 표 자</th>
                <td style="position:relative;">
                  <%= _company.ceo_name %>
                  <img src="<%= _host %>/img/stamp.png" style="position:absolute;top:-34px;right:-10px;height:100px;"/>
                </td>
              </tr>
              <tr>
                <th>주 소</th>
                <td colspan="3"><%= _company.address %></td>
              </tr>
              <tr>
                <th>업 태</th>
                <td><%= _company.business_status %></td>
                <th>업 종</th>
                <td><%= _company.business_sector %></td>
              </tr>
              <tr>
                <th>T E L</th>
                <td><%= quote_manager.emp_ext_phone %></td>
                <th>F A X</th>
                <td><%= _company.fax %></td>
              </tr>
              <tr>
                <th>담 당</th>
                <td><%= quote_manager.emp_name %> <%= quote_manager.emp_position %></td>
                <th>연 락 처</th>
                <td><%= quote_manager.emp_mobile %></td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td colspan="3"><%= quote_manager.emp_email %></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="lined-outer">
              <tr>
                <td>견 적 금 액 :</td>
                <td>일금<%= supply_price_humanize %>원정 (₩<%= supply_price %>-)</td>
                <td>[V.A.T. <%= vat_type %>]</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="align-center">
            아래와 같이 견적서를 제출합니다.
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>

        <tr>
          <td colspan="2">
            <table class="lined-all">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>자 재 코 드</th>
                  <th>품 명</th>
                  <th>단 위</th>
                  <th>수 량</th>
                  <th>단 가</th>
                  <th>금 액</th>
                  <th>비 고</th>
                </tr>
              </thead>
              <tbody>
                <% _.forEach(items, function(item, index) { %>
                <tr>
                  <td><%= index + 1 %></td>
                  <td><%= item.item_code %></td>
                  <td><%= item.item.item_name %></td>
                  <td><%= item.item.unit %></td>
                  <td class="align-right"><%= item.quote_quantity %></td>
                  <td class="align-right"><%= item.unit_price %></td>
                  <td class="align-right"><%= item.supply_price %></td>
                  <td><%= item.note %></td>
                </tr>
                <% }); %>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td colspan="2" class="align-center">------------- 이 하 여 백 -------------</td>
        </tr>

        <tr>
          <td colspan="2">
            <table class="lined-outer">
              <tr>
                <td>총 액</td>
                <td>₩<%= supply_price %></td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <table class="lined-all">
              <colgroup>
                <col style="width:20px"/>
                <col/>
              </colgroup>
              <td>MEMO</td>
              <td>
                <pre><%= etc %></pre>
              </td>
            </table>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">
            <table>
              <tr>
                <td>견적번호: <%= quote_number %></td>
                <td class="align-right">견적일자: <%= quote_date %></td>
              </tr>
            </table>
          </td>
        </tr>
      </tfoot>
    </table>`,
  order: `
    <table class="print-document">
      <colgroup>
        <col style="width:50%"/>
        <col style="width:50%"/>
      </colgroup>
      <thead>
        <tr>
          <th colspan="2">
            <div class="title">발 주 서</div>
            <img src="<%= _host %>/img/logo.gif" style="position:absolute;top:0;right:20px;height:60px;"/>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table>
              <tr>
                <td colspan="3" class="subtitle"><%= client_company %></td>
                <td>귀중</td>
              </tr>
              <tr>
                <th>참 조</th>
                <td>:</td>
                <td colspan="2"><%= client_manager.name %> <%= client_manager.position %> 님</td>
              </tr>
              <tr>
                <th>발 주 일 자</th>
                <td>:</td>
                <td colspan="2"><%= order_date %></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="lined-all">
              <colgroup>
                <col style="width:20px"/>
                <col/>
                <col/>
                <col/>
                <col/>
              </colgroup>
              <tr>
                <th>상 호</th>
                <td><%= _company.name %></td>
                <th>대 표 자</th>
                <td style="position:relative;">
                  <%= _company.ceo_name %>
                  <img src="<%= _host %>/img/stamp.png" style="position:absolute;top:-34px;right:-10px;height:100px;"/>
                </td>
              </tr>
              <tr>
                <th>주 소</th>
                <td colspan="3"><%= _company.address %></td>
              </tr>
              <tr>
                <th>T E L</th>
                <td><%= _company.phone %></td>
                <th>F A X</th>
                <td><%= _company.fax %></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>

        <tr>
          <td colspan="2" class="align-left">
            아래와 같이 발주합니다.
          </td>
        </tr>
        
        <tr>
          <td colspan="2">
            <table class="lined-all">
              <thead>
                <tr>
                  <th>번 호</th>
                  <th>품 명</th>
                  <th>규 격</th>
                  <th>단 위</th>
                  <th>수 량</th>
                  <th>단 가</th>
                  <th>금 액</th>
                </tr>
              </thead>
              <tbody>
                <% _.forEach(items, function(item, index) { %>
                <tr>
                  <td><%= index + 1 %></td>
                  <td><%= item.item.item_name %></td>
                  <td><%= item.item.standard %></td>
                  <td><%= item.item.unit %></td>
                  <td class="align-right"><%= item.order_quantity %></td>
                  <td class="align-right"><%= item.unit_price %></td>
                  <td class="align-right"><%= item.supply_price %></td>
                </tr>
                <% }); %>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6" class="align-center"><b>공 급 가&nbsp;&nbsp;합 계</b></td>
                  <td class="align-right"><%= supply_price %></td>
                </tr>
                <tr>
                  <td colspan="6" class="align-center"><b>부 가 세</b></td>
                  <td class="align-right"><%= tax_price %></td>
                </tr>
                <tr>
                  <td colspan="6" class="align-center"><b>합 계 금 액</b></td>
                  <td class="align-right"><%= total_price %></td>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <table>
              <tr>
                <th>수 령 인</th>
                <td>:</td>
                <td><%= _company.name %> <%= order_manager.department_name %> <%= order_manager.emp_name %> <%= order_manager.emp_position %> (<%= order_manager.emp_mobile %>)</td>
              </tr>
              <tr>
                <th>결 제 조 건</th>
                <td>:</td>
                <td><%= payment_terms %></td>
              </tr>
              <tr>
                <th>납 품 일 정</th>
                <td>:</td>
                <td><%= delivery_date %>까지</td>
              </tr>
              <tr>
                <th>입 고 장 소</th>
                <td>:</td>
                <td><%= delivery_place %></td>
              </tr>
              <tr>
                <th>제 품 보 증</th>
                <td>:</td>
                <td><%= note %></td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">
            <table>
              <tr>
                <td>발주번호: <%= order_number %></td>
                <td class="align-right">발주일자: <%= order_date %></td>
              </tr>
            </table>
          </td>
        </tr>
      </tfoot>
    </table>`,
  qr: `
    <style>
    body { margin: 0; padding: 0; }
    .print-document {
      box-sizing: border-box;
    }
    .section {
      display: flex;
      align-items: center;
      page-break-before:always;
    }
    
    .label {
      margin: 0cm;
      font-size: 0;
    }
    .label.left {
      margin-left: 3mm;
      margin-right: 8mm;
    }
    .label img { 
      width: 100%; height: 100%;
    }
    .p50x24 { width: 50mm; height: 24mm; }
    .s47x26 { width: 4.7cm; height: 2.69cm; }
    .s2x2 { width: 2cm; height: 2cm; }
    </style>
    <div class="print-document">
    <% _.chain(items).chunk(2).value().forEach(function(item, index) { %>
      <div class="section p50x24">
        <div class="label left s<%=label%>">
          <img src="<%= item[0].qr %>"/>
        </div>
        <% if (item[1]) { %>
        <div class="label s<%=label%>">
          <img src="<%= item[1].qr %>"/>
        </div>
        <% } %>
      </div>
    <% }); %>
    </div>
  `
}


export default async function printDocument (type, params) {
  try {
    const t = templates[type]
    if (!_company) {
      const {data: company} = await apiService.get(String(authService.getCompanyId()))
      company.business_number = businessNumberFormat(company.business_number)
      _company = company
    }
    const body = template(t)({...params, _company, _host})
    const w = window.open('', 'print-document', 'width=800,height=800')
    w.document.body.innerHTML = documentWrapper(body, _company.name)
  }
  catch (ex) {
    console.error(ex)
    return null
  }
}