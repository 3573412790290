<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">작지입고현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">작업지시일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-check-box
              text="미입고유무"
              v-model:value="vars.formData.notShipped"
            />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onInitialized(evt, 'status')"
          :data-source="produceWorkOrderItem1"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="work_order.number"
            caption="작지번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="work_order.target_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="work_order.manager" caption="작지담당자" />
          <dx-column data-field="work_order.note" caption="참고사항" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="required_quantity"
            caption="작지수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="item.main_category" caption="대분류" />
          <dx-column data-field="item.middle_category" caption="중분류" />
          <dx-column data-field="item.sub_category" caption="소분류" />
          <dx-column data-field="request_delivery_date" caption="요청납기" />
          <dx-column
            data-field="unproduced_quantity"
            caption="미입고수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.availableStock"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="methods.currentStock"
          />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column data-field="client_item_number" caption="고객사품번" />
          <dx-column data-field="item.item_detail" caption="품목설명" />
          <dx-column data-field="project_number" caption="프로젝트번호" />
          <dx-column data-field="end_yn" caption="종결" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />

          <dx-master-detail :enabled="true" template="masterDetailTemplate" />
          <template #masterDetailTemplate="{ data: data }">
            <work-order-receiving-detail :template-data="data" />
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSorting,
  DxFilterRow,
  DxColumnChooser,
  DxMasterDetail,
  DxExport
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { produceWorkOrderItem1 } from '../../data-source/produce';
import { reactive } from 'vue';
import stateStore from '@/utils/state-store';
import moment from 'moment';
import { alert } from 'devextreme/ui/dialog';
import WorkOrderReceivingDetail from './work-order-receiving-detail.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxColumnChooser,
    DxMasterDetail,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
    DxExport,
    WorkOrderReceivingDetail,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.grid = {};
    vars.formData = reactive({
      startDate: new Date(),
      endDate: new Date(),
      notShipped: false,
    });
    vars.formData.startDate.setDate(vars.formData.startDate.getDate() - 7);

    const methods = {
      onInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`produce-work-order-receiving-${key}`, evt.component);

        methods.initSorting();
        methods.searchDateRange();
      },
      initSorting() {
        const columns = vars.grid['status'].getVisibleColumns();
        const col = columns.filter(item => item.sortOrder);
        if (col.length == 0) {
          const defaultName = 'production_plan.target_date';
          const defaultSort = columns.filter(item => item.name == defaultName);
          if (defaultSort.length > 0) {
            vars.grid['status'].columnOption(
              defaultSort[0].index,
              'sortOrder',
              'desc'
            );
          }
        }
      },
      goDetail(e) {
        router.push({ path: `/produce/work-order/${e.data.work_order.id}` });
      },
      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        let filter = [
          [
            'work_order.target_date',
            '>=',
            moment(vars.formData.startDate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'work_order.target_date',
            '<=',
            moment(vars.formData.endDate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ];
        if (vars.formData.notShipped) {
          filter = [
            [
              'work_order.target_date',
              '>=',
              moment(vars.formData.startDate)
                .startOf('day')
                .format('YYYY-MM-DD 00:00:00'),
            ],
            'and',
            [
              'work_order.target_date',
              '<=',
              moment(vars.formData.endDate)
                .endOf('day')
                .format('YYYY-MM-DD 23:59:59'),
            ],
            'and',
            ['unproduced_quantity', '>', 0],
          ];
        }
        vars.grid['status'].filter(filter);
      },
      availableStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.available_stock;
      },
      currentStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.current_stock;
      },
      onExporting (evt) {
        produceWorkOrderItem1.exportData(evt.component, '작지입고현황', `작지입고현황_${Date.now()}.xlsx`)
        evt.cancel = true
      }
    };

    return { vars, methods, produceWorkOrderItem1 };
  },
};
</script>
