import notify from 'devextreme/ui/notify';

const notifyInfo = message => {
  notify({ message: message, width: 450 }, 'info', 2000);
};

const notifyError = message => {
  notify({ message: message, width: 450 }, 'error', 2000);
};

export { notifyInfo, notifyError };
