<template>
  <dx-data-grid
    :height="props.height"
    :data-source="produceWorkOrderItem1"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @initialized="onInitialized"
    @selection-changed="onSelectionChanged"
  >
    <dx-column
      data-field="work_order.number"
      caption="작업지시번호"
      :filter-operations="['contain', '=']"
    />
    <dx-column
      data-field="work_order.target_date"
      caption="일자"
      data-type="date"
      format="yyyy-MM-dd"
      sort-order="desc"
    />
    <dx-column data-field="client_company" caption="고객업체" />
    <dx-column data-field="order_number" caption="수주번호" />
    <dx-column data-field="order.order_date" caption="수주일자" />
    <dx-column data-field="work_order.manager" caption="담당자" />
    <dx-column data-field="item_code" caption="품목코드" />
    <dx-column data-field="item.item_name" caption="품명" />
    <dx-column data-field="item.item_standard" caption="규격" />
    <dx-column data-field="required_quantity" caption="작업지시수량" />
    <dx-column data-field="unit_price" caption="단가" format="currency" />
    <dx-column data-field="unit" caption="단위" />
    <dx-column data-field="supply_price" caption="공급가" format="currency" />
    <dx-column
      data-field="request_delivery_date"
      caption="요청납기"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="unproduced_quantity" caption="미입고수량" />
    <dx-column data-field="available_stock" caption="가용재고" />
    <dx-column data-field="current_stock" caption="현재고" />
    <dx-column data-field="item.main_category" caption="품목대분류" />
    <dx-column data-field="item.middle_category" caption="품목중분류" />
    <dx-column data-field="item.sub_category" caption="품목소분류" />
    <dx-column data-field="item.asset_type" caption="자산구분" />
    <dx-column data-field="item.safety_stock" caption="안전재고" />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { produceWorkOrderItem1 } from '../../data-source/produce';
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  height: {
    type: [String, Number]
  },
  filters: {
    type: Object
  }
});
const emit = defineEmits(['change']);
const mode = ref(props.multiple ? 'multiple' : 'single');
let component = undefined

function onInitialized (e) {
  component = e.component
  setFilter()
}

function setFilter () {
  if (!component) return
  if (!props.filters || !props.filters.length) {
    component.clearFilter()
    return
  }
  component.filter(props.filters)
}

function onSelectionChanged({ selectedRowsData }) {
  if (!props.multiple && selectedRowsData)
    selectedRowsData = selectedRowsData[0];
  emit('change', selectedRowsData);
}

watch(
  () => props.filters,
  () => setFilter()
);
</script>
