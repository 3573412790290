<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">프로젝트현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">계약일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status')"
          :data-source="projectRegistration"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @row-dbl-click="methods.goProjectDetail"
          @exporting="methods.onExporting"
        >
          <dx-export :enabled="true" />
          <dx-column
            data-field="project_number"
            caption="프로젝트번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column data-field="contract_name" caption="계약건명" />
          <dx-column data-field="order_company" caption="발주기관" />
          <dx-column
            data-field="contract_date"
            caption="계약일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column
            data-field="contract_begin_date"
            caption="계약시작"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="contract_end_date"
            caption="계약종료"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="contract_price"
            caption="계약금액"
            format="currency"
          />
          <dx-column data-field="register_department" caption="등록부서" />
          <dx-column data-field="register_manager" caption="등록담당자" />
          <dx-column
            data-field="register_date"
            caption="등록일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="sales_department" caption="영업부서" />
          <dx-column data-field="sales_manager" caption="영업담당" />
          <dx-column data-field="contract_type" caption="계약종류" />
          <dx-column data-field="contract_item" caption="계약품목" />
          <dx-column data-field="business_department" caption="사업부서" />
          <dx-column data-field="business_manager" caption="사업담당" />
          <dx-column data-field="site_address" caption="현장주소" />
          <dx-column data-field="note" caption="참고사항" />
          <dx-column
            data-field="allocation_amount"
            caption="배정금액"
            format="currency"
          />
          <dx-column
            data-field="use_price"
            caption="사용금액"
            format="currency"
          />
          <dx-column data-field="use_department" caption="사용부서" />
          <dx-column
            data-field="use_date"
            caption="사용일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="etc" caption="비고" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSorting,
  DxFilterRow,
  DxColumnChooser,
  DxExport
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { projectRegistration } from '../../data-source/project';
import { reactive } from 'vue';
import moment from 'moment';
import stateStore from '@/utils/state-store';
import { alert } from 'devextreme/ui/dialog';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxFilterRow,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
    DxExport
  },
  setup() {
    const router = useRouter();
    const vars = {};
    const storeDateFormat = 'YYYY-MM-DD HH:mm:ss'
    vars.grid = {};
    vars.formData = reactive({
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    });

    const storageKey = 'project/status/search'
    if (sessionStorage.getItem(storageKey)) {
      const stored = JSON.parse(sessionStorage.getItem(storageKey))
      vars.formData.startDate = moment(stored.startDate, storeDateFormat).toDate()
      vars.formData.endDate = moment(stored.endDate, storeDateFormat).toDate()
    }

    const methods = {
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(`project-status-${key}`, evt.component);

        methods.initSorting();
        methods.searchDateRange();
      },
      initSorting() {
        const columns = vars.grid['status'].getVisibleColumns();
        const col = columns.filter(item => item.sortOrder);
        if (col.length == 0) {
          const defaultName = 'contract_date';
          const defaultSort = columns.filter(item => item.name == defaultName);
          if (defaultSort.length > 0) {
            vars.grid['status'].columnOption(
              defaultSort[0].index,
              'sortOrder',
              'desc'
            );
          }
        }
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.quote_quantity && rowData.unit_price) {
          supply_price = rowData.quote_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        return supply_price;
      },
      availableStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.available_stock;
      },
      currentStock(rowData) {
        if (!rowData.basic_stock) return '0';
        return rowData.basic_stock.current_stock;
      },
      goProjectDetail({ data }) {
        router.push({ path: `/project/registration/${data.id}` });
      },
      async searchDateRange() {
        if (vars.formData.startDate > vars.formData.endDate) {
          await alert('조회 일자가 잘못 되었습니다', '조회');
          return;
        }
        vars.grid['status'].filter([
          [
            'contract_date',
            '>=',
            moment(vars.formData.startDate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'contract_date',
            '<=',
            moment(vars.formData.endDate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ]);

        sessionStorage.setItem(storageKey, JSON.stringify({
          startDate: moment(vars.formData.startDate).format(storeDateFormat),
          endDate: moment(vars.formData.endDate).format(storeDateFormat)
        }))
      },
      onExporting (evt) {
        projectRegistration.exportData(evt.component, '프로젝트현황', `프로젝트현황_${Date.now()}.xlsx`)
        evt.cancel = true
      }
    };

    return { vars, methods, projectRegistration };
  },
};
</script>
