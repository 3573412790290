<template>
  <dx-data-grid
    :data-source="purchasePreReceivingItem"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column
      data-field="prereceiving.prereceiving_number"
      caption="가입고번호"
      :filter-operations="['contain', '=']"
    />
    <dx-column
      data-field="prereceiving.prereceiving_date"
      caption="가입고일자"
      data-type="date"
      format="yyyy-MM-dd"
      sort-order="desc"
    />
    <dx-column data-field="prereceiving.client_company" caption="공급업체" />
    <dx-column
      data-field="prereceiving.receiving_department"
      caption="입고부서"
    />
    <dx-column
      data-field="prereceiving.receiving_manager"
      caption="입고담당자"
    />
    <dx-column data-field="prereceiving.receiving_type" caption="입고구분" />
    <dx-column data-field="item_code" caption="품목코드" />
    <dx-column data-field="item.item_name" caption="품명" />
    <dx-column data-field="item.item_standard" caption="규격" />
    <dx-column
      data-field="order_quantity"
      caption="발주수량"
      data-type="number"
      format="fixedPoint"
    />
    <dx-column
      data-field="prereceiving_quantity"
      caption="가입고수량"
      data-type="number"
      format="fixedPoint"
    />
    <dx-column data-field="unit_price" caption="단가" format="currency" />
    <dx-column data-field="item.unit" caption="단위" />
    <dx-column data-field="supply_price" caption="공급가" format="currency" />
    <dx-column
      data-field="request_delivery_date"
      caption="요청납기"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="item.main_category" caption="품목대분류" />
    <dx-column data-field="item.middle_category" caption="품목중분류" />
    <dx-column data-field="item.sub_category" caption="품목소분류" />
    <dx-column data-field="order_number" caption="발주번호" />
    <dx-column data-field="client_item_number" caption="공급사품번" />
    <dx-column data-field="item.item_detail" caption="품목설명" />
    <dx-column data-field="lot_number" caption="LOT번호" />
    <dx-column data-field="check_yn" caption="검사완료" />

    <dx-paging :page-size="20" />
    <dx-selection
      select-all-mode="page"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
  </dx-data-grid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { purchasePreReceivingItem } from '../../data-source/purchase';
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  fixedFilter: {
    type: Object,
  },
});
const emit = defineEmits(['change']);
const mode = ref(props.multiple ? 'multiple' : 'single');
if (props.fixedFilter) {
  !(() => (purchasePreReceivingItem.defaultFilters = props.fixedFilter))();
}

function onSelectionChanged({ selectedRowsData }) {
  if (!props.multiple && selectedRowsData)
    selectedRowsData = selectedRowsData[0];
  emit('change', selectedRowsData);
}
</script>
