const generateItemButtonOption = (
  icon,
  callback,
  location = 'after',
  options = {}
) => {
  let buttonOptions = { stylingMode: 'text', icon, onClick: callback };
  return {
    ...options,
    buttons: [{ name: icon, location, options: buttonOptions }],
  };
};

const sumSupplyPrice = (grid, quantityName, priceName) => {
  let total = 0;
  if (grid) {
    const rows = grid.getVisibleRows();
    if (rows.length > 0) {
      rows.forEach(elem => {
        total += elem.data[quantityName] * elem.data[priceName];
      });
    }
  }
  return total;
};

const calcPriceSummary = (vat_type, total) => {
  let supply_price = 0;
  let vat = 0;
  let total_price = 0;

  switch (vat_type) {
    case '별도': {
      supply_price = total;
      vat = Math.floor(total * 0.1);
      total_price = total + vat;
      break;
    }
    case '포함': {
      total_price = total;
      supply_price = Math.round((total * 10) / 11);
      vat = total_price - supply_price;
      break;
    }
    case '영세': {
      total_price = total;
      vat = 0;
      supply_price = total;
      break;
    }
    default: {
      total_price = total;
      vat = 0;
      supply_price = total;
    }
  }
  return { supply_price, vat, total_price };
};

const humanize = (n) => {
  const runit = ['','십','백','천']
  const aunit = ['','만','억','조']
  const nunit = {'1':'일','2':'이','3':'삼','4':'사','5':'오','6':'육','7':'칠','8':'팔','9':'구'}
  const a = String(n).split('')
  let cursor = 0, result = ''
  while (a.length) {
    const i = cursor % 4
    if (i === 0) result = aunit[cursor / 4] + result
    const r = nunit[a.pop()]
    if (r) result = r + runit[i] + result
    cursor++
  }
  return result
}

const businessNumberFormat = (n) => {
  try {
    const a = String(n).replace(/-/g, '').split('')
    a.splice(3, 0, '-')
    a.splice(6, 0, '-')
    return a.join('')
  }
  catch (ex) {
    console.error(ex)
    return n
  }
} 

export { generateItemButtonOption, sumSupplyPrice, calcPriceSummary, humanize, businessNumberFormat };
