<script setup>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import DxForm, {DxGroupItem, DxSimpleItem, DxLabel} from 'devextreme-vue/form'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import { DxNumberBox } from 'devextreme-vue/number-box'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import { 
  DxDataGrid, DxColumn, DxPaging, 
  DxFilterRow, DxColumnChooser, DxSelection, 
  DxSorting, DxToolbar as DxGridToolbar 
} from 'devextreme-vue/data-grid'

import authService from '@/auth'
import { purchaseReceivingItem } from '@/data-source/purchase'
import { performanceItem1 } from '@/data-source/produce'
import { shipmentReleaseItem } from '@/data-source/shipment'
import { baseClient } from '@/data-source/base'
import stateStore from '@/utils/state-store'
import { notifyError } from '@/utils/notify'
import printDocument from '@/utils/print-document'

import moment from 'moment'
import QRCode from 'qrcode'
import { reactive, ref, nextTick } from 'vue'

const companyKeyFilter = {name: 'fk_company_id', op: 'eq', val: authService.getCompanyId()}
purchaseReceivingItem.defaultFilters = [{name: 'receiving', op: 'has', val: companyKeyFilter}]
performanceItem1.defaultFilters = [{name: 'performance_registration', op: 'has', val: companyKeyFilter}]

const components = {}
const recvType = ref('생산입고')
const search = reactive({
  startdate: moment().startOf('month').toDate(), 
  enddate: moment().endOf('month').toDate(),
  type: '생산입고'
})

const printDialog = reactive({ show: false, data: [], paper: '20x20mm', label: '2x2' })

function initialized (evt, key) {
  components[key] = evt.component
  stateStore.bind(key, evt.component)
  if (key === 'qr-purchase') loadGrid()
}

function loadGrid () {
  recvType.value = search.type
  
  if (search.type === '생산입고') {
    components['qr-produce'].filter([
      ['performance_registration.target_date', '>=', moment(search.startdate).format('YYYY-MM-DD 00:00:00')],
      'and',
      ['performance_registration.target_date', '<=', moment(search.enddate).format('YYYY-MM-DD 23:59:59')],
    ]);

    nextTick(() => {
      components['qr-produce'].repaint()
    })
  }
  else if (search.type === '구매입고') {
    components['qr-purchase'].filter([
      ['receiving.receiving_date', '>=', moment(search.startdate).format('YYYY-MM-DD 00:00:00')],
      'and',
      ['receiving.receiving_date', '<=', moment(search.enddate).format('YYYY-MM-DD 23:59:59')],
    ]);

    nextTick(() => {
      components['qr-purchase'].repaint()
    })
  }
  else if (search.type === '출고') {
    components['qr-release'].filter([
      ['release.release_date', '>=', moment(search.startdate).format('YYYY-MM-DD 00:00:00')],
      'and',
      ['release.release_date', '<=', moment(search.enddate).format('YYYY-MM-DD 23:59:59')],
    ]);

    nextTick(() => {
      components['qr-release'].repaint()
    })
  }
}

function getQrImage (...codes) {
  const code = codes.join('|')
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(code, {margin: 0}, (err, url) => {
      if (err) return reject(err)
      resolve(url)
    })
  })
}

async function printSelectProduceItem () {
  const rows = components['qr-produce'].getSelectedRowsData()
  if (!rows.length) return notifyError('선택된 항목이 없습니다')
  printDialog.data = []

  // 생산입고 : 입고번호 + 품목코드 + 양품수량 
  for (const row of rows) {
    printDialog.data.push({
      number: row.performance_registration.number,
      item_code: row.item.item_code,
      name: row.item.item_name,
      quantity: row.production_receiving_quantity,
      qr: await getQrImage(
        row.order_number,
        row.performance_registration.number, 
        row.item.item_code,
        goodQuantity(row)) 
    })
  }
  printDialog.show = true
}

async function printSelectPurchaseItem () {
  const rows = components['qr-purchase'].getSelectedRowsData()
  if (!rows.length) return notifyError('선택된 항목이 없습니다')
  printDialog.data = []
  const loadedClient = {}

  // 구매입고 : 품목코드|업체약칭|입고번호 (변경전: 입고일자 + 공급업체 + 품명 + 규격)
  for (const row of rows) {
    if (!loadedClient[row.receiving.client_company]) {
      const {data: client} = await baseClient.load({filter: ['name', '=', row.receiving.client_company]})
      if (client.length) {
        loadedClient[row.receiving.client_company] = client[0].alias
      }
    }

    console.log([row.item.item_name,
        loadedClient[row.receiving.client_company],
        row.receiving.receiving_number].join('|'))
    
    printDialog.data.push({
      number: row.receiving.receiving_number,
      item_code: row.item.item_code,
      name: row.item.item_name,
      quantity: row.receiving_quantity,
      qr: await getQrImage(
        row.item.item_code,
        loadedClient[row.receiving.client_company],
        row.receiving.receiving_number
      ),
    })
  }
  printDialog.show = true
}

async function printSelectReleaseItem () {
  const rows = components['qr-release'].getSelectedRowsData()
  if (!rows.length) return notifyError('선택된 항목이 없습니다')
  printDialog.data = []

  // 출고 : 출고일자 + 고객업체 + 품명 + 규격 + 프로젝트번호
  for (const row of rows) {
    printDialog.data.push({
      number: row.release_number,
      item_code: row.item.item_code,
      name: row.item.item_name,
      quantity: row.release_quantity,
      qr: await getQrImage(
        moment(row.release.release_date).format('YYYYMMDD'),
        row.release.client_company,
        row.item.item_name,
        row.item.standard,
        row.project_number),
    })
  }
  printDialog.show = true
}

function printQRLabel () {
  const params = {paper: printDialog.paper, label: printDialog.label, items: []}
  for (const item of printDialog.data) {
    for (let i=0; i<item.quantity; i++) {
      params.items.push(item)
    }
  }
  printDocument('qr', params)
}

function closePrintPopup () {
  printDialog.show = false
}

function workOrderQuantity(rowData) {
  if (!rowData.work_order_quantity) return '0';
  return rowData.work_order_quantity;
}
function productionReceivingQuantity(rowData) {
  if (!rowData.production_receiving_quantity) return '0';
  return rowData.production_receiving_quantity;
}
function checkQuantity(rowData) {
  if (!rowData.check_quantity) return '0';
  return rowData.check_quantity;
}
function badQuantity(rowData) {
  if (!rowData.bad_quantity) return '0';
  return rowData.bad_quantity;
}
function goodQuantity(rowData) {
  if (!rowData.good_quantity) return '0';
  return rowData.good_quantity;
}
function availableStock(rowData) {
  if (!rowData.basic_stock) return '0';
  return rowData.basic_stock.available_stock;
}
function currentStock(rowData) {
  if (!rowData.basic_stock) return '0';
  return rowData.basic_stock.current_stock;
}
function calcSupplyPrice(rowData) {
  let supply_price = 0;
  if (rowData.release_quantity && rowData.unit_price) {
    supply_price = rowData.release_quantity * rowData.unit_price;
  }
  rowData.supply_price = supply_price;
  return supply_price;
}
</script>

<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">QR라벨관리</div>
            </dx-item>
          </dx-toolbar>
        </div>

        <div>
          <dx-form :form-data="search">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="입고일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item 
                data-field="type" 
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: ['생산입고', '구매입고', '출고']
                }">
                <dx-label text="업무구분" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: loadGrid,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1" v-show="recvType === '생산입고'">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :data-source="performanceItem1"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @initialized="evt => initialized(evt, 'qr-produce')"
        >
          <dx-grid-toolbar>
            <dx-item location="before" text="생산입고품목" />
            <dx-item location="after" 
              widget="dxButton" 
              :options="{
                icon: 'print',
                text: '선택된 항목 출력',
                type: 'normal',
                onClick: printSelectProduceItem
              }" 
            />
            <dx-item name="columnChooserButton" />
          </dx-grid-toolbar>
          <dx-column
            data-field="performance_registration.number"
            caption="생산입고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="performance_registration.target_date"
            caption="입고일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column data-field="work_order_number" caption="작지번호" />
          <dx-column
            data-field="work_order.target_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="performance_registration.manager"
            caption="담당자"
          />
          <dx-column data-field="item.item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="work_order_quantity"
            caption="작업지시수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="workOrderQuantity"
          />
          <dx-column
            data-field="production_receiving_quantity"
            caption="생산입고수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="productionReceivingQuantity"
          />
          <dx-column
            data-field="check_quantity"
            caption="검수수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="checkQuantity"
          />
          <dx-column
            data-field="bad_quantity"
            caption="불량수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="badQuantity"
          />
          <dx-column
            data-field="good_quantity"
            caption="양품수량"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="goodQuantity"
          />
          <dx-column
            data-field="check_yn"
            caption="검수완료"
            data-type="boolean"
          />
          <dx-column data-field="warehouse.wh_name" caption="입고창고" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="availableStock"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
            :calculate-display-value="currentStock"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column data-field="item.safety_stock" caption="안전재고" />
          <dx-column data-field="project_number" caption="프로젝트번호" />

          <dx-paging :page-size="20" />
          <dx-sorting mode="single" />
          <dx-filter-row :visible="true" />
          <dx-selection select-all-mode="page" show-check-boxes-mode="always" mode="multiple" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
      <div class="dx-card responsive-paddings mt-1" v-show="recvType === '구매입고'">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :data-source="purchaseReceivingItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @initialized="evt => initialized(evt, 'qr-purchase')">

          <dx-grid-toolbar>
            <dx-item location="before" text="구매입고품목" />
            <dx-item location="after" 
              widget="dxButton" 
              :options="{
                icon: 'print',
                text: '선택된 항목 출력',
                type: 'normal',
                onClick: printSelectPurchaseItem
              }" 
            />
            <dx-item name="columnChooserButton" />
          </dx-grid-toolbar>
          
          <dx-column
            data-field="receiving.receiving_number"
            caption="입고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="receiving.receiving_date"
            caption="입고일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="receiving.client_company" caption="공급업체" />
          <dx-column
            data-field="receiving.receiving_department"
            caption="입고부서"
          />
          <dx-column
            data-field="receiving.receiving_manager"
            caption="입고담당자"
          />
          <dx-column data-field="receiving.receiving_type" caption="입고구분" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="order_quantity"
            caption="발주수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="receiving_quantity"
            caption="입고수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="order_number" caption="발주번호" />
          <dx-column data-field="client_item_number" caption="공급사품번" />
          <dx-column data-field="item.item_detail" caption="품목설명" />
          <dx-column data-field="note" caption="참고사항" />
          <dx-column data-field="closing_yn" caption="종결" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-selection select-all-mode="page" show-check-boxes-mode="always" mode="multiple" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
      <div class="dx-card responsive-paddings mt-1" v-show="recvType === '출고'">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :data-source="shipmentReleaseItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @initialized="evt => initialized(evt, 'qr-release')">

          <dx-grid-toolbar>
            <dx-item location="before" text="출고품목" />
            <dx-item location="after" 
              widget="dxButton" 
              :options="{
                icon: 'print',
                text: '선택된 항목 출력',
                type: 'normal',
                onClick: printSelectReleaseItem
              }" 
            />
            <dx-item name="columnChooserButton" />
          </dx-grid-toolbar>
          
          <dx-column
            data-field="release.release_number"
            caption="출고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="release.release_date"
            caption="출고일자"
            data-type="date"
            format="yyyy-MM-dd"
            :show-in-column-chooser="false"
          />
          <dx-column data-field="release.client_company" caption="고객업체" />
          <dx-column
            data-field="release.release_department"
            caption="견적부서"
          />
          <dx-column
            data-field="release.release_manager"
            caption="출고담당자"
          />
          <dx-column data-field="release.release_type" caption="출고구분" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="order_item.order_quantity"
            caption="수주수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="release_quantity"
            caption="출고수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
            :calculate-cell-value="calcSupplyPrice"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="order_number" caption="수주번호" />
          <dx-column data-field="client_item_number" caption="고객사품번" />
          <dx-column data-field="item.item_detail" caption="품목설명" />
          <dx-column data-field="note" caption="참고사항" />
          <dx-column data-field="project_number" caption="프로젝트번호" />
          <dx-column data-field="closing_yn" caption="종결" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-selection select-all-mode="page" show-check-boxes-mode="always" mode="multiple" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>

    <dx-popup
      v-model:visible="printDialog.show"
      :show-title="false"
      content-template="popup-content"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => initialized(evt, 'qr-popup')">

      <dx-toolbar-item location="after" widget="dxButton" :options="{icon: 'close', text: '닫기', onClick: closePrintPopup}" />
      <dx-toolbar-item location="after" widget="dxButton" :options="{icon: 'print', text: '확인', onClick: printQRLabel}" />

      <template #popup-content>
        <dx-form>
          <dx-group-item>

          </dx-group-item>
        </dx-form>
        <dx-scroll-view width="100%" height="100%">
          <div class="qr-list">
            <div class="qr-list-item" v-for="row in printDialog.data" :key="row.number">
              <div class="qr-list-img"><img :src="row.qr" /></div>
              <div class="qr-list-name">{{row.name}}</div>
              <div class="qr-list-label">인쇄수량</div>
              <div class="qr-list-quantity">
                <dx-number-box v-model:value="row.quantity" :min="1" />
              </div>
            </div>
          </div>
        </dx-scroll-view>
      </template>
    </dx-popup>

  </div>
</template>

<style lang="scss" scoped>
.qr-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .qr-popup-title {
    font-size: 18px;
  }
  .qr-popup-fn .dx-button:not(:last-child) {
    margin: 0 10px;
  }
}
.qr-list {
  margin: -4px 0;
  .qr-list-item {
    display: flex;
    align-items: center;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    overflow: hidden;
    margin: 4px 0;
  }
  .qr-list-img {
    flex: 0 0 80px;
    font-size: 0;
    img { width: 100%; }
  }
  .qr-list-name {
    flex: 1 1 auto;
    padding: 0 20px;
    font-size: 16px;
  }
  .qr-list-label {
    flex: 0 0 52px;
  }
  .qr-list-quantity {
    flex: 0 0 100px;
    padding: 0 10px;
  }
}
</style>