import defaultLayout from '@/layouts/side-nav-outer-toolbar';

import orderPlan from '@/views/purchase/order-plan';
import order from '@/views/purchase/order';
import preReceiving from '@/views/purchase/pre-receiving';
import receiving from '@/views/purchase/receiving';
import orderPlanStatus from '@/views/purchase/order-plan-status';
import orderStatus from '@/views/purchase/order-status';
import preReceivingStatus from '@/views/purchase/pre-receiving-status';
import receivingStatus from '@/views/purchase/receiving-status';
import orderReceivingStatus from '@/views/purchase/order-receiving-status';

export default [
  {
    path: '/purchase/order-plan',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: orderPlan,
  },
  {
    path: '/purchase/order-plan/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: orderPlan,
    props: true,
  },
  {
    path: '/purchase/order',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: order,
  },
  {
    path: '/purchase/order/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: order,
    props: true,
  },
  {
    path: '/purchase/pre-receiving',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: preReceiving,
  },
  {
    path: '/purchase/pre-receiving/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: preReceiving,
    props: true,
  },
  {
    path: '/purchase/receiving',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: receiving,
  },
  {
    path: '/purchase/receiving/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: receiving,
    props: true,
  },
  {
    path: '/purchase/order-plan-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: orderPlanStatus,
  },
  {
    path: '/purchase/order-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: orderStatus,
  },
  {
    path: '/purchase/pre-receiving-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: preReceivingStatus,
  },
  {
    path: '/purchase/receiving-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: receivingStatus,
  },
  {
    path: '/purchase/order-receiving-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: orderReceivingStatus,
  },
];
