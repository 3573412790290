<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">부서/사원</div>
            </dx-item>
          </dx-toolbar>
        </div>

        <dx-tab-panel
          :animation-enabled="false"
          :swipe-enabled="false"
          @selection-changed="methods.tabChanged"
        >
          <dx-item title="부서">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 210px)"
                  :data-source="baseDepartment"
                  :remote-operations="true"
                  :show-borders="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :focused-row-enabled="true"
                  @initialized="evt => methods.initialized(evt, 'group')"
                  @init-new-row="methods.groupRowInit"
                >
                  <!--dx-column data-field="id" caption="번호" width="80" :allow-editing="false" sort-order="desc" /-->
                  <dx-column
                    data-field="department_name"
                    caption="부서명"
                    :allow-editing="true"
                    :allow-hiding="false"
                  />
                  <dx-column
                    data-field="depart_head_name"
                    caption="부서장"
                    width="140"
                    :allow-editing="true"
                  >
                    <dx-lookup
                      :data-source="methods.employeeLookup"
                      display-expr="emp_name"
                      value-expr="emp_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="wh_code"
                    caption="지정창고"
                    width="140"
                    :allow-editing="true"
                  >
                    <dx-lookup
                      :data-source="vars.common.warehouse"
                      display-expr="wh_name"
                      value-expr="wh_code"
                    />
                  </dx-column>
                  <dx-column
                    data-field="created"
                    caption="생성일"
                    width="140"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="false"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="row"
                    :use-icons="true"
                  />
                  <dx-paging :page-size="20" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="사원">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 210px)"
                  :data-source="baseEmployee"
                  :remote-operations="true"
                  :show-borders="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :focused-row-enabled="true"
                  @initialized="evt => methods.initialized(evt, 'employee')"
                  @toolbar-preparing="methods.onGrid1ToolbarPreparing"
                  @init-new-row="methods.initInsertedRow"
                  @editing-start="methods.onEditStart"
                  @row-inserting="methods.injectImage"
                  @row-updating="methods.injectImage"
                  @saved="methods.onSaved"
                >
                  <dx-grid-toolbar>
                    <dx-item name="addRowButton" />
                    <dx-item location="after" template="excelUploadButton" />
                    <dx-item
                      location="after"
                      template="excelSampleDownloadButton"
                    />
                  </dx-grid-toolbar>
                  <template #excelUploadButton>
                    <dx-button icon="upload" @click="methods.excelUpload" />
                  </template>
                  <template #excelSampleDownloadButton>
                    <dx-button
                      icon="download"
                      @click="methods.excelSampleDownload"
                    />
                  </template>

                  <!--dx-column data-field="id" caption="번호" :allow-editing="false" sort-order="desc" /-->
                  <dx-column
                    data-field="created"
                    caption="생성일"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="emp_code"
                    caption="사원코드"
                    :allow-editing="true"
                  >
                    <dx-required-rule message="사원코드를 입력하세요" />
                    <dx-async-rule
                      message="이미 사용중인 사원코드입니다"
                      :validation-callback="methods.duplicationCheck"
                    />
                  </dx-column>
                  <dx-column
                    data-field="fk_department_id"
                    caption="부서코드"
                    :allow-editing="true"
                  >
                    <dx-lookup
                      :data-source="vars.common.department"
                      display-expr="department_name"
                      value-expr="id"
                    />
                  </dx-column>
                  <dx-column
                    data-field="emp_password"
                    caption="접속 비밀번호"
                    :allow-editing="true"
                    :visible="false"
                    :editor-options="{ mode: 'password' }"
                  />
                  <dx-column
                    data-field="emp_name"
                    caption="사원이름"
                    :allow-editing="true"
                    :allow-hiding="false"
                  />
                  <dx-column
                    data-field="emp_addr"
                    caption="주소"
                    :allow-editing="true"
                    :visible="false"
                    :editor-options="vars.findAddress.columnOptions"
                  />
                  <dx-column
                    data-field="emp_addr_detail"
                    caption="상세주소"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_addr_zipcode"
                    caption="우편번호"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_direct_phone"
                    caption="직통번호"
                    :allow-editing="true"
                  />
                  <dx-column
                    data-field="emp_ext_phone"
                    caption="전화번호"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_mobile"
                    caption="핸드폰"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_position"
                    caption="직위코드"
                    :allow-editing="true"
                    :visible="false"
                  >
                    <dx-lookup
                      :data-source="vars.common.position_code"
                      display-expr="code_name"
                      value-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="emp_email"
                    caption="이메일"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_joindate"
                    caption="입사일"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="true"
                  />
                  <dx-column
                    data-field="memo"
                    caption="메모"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_name_en"
                    caption="영문이름"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_gender"
                    caption="성별"
                    :allow-editing="true"
                    :visible="false"
                  >
                    <dx-lookup
                      :data-source="vars.common.gender"
                      display-expr="code_name"
                      value-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="emp_country"
                    caption="국가코드"
                    :allow-editing="true"
                    :visible="false"
                  >
                    <dx-lookup
                      :data-source="vars.common.country_code"
                      display-expr="code_name"
                      value-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="resignation_yn"
                    caption="퇴사여부"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="resignation_date"
                    caption="퇴사일"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="true"
                    :visible="false"
                  />
                  <dx-column
                    data-field="resignation_type"
                    caption="퇴사코드"
                    :allow-editing="true"
                    :visible="false"
                  >
                    <dx-lookup
                      :data-source="vars.common.resignation_code"
                      display-expr="code_name"
                      value-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="fk_setup_group_auth"
                    caption="그룹코드"
                    :allow-editing="true"
                    :visible="false"
                  >
                    <dx-lookup
                      :data-source="vars.common.group"
                      display-expr="group_name"
                      value-expr="id"
                    />
                  </dx-column>

                  <dx-column
                    data-field="emp_picture_path"
                    caption="얼굴사진"
                    :allow-editing="false"
                    :visible="false"
                  />
                  <dx-column
                    data-field="emp_sign_path"
                    caption="서명"
                    :allow-editing="false"
                    :visible="false"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="popup"
                    :use-icons="true"
                  >
                    <dx-grid-popup
                      :show-title="true"
                      :width="840"
                      :height="700"
                      title="사원 정보"
                    >
                    </dx-grid-popup>
                    <dx-form
                      :col-count="1"
                      :show-colon-after-label="false"
                      @initialized="
                        evt => methods.initialized(evt, 'edit-form')
                      "
                    >
                      <dx-simple-item>
                        <template #default>
                          <div class="flex-end">
                            <div class="thumb">
                              <div class="thumb-label">사원사진</div>
                              <label for="thumb-file" class="thumb-photo">
                                <img
                                  v-if="vars.image.thumb"
                                  :src="vars.image.thumb"
                                />
                                <div style="text-align: center" v-else>
                                  클릭하여<br />이미지 선택
                                </div>
                              </label>
                            </div>
                            <div class="thumb">
                              <div class="thumb-label">사원사인</div>
                              <label for="sign-file" class="thumb-photo">
                                <img
                                  v-if="vars.image.sign"
                                  :src="vars.image.sign"
                                />
                                <div style="text-align: center" v-else>
                                  클릭하여<br />이미지 선택
                                </div>
                              </label>
                            </div>
                          </div>
                        </template>
                      </dx-simple-item>
                      <dx-group-item :col-count="3">
                        <dx-simple-item data-field="emp_code" />
                        <dx-simple-item data-field="emp_password" />
                        <dx-simple-item data-field="emp_name_en" />

                        <dx-simple-item data-field="emp_name" :col-span="2" />
                        <dx-simple-item data-field="created" />

                        <dx-simple-item data-field="emp_addr" :col-span="2" />
                        <dx-simple-item data-field="emp_gender" />

                        <dx-simple-item data-field="emp_addr_detail" />
                        <dx-simple-item data-field="emp_addr_zipcode" />
                        <dx-simple-item data-field="emp_country" />

                        <dx-simple-item data-field="emp_ext_phone" />
                        <dx-simple-item data-field="emp_direct_phone" />
                        <dx-simple-item data-field="resignation_yn" />

                        <dx-simple-item data-field="emp_mobile" />
                        <dx-simple-item data-field="emp_position" />
                        <dx-simple-item data-field="resignation_date" />

                        <dx-simple-item data-field="emp_email" :col-span="2" />
                        <dx-simple-item data-field="resignation_type" />

                        <dx-simple-item
                          data-field="emp_joindate"
                          :col-span="2"
                        />
                        <dx-simple-item data-field="fk_setup_group_auth" />

                        <dx-simple-item data-field="memo" :col-span="2" />
                        <dx-simple-item data-field="fk_department_id" />
                      </dx-group-item>
                    </dx-form>
                  </dx-editing>
                  <dx-column-chooser mode="select" :enabled="true" />
                  <dx-filter-row :visible="true" />
                  <dx-paging :page-size="20" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>

        <dx-popup
          v-model:visible="vars.findAddress.popup.value"
          content-template="popup-content"
          title="주소찾기"
          :close-on-outside-click="true"
          :width="680"
          :height="500"
          :resize-enabled="true"
        >
          <template #popup-content>
            <div>
              <div style="margin-bottom: 10px">
                <dx-text-box
                  v-model="vars.findAddress.keyword"
                  :buttons="vars.findAddress.textBoxOptions"
                  @enter-key="methods.findAddressSubmit"
                />
              </div>
              <dx-data-grid
                :height="340"
                :data-source="vars.findAddress.store"
                :show-borders="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :remote-operations="true"
                @initialized="evt => methods.initialized(evt, 'find-address')"
                @row-click="methods.findAddressSelect"
              >
                <dx-column data-field="road" caption="도로명주소" />
                <dx-column data-field="jibun" caption="지번주소" />
                <dx-column data-field="zip" caption="우편번호" />
                <dx-paging :page-size="20" />
              </dx-data-grid>
            </div>
          </template>
        </dx-popup>
      </div>
    </div>
    <input
      hidden
      type="file"
      id="thumb-file"
      accept=".png,.jpg"
      @change="methods.thumbFileChange"
    />
    <input
      hidden
      type="file"
      id="sign-file"
      accept=".png,.jpg"
      @change="methods.signFileChange"
    />
    <input
      hidden
      type="file"
      ref="excelRef"
      accept=".xlsx,.xls"
      @change="methods.excelFileChange"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxLookup,
  DxPopup as DxGridPopup,
  DxForm,
  DxFilterRow,
  DxColumnChooser,
  DxRequiredRule,
  DxAsyncRule,
  DxToolbar as DxGridToolbar,
} from 'devextreme-vue/data-grid';
import { DxGroupItem, DxSimpleItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import DxTextBox from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import {
  baseEmployee,
  baseDepartment,
  baseWarehouse,
  baseCodeLoader,
} from '../../data-source/base';
import FindAddressStore from '../../data-source/find-address';
import { setupGroup } from '../../data-source/setup';
import ApiService from '../../utils/api-service';
import { notifyError } from '../../utils/notify';
import { reactive, ref } from 'vue';
import moment from 'moment';
import { saveAs } from 'file-saver';
import authService from '../../auth';

export default {
  components: {
    DxToolbar,
    DxTextBox,
    DxItem,
    DxTabPanel,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxPopup,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxColumnChooser,
    DxLookup,
    DxRequiredRule,
    DxAsyncRule,
    DxFilterRow,
    DxGridPopup,
    DxGridToolbar,
  },
  setup() {
    const vars = {},
      methods = {};

    const excelRef = ref(null);
    const apiService = new ApiService('/api/mes/v1/excel/base/employee');

    // Vars
    vars.components = {};
    vars.image = reactive({ thumb: null, sign: null });
    vars.editKey = null;
    vars.common = reactive({
      gender: [],
      country_code: [],
      resignation_code: [],
      position_code: [],
      warehouse: [],
      department: [],
      group: [],
      employee: []
    });
    baseEmployee
      .load({
        filter: [['fk_company_id', '=', authService.getCompanyId()]],
        take: 1000,
        skip: 0,
      })
      .then(({ data }) => {
        vars.common.employee = data;
        if (vars.components['group']) vars.components['group'].refresh()
      });

    baseCodeLoader(['성별', '국적', '퇴사사유', '직급']).then(response => {
      vars.common.gender = response['성별'];
      vars.common.country_code = response['국적'];
      vars.common.resignation_code = response['퇴사사유'];
      vars.common.position_code = response['직급'];
    });

    vars.findAddress = {
      popup: ref(false),
      store: new FindAddressStore(),
      keyword: null,
      columnOptions: {
        buttons: [
          {
            name: 'icon',
            location: 'after',
            options: {
              stylingMode: 'text',
              icon: 'search',
              onClick: () => {
                const rowIndex = vars.components['employee'].getRowIndexByKey(
                  vars.editKey
                );
                vars.findAddress.keyword = vars.components[
                  'employee'
                ].cellValue(rowIndex, 'emp_addr');
                vars.findAddress.popup.value = true;
              },
            },
          },
        ],
      },
      textBoxOptions: [
        {
          name: 'icon',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'search',
            onClick: () => {
              methods.findAddressSubmit();
            },
          },
        },
      ],
    };

    baseWarehouse.load().then(response => {
      vars.common.warehouse = response.data;
    });

    setupGroup.load().then(response => {
      vars.common.group = response.data;
    });

    // 그룹코드
    // vars.common.group_auth_code = []

    // Methods
    methods.groupRowInit = evt => {
      evt.data.created = moment().format('YYYY-MM-DD HH:mm:ss');
      evt.data.fk_company_id = authService.getCompanyId();
    };

    methods.initInsertedRow = evt => {
      evt.component.columnOption('emp_code', 'allowEditing', true);
      vars.data = evt.data;
      const today = moment().format('YYYY-MM-DD HH:mm:ss');
      evt.data.created = today;
      evt.data.emp_joindate = today;
      evt.data.resignation_yn = false;
      evt.data.fk_company_id = authService.getCompanyId();
    };

    methods.employeeLookup = options => ({
      store: vars.common.employee,
      filter: options.data ? ['fk_department_id', '=', options.data.id] : null,
    });

    methods.initialized = (evt, key) => {
      vars.components[key] = evt.component;
    };

    methods.onEditStart = evt => {
      evt.component.columnOption('emp_code', 'allowEditing', false);
      vars.image.thumb = evt.data.emp_picture_path;
      vars.image.sign = evt.data.emp_sign_path;
      vars.editKey = evt.key;
    };

    methods.injectImage = evt => {
      const sendData = evt.data || evt.newData;
      console.log(sendData);
      // sendData.emp_picture_path = vars.image.thumb
      // sendData.emp_sign_path = vars.image.sign
    };

    methods.onSaved = () => {
      vars.image.thumb = null;
      vars.image.sign = null;
    };

    methods.thumbFileChange = evt => {
      if (!evt.target.files.length) {
        vars.image.thumb = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(evt.target.files[0]);
      reader.onload = () => {
        vars.image.thumb = reader.result;
        const rowIndex = vars.components['employee'].getRowIndexByKey(
          vars.editKey
        );
        vars.components['employee'].cellValue(
          rowIndex,
          'emp_picture_path',
          reader.result
        );
      };
      evt.target.value = null;
    };

    methods.signFileChange = evt => {
      if (!evt.target.files.length) {
        vars.image.sign = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(evt.target.files[0]);
      reader.onload = () => {
        vars.image.sign = reader.result;
        const rowIndex = vars.components['employee'].getRowIndexByKey(
          vars.editKey
        );
        vars.components['employee'].cellValue(
          rowIndex,
          'emp_sign_path',
          reader.result
        );
      };
      evt.target.value = null;
    };

    methods.tabChanged = evt => {
      baseDepartment.load().then(response => {
        vars.common.department = response.data;
      });
    };

    methods.duplicationCheck = async ({ data, value }) => {
      if (data.id) return true;

      try {
        const response = await baseEmployee.load({
          filter: ['emp_code', '=', value],
        });
        return !response.data.length;
      } catch (ex) {
        return false;
      }
    };

    methods.findAddressSubmit = () => {
      vars.findAddress.store.keyword = vars.findAddress.keyword;
      vars.components['find-address'].refresh();
    };

    methods.findAddressSelect = ({ data }) => {
      const rowIndex = vars.components['employee'].getRowIndexByKey(
        vars.editKey
      );
      vars.components['employee'].beginUpdate();
      vars.components['employee'].cellValue(rowIndex, 'emp_addr', data.road);
      vars.components['employee'].cellValue(
        rowIndex,
        'emp_addr_zipcode',
        data.zip
      );
      vars.components['employee'].cellValue(rowIndex, 'emp_addr_detail', '');
      vars.components['employee'].endUpdate();
      vars.findAddress.popup.value = false;
    };

    methods.excelUpload = () => {
      excelRef.value.click();
    };

    methods.excelFileChange = async ({ target }) => {
      if (!target.files.length) return;

      vars.components['employee'].beginCustomLoading(
        '엑셀 데이터를 읽고 있습니다'
      );
      const fd = new FormData();
      fd.append('file', target.files[0]);

      try {
        await apiService.post('', fd);
        vars.components['employee'].refresh();
      } catch (ex) {
        if (ex.response.data) {
          notifyError(ex.response.data);
        }
      } finally {
        vars.components['employee'].endCustomLoading();
        target.value = null;
      }
    };

    methods.excelSampleDownload = () => {
      saveAs('/api/mes/v1/excel/base/employee', '사원입력양식.xlsx');
    };

    return {
      vars,
      methods,
      excelRef,
      setupGroup,
      baseEmployee,
      baseDepartment,
    };
  },
};
</script>

<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.thumb {
  display: flex;
  align-items: flex-start;
  .thumb-label {
    padding: 5px 10px;
    margin: 0 10px;
    border: 1px solid #ddd;
  }
  .thumb-photo {
    width: 140px;
    height: 100px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: 100%;
    }
  }
}
</style>
