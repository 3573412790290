<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">프로젝트등록</div></dx-item
            >
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'add',
                icon: 'add',
                onClick: methods.newItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'rename',
                icon: 'rename',
                disabled: vars.disabled.edit,
                onClick: methods.editItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'remove',
                icon: 'remove',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'save',
                icon: 'save',
                disabled: vars.disabled.save,
                onClick: methods.saveItem,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="project_number"
                :editor-options="{
                  placeholder: '(자동 or 직접입력)',
                  ...methods.generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('project', '프로젝트조회')
                  ),
                }"
              >
                <dx-label text="프로젝트번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="contract_name"
                :editor-options="{
                  onValueChanged: methods.onContractChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="계약건명" :show-colon="false" />
                <dx-required-rule message="계약건명을 입력하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="order_company"
                :editor-options="{
                  ...methods.generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('client', '고객조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="발주기관" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="step"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.step.item,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  ...vars.formState,
                }"
              >
                <dx-label text="진행단계" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="contract_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="계약일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="contract_begin_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="계약시작" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="contract_end_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="계약종료" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="contract_price"
                editor-type="dxNumberBox"
                :editor-options="{
                  format: 'currency',
                  ...vars.formState,
                }"
              >
                <dx-label text="계약금액" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="register_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.department.item,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  onValueChanged: methods.selectRegDepartment,
                  ...vars.formState,
                }"
              >
                <dx-label text="등록부서" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="register_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.employee.regItem,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="등록담당자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="register_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="등록일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...methods.generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '174px',
                  labelMode: 'hidden',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings back-colored mt-1">
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-item location="before"
                ><div class="content-title">영업정보</div></dx-item
              >
              <dx-simple-item
                data-field="sales_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.department.item,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  onValueChanged: methods.selectSalesDepartment,
                  ...vars.formState,
                }"
              >
                <dx-label text="영업부서" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="sales_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.employee.salesItem,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="영업담당" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="contract_type"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.common.contract_type,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="계약종류" :show-colon="false" />
              </dx-simple-item>
              <dx-empty-item />
            </dx-group-item>
            <dx-group-item>
              <dx-item location="before"
                ><div class="content-title">사업정보</div></dx-item
              >
              <dx-simple-item
                data-field="business_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.department.item,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  onValueChanged: methods.selectBusinessDepartment,
                  ...vars.formState,
                }"
              >
                <dx-label text="사업부서" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="business_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.employee.businessItem,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="사업담당" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="site_address"
                :allow-editing="true"
                :editor-options="{
                  ...vars.findAddress.columnOptions,
                  ...vars.formState,
                }"
              >
                <dx-label text="현장주소" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{
                  ...vars.formState,
                }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-item location="before"
                ><div class="content-title">안전관리비</div></dx-item
              >
              <dx-simple-item
                data-field="allocation_amount"
                editor-type="dxNumberBox"
                :editor-options="{
                  format: 'currency',
                  ...vars.formState,
                }"
              >
                <dx-label text="배정금액" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="use_price"
                editor-type="dxNumberBox"
                :editor-options="{
                  format: 'currency',
                  ...vars.formState,
                }"
              >
                <dx-label text="사용금액" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="use_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.department.item,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="사용부서" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="use_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="사용일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel :animation-enabled="false" :swipe-enabled="false">
          <dx-item title="계약품목">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 600px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'items')
                  "
                  :remote-operations="true"
                  :data-source="vars.items.item"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  @saving="methods.onSavingItem"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item
                      name="addRowButton"
                      :options="{ onClick: methods.showAddPopup }"
                    />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                  </dx-grid-toolbar>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="quantity"
                    caption="수량"
                    data-type="number"
                    format="fixedPoint"
                    :set-cell-value="methods.setQuantity"
                  />
                  <dx-column
                    data-field="not_ordered"
                    caption="미수주수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="fk_project_management_id"
                    caption="프로젝트아이디"
                    :visible="false"
                  />

                  <dx-editing
                    :allow-adding="!vars.formState.readOnly"
                    :allow-updating="!vars.formState.readOnly"
                    :allow-deleting="!vars.formState.readOnly"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="보증현황">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 600px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'guarantee')
                  "
                  :remote-operations="true"
                  :data-source="vars.guarantee.item"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  @saving="methods.onSavingItem"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item name="addRowButton" />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                  </dx-grid-toolbar>

                  <dx-column data-field="guarantee_type" caption="보증종류">
                    <dx-lookup
                      :data-source="vars.common.guarantee_type"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column data-field="guarantee_number" caption="증권번호" />
                  <dx-column data-field="publish_status" caption="발행상태">
                    <dx-lookup
                      :data-source="vars.common.publish_status"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="publish_date"
                    caption="발행일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="fk_project_management_id"
                    caption="프로젝트아이디"
                    :visible="false"
                  />
                  <dx-editing
                    :allow-adding="!vars.formState.readOnly"
                    :allow-updating="!vars.formState.readOnly"
                    :allow-deleting="!vars.formState.readOnly"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="보험현황">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 600px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'insurance')
                  "
                  :remote-operations="true"
                  :data-source="vars.insurance.item"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  @saving="methods.onSavingItem"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item name="addRowButton" />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                  </dx-grid-toolbar>

                  <dx-column data-field="insurance_type" caption="보험종류">
                    <dx-lookup
                      :data-source="vars.common.insurance_type"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column data-field="request_start" caption="개시요청">
                    <dx-lookup
                      :data-source="vars.common.request_start"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column data-field="start_status" caption="개시상태">
                    <dx-lookup
                      :data-source="vars.common.start_status"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="start_date"
                    caption="개시일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="fk_project_management_id"
                    caption="프로젝트아이디"
                    :visible="false"
                  />
                  <dx-editing
                    :allow-adding="!vars.formState.readOnly"
                    :allow-updating="!vars.formState.readOnly"
                    :allow-deleting="!vars.formState.readOnly"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="수금현황">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 600px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'collect')
                  "
                  :remote-operations="true"
                  :data-source="vars.collect.item"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  @saving="methods.onSavingItem"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item name="addRowButton" />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                  </dx-grid-toolbar>

                  <dx-column data-field="collect_type" caption="수금구분">
                    <dx-lookup
                      :data-source="vars.common.collect_type"
                      value-expr="code_name"
                      display-expr="code_name"
                    />
                  </dx-column>
                  <dx-column
                    data-field="collect_date"
                    caption="수금일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="collect_price"
                    caption="수금액"
                    data-type="number"
                    format="currency"
                  />
                  <dx-column
                    data-field="fk_project_management_id"
                    caption="프로젝트아이디"
                    :visible="false"
                  />
                  <dx-editing
                    :allow-adding="!vars.formState.readOnly"
                    :allow-updating="!vars.formState.readOnly"
                    :allow-deleting="!vars.formState.readOnly"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="첨부파일">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 600px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'attachment')
                  "
                  :remote-operations="true"
                  :data-source="vars.attachment.item"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :focused-row-enabled="true"
                  @row-removing="methods.onAttachmentRemoving"
                  @cell-click="methods.onAttachmentClick"
                  @saving="methods.onSavingItem"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item
                      template="addFile"
                      :visible="vars.formData.id & !vars.formState.readOnly"
                    />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                  </dx-grid-toolbar>
                  <template #addFile>
                    <dx-file-uploader
                      select-button-text="파일 선택"
                      label-text=""
                      :multiple="true"
                      :showFileList="false"
                      :uploadUrl="vars.attachment.uploadUrl"
                      :onValueChanged="methods.onValueChanged"
                      :onUploaded="methods.onUploaded"
                    />
                  </template>

                  <dx-column
                    data-field="file_name"
                    caption="파일명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="upload_date"
                    caption="업로드일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                    :allow-editing="false"
                  />
                  <dx-column data-field="note" caption="비고" />
                  <dx-column
                    data-field="fk_project_management_id"
                    caption="프로젝트아이디"
                    :visible="false"
                  />
                  <dx-editing
                    :allow-adding="false"
                    :allow-updating="!vars.formState.readOnly"
                    :allow-deleting="!vars.formState.readOnly"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'add-popup')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-paging :page-size="20" />
          <dx-selection
            select-all-mode="page"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      width="60%"
      height="60%"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'find-popup')"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-else-if="vars.dlg.finder.key === 'address'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-item
          v-else-if="vars.dlg.finder.key === 'item'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="440"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.findAddress.popup.value"
      content-template="popup-content"
      title="주소찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'find-address-popup')"
    >
      <template #popup-content>
        <div>
          <div style="margin-bottom: 10px">
            <dx-text-box
              v-model="vars.findAddress.keyword"
              :buttons="vars.findAddress.textBoxOptions"
              @enter-key="methods.findAddressSubmit"
            />
          </div>
          <dx-data-grid
            :height="340"
            :data-source="vars.findAddress.store"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :remote-operations="true"
            @initialized="evt => methods.onGridInitialized(evt, 'find-address')"
            @row-click="methods.findAddressSelect"
          >
            <dx-column data-field="road" caption="도로명주소" />
            <dx-column data-field="jibun" caption="지번주소" />
            <dx-column data-field="zip" caption="우편번호" />
            <dx-paging :page-size="20" />
          </dx-data-grid>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxEmptyItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSelection,
  DxFilterRow,
  DxPaging,
  DxLookup,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxTextBox from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import FindAddressStore from '../../data-source/find-address';
import stateStore from '@/utils/state-store';
import {
  projectRegistration,
  getProjectRegistration,
  getProjectItem,
  getProjectGuarantee,
  getProjectInsurance,
  getProjectCollect,
  getProjectAttachment,
} from '../../data-source/project';
import {
  baseCodeLoader,
  baseClient,
  baseItem,
  baseDepartment,
  baseEmployee,
} from '../../data-source/base';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridProject from '../../components/project/data-project.vue';
import DataGridItem from '../../components/base/data-item.vue';
import authService from '../../auth';
import ApiService from '../../utils/api-service';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxSelection,
    DxFilterRow,
    DxLabel,
    DxTextBox,
    DxButton,
    DxFileUploader,
    DxGroupItem,
    DxSimpleItem,
    DxEmptyItem,
    DxTabPanel,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxLookup,
    DxPopup,
    DxToolbarItem,
    DxScrolling,
    DxColumnChooser,
    DxPaging,
    DataGridClient,
    DataGridProject,
    DataGridItem,
    DxRequiredRule,
    DxGridToolbar,
    DxGridItem,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = {
      guarantee: null,
      insurance: null,
      collect: null,
      attachment: null,
    };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.common = reactive({
      guarantee_type: [],
      publish_status: [],
      insurance_type: [],
      request_start: [],
      start_status: [],
      collect_type: [],
      contract_type: [],
    });
    vars.formData = reactive({
      id: null,
      created: '',
      project_number: '',
      contract_name: '',
      order_company: '',
      contract_date: '',
      contract_begin_date: '',
      contract_end_date: '',
      contract_price: 0,
      register_department: '',
      register_manager: '',
      register_date: '',
      sales_department: '',
      sales_manager: '',
      contract_type: '',
      business_department: '',
      business_manager: '',
      site_address: '',
      note: '',
      allocation_amount: 0,
      use_price: 0,
      use_department: '',
      use_date: '',
      fk_company_id: authService._user.fk_company_id,
    });

    vars.toolbar = reactive({
      allowAdding: false,
    });
    vars.filter = reactive({
      items: [
        {
          name: 'fk_project_management_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
      guarantee: [
        {
          name: 'fk_project_management_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
      insurance: [
        {
          name: 'fk_project_management_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
      collect: [
        {
          name: 'fk_project_management_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
      attachment: [
        {
          name: 'fk_project_management_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
    });
    vars.dataSource = reactive({
      baseItem: null,
    });
    vars.items = reactive({
      item: getProjectItem(vars.filter.items),
    });
    vars.guarantee = reactive({
      item: getProjectGuarantee(vars.filter.guarantee),
    });
    vars.insurance = reactive({
      item: getProjectInsurance(vars.filter.insurance),
    });
    vars.collect = reactive({
      item: getProjectCollect(vars.filter.collect),
    });
    vars.attachment = reactive({
      item: getProjectAttachment(vars.filter.attachment),
      files: null,
      uploadUrl: '',
    });
    vars.department = reactive({
      item: null,
    });
    vars.employee = reactive({
      regItem: null,
      salesItem: null,
      businessItem: null,
    });
    vars.step = reactive({
      item: [
        { name: '영업' },
        { name: '제안' },
        { name: '계약' },
        { name: '설계' },
        { name: '제작' },
        { name: '납품' },
        { name: '설치' },
        { name: '완료' },
      ],
    });
    vars.disabled = reactive({
      edit: true,
      delete: true,
      save: true,
    });

    baseCodeLoader([
      '보증종류',
      '발행상태',
      '보험종류',
      '수금구분',
      '계약종류',
      '개시요청',
      '개시상태',
    ])
      .then(response => {
        vars.common.guarantee_type = response['보증종류'];
        vars.common.publish_status = response['발행상태'];
        vars.common.insurance_type = response['보험종류'];
        vars.common.collect_type = response['수금구분'];
        vars.common.contract_type = response['계약종류'];
        vars.common.request_start = response['개시요청'];
        vars.common.start_status = response['개시상태'];
      })
      .then(() => (vars.init.value = true));

    vars.findAddress = {
      popup: ref(false),
      store: new FindAddressStore(),
      keyword: null,
      columnOptions: {
        buttons: [
          {
            name: 'icon',
            location: 'after',
            options: {
              stylingMode: 'text',
              icon: 'search',
              onClick: () => {
                vars.findAddress.keyword = vars.formData.site_address;
                vars.findAddress.popup.value = true;
              },
            },
          },
        ],
      },
      textBoxOptions: [
        {
          name: 'icon',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'search',
            onClick: () => {
              methods.findAddressSubmit();
            },
          },
        },
      ],
    };

    onMounted(async () => {
      methods.loadBaseItem();
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        baseDepartment
          .load({
            filter: [['fk_company_id', '=', authService._user.fk_company_id]],
          })
          .then(({ data }) => {
            vars.department.item = data;
          });

        vars.init.value = true;

        methods.gridItemsRefresh(id);
        methods.gridGuaranteeRefresh(id);
        methods.gridInsuranceRefresh(id);
        methods.gridCollectRefresh(id);
        methods.gridAttachmentRefresh(id);

        if (!id) {
          vars.toolbar.allowAdding = false;
          methods.clearFormData();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          return;
        }

        let { data } = await projectRegistration.byKey(id);
        Object.assign(vars.formData, data);

        vars.disabled.edit = false;
        methods.enableDelete();
        methods.enableSave();

        vars.toolbar.allowAdding = true;
        vars.attachment.uploadUrl =
          '/api/server/v1/project-attachment/' +
          authService._user.fk_company_id +
          '/' +
          id;
      },
      clearFormData() {
        vars.formData.id = null;
        vars.formData.created = '';
        vars.formData.project_number = '';
        vars.formData.contract_name = '';
        vars.formData.order_company = '';
        vars.formData.contract_date = '';
        vars.formData.contract_begin_date = '';
        vars.formData.contract_end_date = '';
        vars.formData.contract_price = 0;
        vars.formData.register_department = '';
        vars.formData.register_manager = '';
        vars.formData.register_date = '';
        vars.formData.sales_department = '';
        vars.formData.sales_manager = '';
        vars.formData.contract_type = '';
        vars.formData.business_department = '';
        vars.formData.business_manager = '';
        vars.formData.site_address = '';
        vars.formData.note = '';
        vars.formData.allocation_amount = 0;
        vars.formData.use_price = 0;
        vars.formData.use_department = '';
        vars.formData.use_date = '';
        vars.formData.etc = '';
        vars.formData.fk_company_id = authService.getCompanyId();
      },
      generateItemSelectOption(items = [], value = '', searchEnabled = false) {
        return { value, searchEnabled, items };
      },
      generateItemButtonOption(
        icon,
        callback,
        location = 'after',
        options = {}
      ) {
        let buttonOptions = { stylingMode: 'text', icon, onClick: callback };
        return {
          ...options,
          buttons: [{ name: icon, location, options: buttonOptions }],
        };
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newItem() {
        methods.gridItemsRefresh();
        methods.gridGuaranteeRefresh();
        methods.gridInsuranceRefresh();
        methods.gridCollectRefresh();
        methods.gridAttachmentRefresh();
        if (vars.formData.id) {
          methods.clearFormData();
          methods.redirect();
        }
        setTimeout(() => {
          methods.clearFormData();
          vars.formData.contract_date = new Date();
          vars.formData.step = '영업';
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editItem() {
        if (!vars.formData.id) return;
        if (vars.formState.readOnly) {
          let isSelect = await confirm('수정하시겠습니까?', '수정');
          if (!isSelect) {
            return;
          }
        }

        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        methods.enableSave();
        methods.enableDelete();

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async gridItemsRefresh(id) {
        if (!id) id = 0;
        vars.filter.items[0].val = id;
        vars.items.item.defaultFilters = vars.filter.items;
        if (vars.grid.items) {
          vars.grid.items.cancelEditData();
          vars.grid.items.refresh();
        }
      },
      async gridGuaranteeRefresh(id) {
        if (!id) id = 0;
        vars.filter.guarantee[0].val = id;
        vars.guarantee.item.defaultFilters = vars.filter.guarantee;
        if (vars.grid.guarantee) {
          vars.grid.guarantee.cancelEditData();
          vars.grid.guarantee.refresh();
        }
      },
      async gridInsuranceRefresh(id) {
        if (!id) id = 0;
        vars.filter.insurance[0].val = id;
        vars.insurance.item.defaultFilters = vars.filter.insurance;
        if (vars.grid.insurance) {
          vars.grid.insurance.cancelEditData();
          vars.grid.insurance.refresh();
        }
      },
      async gridCollectRefresh(id) {
        if (!id) id = 0;
        vars.filter.collect[0].val = id;
        vars.collect.item.defaultFilters = vars.filter.collect;
        if (vars.grid.collect) {
          vars.grid.collect.cancelEditData();
          vars.grid.collect.refresh();
        }
      },
      async gridAttachmentRefresh(id) {
        if (!id) id = 0;
        vars.filter.attachment[0].val = id;
        vars.attachment.item.defaultFilters = vars.filter.attachment;
        if (vars.grid.attachment) {
          vars.grid.attachment.cancelEditData();
          vars.grid.attachment.refresh();
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'project': {
            methods.redirect(data.id);
            vars.formState.readOnly = true;
            break;
          }
          case 'item': {
            console.log(data);
            vars.formData.contract_item = data.item_name;
            break;
          }
          case 'client': {
            console.log(data);
            vars.formData.order_company = data.name;
            break;
          }
          case 'address': {
            console.log(data);
            vars.formData.site_address =
              data.address + ' ' + data.address_detail;
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      async saveItem() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            const updateDate = Object.assign({}, vars.formData);
            delete updateDate.created;
            delete updateDate.project_number;
            const { data } = await projectRegistration.update(
              vars.formData.id,
              updateDate
            );
            vars.formData.project_number = data.project_number;

            const items = vars.grid.items;
            if (items) await items.saveEditData();
            const guarantee = vars.grid.guarantee;
            if (guarantee) await guarantee.saveEditData();
            const insurance = vars.grid.insurance;
            if (insurance) await insurance.saveEditData();
            const collect = vars.grid.collect;
            if (collect) await collect.saveEditData();
            const attachment = vars.grid.attachment;
            if (attachment) await attachment.saveEditData();

            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');

            methods.enableSave();
            methods.enableDelete();
          } else {
            // 채번이 없을 경우 자동 채번
            if (vars.formData.created) vars.formData.created = null;
            let { data } = await projectRegistration.insert(vars.formData);
            vars.formData.id = data.id;

            const items = vars.grid.items;
            if (items && items.hasEditData()) {
              await items.saveEditData();
            }
            const guarantee = vars.grid.guarantee;
            if (guarantee && guarantee.hasEditData()) {
              await guarantee.saveEditData();
            }
            const insurance = vars.grid.insurance;
            if (insurance && insurance.hasEditData()) {
              await insurance.saveEditData();
            }
            const collect = vars.grid.collect;
            if (collect && collect.hasEditData()) {
              await collect.saveEditData();
            }
            const attachment = vars.grid.attachment;
            if (attachment && attachment.hasEditData()) {
              await attachment.saveEditData();
            }

            methods.redirect(data.id);
            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 견적번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      selectRegDepartment(e) {
        const selectItem = e.component.option('selectedItem');
        if (selectItem) {
          baseEmployee
            .load({
              filter: [
                ['fk_company_id', '=', authService._user.fk_company_id],
                ['fk_department_id', '=', selectItem.id],
              ],
              skip: 0,
              take: 1000,
            })
            .then(({ data }) => {
              vars.employee.regItem = data;
            });
        }
      },
      selectSalesDepartment(e) {
        const selectItem = e.component.option('selectedItem');
        if (selectItem) {
          baseEmployee
            .load({
              filter: [
                ['fk_company_id', '=', authService._user.fk_company_id],
                ['fk_department_id', '=', selectItem.id],
              ],
              skip: 0,
              take: 1000,
            })
            .then(({ data }) => {
              vars.employee.salesItem = data;
            });
        }
      },
      selectBusinessDepartment(e) {
        const selectItem = e.component.option('selectedItem');
        if (selectItem) {
          baseEmployee
            .load({
              filter: [
                ['fk_company_id', '=', authService._user.fk_company_id],
                ['fk_department_id', '=', selectItem.id],
              ],
              skip: 0,
              take: 1000,
            })
            .then(({ data }) => {
              vars.employee.businessItem = data;
            });
        }
      },
      async onAttachmentRemoving(e) {
        const apiService = new ApiService('/api/server/v1/project-attachment');
        return await apiService.post(`remove/${e.data.id}`);
      },
      onAttachmentClick(e) {
        if (e.columnIndex == 0) {
          // download
        }
      },
      onAttachmentToolbarPreparing(evt) {
        /*
        evt.toolbarOptions.items.find(
          v => v.name === 'addRowButton'
        ).options.onClick = methods.showAddPopup;
        */
        evt.toolbarOptions.items.push({
          widget: 'dxFileUploader',
          options: {
            icon: 'add',
            selectButtonText: '파일 선택',
            labelText: '',
            width: '120px',
            multiple: true,
            showFileList: false,
            uploadUrl: vars.attachment.uploadUrl,
            onValueChanged: methods.onValueChanged,
            onUploaded: methods.onUploaded,
          },
          location: 'after',
        });
      },
      onValueChanged(e) {
        //vars.attachment.files = e.value;
      },
      onUploaded(e) {
        vars.grid.attachment.refresh();
      },
      findAddressSubmit() {
        vars.findAddress.store.keyword = vars.findAddress.keyword;
        vars.grid['find-address'].refresh();
      },
      findAddressSelect({ data }) {
        vars.formData.site_address = data.road;
        vars.findAddress.popup.value = false;
      },
      async deleteItem() {
        if (!vars.formData.id) {
          return;
        }

        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await projectRegistration.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          methods.redirect();
          vars.formState.readOnly = true;

          methods.gridItemsRefresh();
          methods.gridGuaranteeRefresh();
          methods.gridInsuranceRefresh();
          methods.gridCollectRefresh();
          methods.gridAttachmentRefresh();
        }
      },
      redirect(id) {
        if (id) router.replace({ path: `/project/registration/${id}` });
        else router.replace({ path: `/project/registration` });
      },
      onContractChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
        } else {
          methods.enableSave();
        }
      },
      async addSelectedRows() {
        const grid = vars.grid.items;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          await grid.addRow();
          /*
          grid.cellValue(0, 'quantity', 0); // 수량
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item_standard); // 규격
          grid.cellValue(0, 'item.fk_project_management_id', props.id); // 프로젝트번호
          */
          const data = await grid.byKey(grid.getKeyByRowIndex(0));
          data.quantity = 0; // 수량
          data.not_ordered = 0; // 미수주수량
          data.item_code = row.item_code; // 품목코드
          data.item = { ...row }; // 품목
          data.fk_project_management_id = props.id; // 프로젝트번호
        }
        vars.dlg.addItem.show = false;
      },
      showAddPopup() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addItem.show = true;
      },
      loadBaseItem() {
        baseItem
          .load({
            filter: [['fk_company_id', '=', authService._user.fk_company_id]],
          })
          .then(({ data }) => {
            vars.dataSource.baseItem = data;
          });
      },
      setQuantity(newData, value, currentRowData) {
        const diff = value - currentRowData.quantity;
        const notOrdered = currentRowData.not_ordered + diff;
        if (notOrdered >= 0) {
          newData.not_ordered = notOrdered;
          newData.quantity = value;
        }
      },
      enableDelete() {
        if (vars.formState.readOnly) {
          vars.disabled.delete = true;
        } else {
          vars.disabled.delete = false;
        }
      },
      enableSave() {
        if (vars.formState.readOnly) {
          vars.disabled.save = true;
        } else {
          vars.disabled.save = false;
        }
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_project_management_id = vars.formData.id;
          }
        });
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      baseItem,
      baseClient,
    };
  },
};
</script>

<style lang="scss">
.dx-fileuploader-wrapper {
  padding: 0px;
  margin: 0px;
}
.dx-fileuploader-input-wrapper {
  padding: 0px;
  margin: 0px;
}
</style>
