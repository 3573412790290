<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">재고이동요청</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'add',
                icon: 'add',
                disabled: vars.disabled.new,
                onClick: methods.newItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'rename',
                icon: 'rename',
                disabled: vars.disabled.edit,
                onClick: methods.editItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'remove',
                icon: 'remove',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'save',
                icon: 'save',
                disabled: vars.disabled.save,
                onClick: methods.saveItem,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  placeholder: '(자동 or 직접입력)',
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn(
                      'stock-move-request',
                      '재고이동요청조회'
                    )
                  ),
                }"
              >
                <dx-label text="이동요청번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="요청일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <div class="mt-2">
          <dx-data-grid
            class="fixed-header-table"
            height="calc(100vh - 286px)"
            :on-initialized="evt => methods.onGridInitialized(evt, 'item1')"
            :remote-operations="true"
            :data-source="vars.stockMoveRequestItem"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :select-text-on-edit-start="true"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            :disabled="vars.disabled.items"
            @saving="methods.onSavingItem"
            @data-error-occurred="methods.onDataError"
            @cell-dbl-click="methods.itemPopupClick"
            @focused-cell-changed="methods.onFocusedCellChanged"
          >
            <dx-grid-toolbar>
              <dx-grid-item
                name="addRowButton"
                :options="{ onClick: methods.showAddPopup }"
              />
              <dx-grid-item name="saveButton" :visible="!!vars.formData.id" />
              <dx-grid-item name="revertButton" />
              <dx-grid-item name="columnChooserButton" />
            </dx-grid-toolbar>

            <dx-column
              data-field="item_code"
              caption="품목코드"
              width="180"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_name"
              caption="품명"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_standard"
              caption="규격"
              :allow-editing="false"
            />
            <dx-column
              data-field="quantity"
              caption="수량"
              data-type="number"
              format="fixedPoint"
            />
            <dx-column
              data-field="item.unit"
              caption="단위"
              :allow-editing="false"
            />
            <dx-column
              data-field="unit_price"
              caption="단가"
              data-type="number"
              format="currency"
            />
            <dx-column
              data-field="supply_price"
              caption="금액"
              data-type="number"
              format="currency"
              :allow-editing="false"
              :calculate-cell-value="methods.calcSupplyPrice"
            />
            <dx-column data-field="out_warehouse" caption="출고창고">
              <dx-lookup
                :data-source="vars.dataSource.warehouse"
                value-expr="wh_code"
                display-expr="wh_name"
              />
            </dx-column>
            <dx-column data-field="in_warehouse" caption="입고창고">
              <dx-lookup
                :data-source="vars.dataSource.warehouse"
                value-expr="wh_code"
                display-expr="wh_name"
              />
            </dx-column>

            <dx-column
              data-field="project_number"
              caption="프로젝트번호"
              :editor-options="{
                ...generateItemButtonOption(
                  'search',
                  methods.createFindPopupFn('project', '프로젝트조회')
                ),
              }"
            />
            <dx-column data-field="note" caption="참고" />
            <dx-column
              data-field="item.asset_type"
              caption="자산구분"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.main_category"
              caption="대분류"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.middle_category"
              caption="중분류"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.sub_category"
              caption="소분류"
              :allow-editing="false"
            />
            <dx-column
              data-field="not_shipped"
              caption="미출고수량"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="fk_stock_move_request_id"
              caption="재고이동요청 FK"
              :visible="false"
              :allow-editing="false"
              :show-in-column-chooser="false"
            />

            <dx-editing
              :allow-adding="!vars.formState.readOnly"
              :allow-updating="!vars.formState.readOnly"
              :allow-deleting="!vars.formState.readOnly"
              mode="batch"
            />
            <dx-scrolling mode="standard" />
            <dx-column-chooser mode="select" :enabled="true" />
          </dx-data-grid>
        </div>
        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가:</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세:</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액:</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'base-item-popup')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-paging :page-size="20" />
          <dx-selection
            select-all-mode="page"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'multiuse-popup')"
    >
      <template #popup-content>
        <data-stock-move-request
          v-if="vars.dlg.finder.key === 'stock-move-request'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>

  <popup-item-detail
    v-model:visible="vars.itemDetail.visible"
    :item-id="vars.itemDetail.id"
  />
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import { stockMoveRequest, getStockMoveRequestItem } from '@/data-source/stock';
import {
  baseCodeLoader,
  baseClient,
  baseItem,
  getBaseItem,
} from '../../data-source/base';
import { shipmentOrderItem } from '../../data-source/shipment';
import DataGridClient from '../../components/base/data-client.vue';
import DataStockMoveRequest from '../../components/stock/data-stock-move-request';
import DataGridProject from '../../components/project/data-project.vue';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import {
  loadDepartment,
  loadEmployee,
  loadWarehouse,
} from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';
import { getStock } from '../../data-source/setup';
import PopupItemDetail from '@/components/base/popup-item-detail';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxGridToolbar,
    DxGridItem,
    DxButton,
    DxRequiredRule,
    DxLookup,
    DataGridClient,
    DataStockMoveRequest,
    DataGridProject,
    PopupItemDetail,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, item1: null };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.warehouseName = '';
    vars.filter = {
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      item1: [
        { name: 'fk_stock_move_request_id', op: 'eq', val: props.id || 0 },
      ],
    };
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      type: [],
      type2: [],
      vat_type: [],
      department: [],
      employee: [],
      warehouse: [],
      baseItem: null,
    });
    vars.focus = reactive({
      item1: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.stockMoveRequestItem = getStockMoveRequestItem(vars.filter.item1);

    vars.itemDetail = reactive({ visible: false, id: 0 });

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      await loadWarehouse(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.created = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await stockMoveRequest.byKey(id);
        Object.assign(vars.formData, data);
        console.log(vars.filter.item1);
        vars.filter.item1[0].val = vars.formData.id;
        vars.stockMoveRequestItem.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) vars.grid.item1.refresh();

        methods.enableDelete();
        vars.disabled.edit = false;
        if (vars.formData.department) {
          methods.enableSave();
          vars.disabled.items = false;
        }
      },
      async addSelectedRows() {
        const grid = vars.grid.item1;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          const basicStock = row.basic_stock;
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item_standard); // 규격
          grid.cellValue(0, 'quantity', 0); // 수량
          grid.cellValue(0, 'unit_price', row.purchase_price);
          grid.cellValue(0, 'item.unit', row.unit);
          if (basicStock) {
            grid.cellValue(0, 'available_stock', basicStock.available_stock); // 가용재고
            grid.cellValue(0, 'current_stock', basicStock.current_stock); // 현재고
            grid.cellValue(0, 'warehouse_code', basicStock.wh_code); // 창고코드
            grid.cellValue(0, 'inout_warehouse', basicStock.wh_code); // 창고코드
          } else {
            grid.cellValue(0, 'available_stock', 0); // 가용재고
            grid.cellValue(0, 'current_stock', 0); // 현재고
          }
          grid.cellValue(0, 'item.asset_type', row.asset_type); // 자산구분
          grid.cellValue(0, 'item.main_category', row.main_category); // 대분류
          grid.cellValue(0, 'item.middle_category', row.middle_category); // 중분류
          grid.cellValue(0, 'item.sub_category', row.sub_category); // 소분류
          grid.cellValue(0, 'not_shipped', row.not_shipeed); // 미출고수량
        }
        vars.dlg.addItem.show = false;
      },
      generateItemSelectOption(items = [], value = '', searchEnabled = false) {
        return { value, searchEnabled, items };
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddPopup() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newItem() {
        if (vars.grid.item1) {
          vars.filter.item1[0].val = 0;
          vars.stockMoveRequestItem.defaultFilters = vars.filter.item1;
          vars.grid.item1.refresh();
        }
        if (vars.formData.id) {
          router.replace('/stock/move-request');
          vars.formData.id = null;
          vars.formData.created = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editItem() {
        if (!vars.formData.id) return;
        if (vars.formState.readOnly) {
          let isSelect = await confirm('수정하시겠습니까?', '수정');
          if (!isSelect) {
            return;
          }
        }

        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        methods.enableSave();
        methods.enableDelete();

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          try {
            await stockMoveRequest.remove(vars.formData.id);
            await alert('삭제되었습니다', '삭제 확인');
            router.replace({ path: '/stock/move-request' });
            vars.formState.readOnly = true;
          } catch (ex) {
            if (ex.response.status != 403) {
              await alert(
                '연결된 데이터가 있어서 삭제가 안됩니다',
                '삭제 확인'
              );
            }
          }
        }
      },
      async saveItem() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            const updateValues = Object.assign({}, vars.formData);
            delete updateValues.id;
            delete updateValues.total_price;
            delete updateValues.supply_price;
            delete updateValues.vat;
            await stockMoveRequest.update(vars.formData.id, updateValues);
            if (vars.grid.item1) await vars.grid.item1.saveEditData();

            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');

            methods.enableSave();
            methods.enableDelete();
          } else {
            // 채번이 없을 경우 자동 채번
            const updateValues = Object.assign({}, vars.formData);
            delete updateValues.id;
            delete updateValues.total_price;
            delete updateValues.supply_price;
            delete updateValues.vat;
            let { data } = await stockMoveRequest.insert(updateValues);
            vars.formData.id = data.id;

            const gridItem1 = vars.grid.item1;
            if (gridItem1 && gridItem1.hasEditData()) {
              await gridItem1.saveEditData();
            }

            router.replace({ path: `/stock/move-request/${data.id}` });
            vars.formState.readOnly = true;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 기타입출고번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'stock-move-request': {
            router.replace({ path: `/stock/move-request/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'project': {
            vars.grid.item1.cellValue(
              vars.focus.item1.rowIndex,
              vars.focus.item1.columnIndex,
              data.project_number
            );
            break;
          }
          case 'etc': {
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();
      },
      onFocusedCellChanged(e) {
        vars.focus.item1 = e;
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_stock_move_request_id = vars.formData.id;
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
            delete element.data.warehouse;
            delete element.data.inout_warehouse;
            if (element.type === 'insert') {
              element.data.not_shipped = element.data.quantity;
            }
          }
        });
      },
      onDataError(e) {
        if (e.error.response.status == 605) {
          e.error.message = '연결된 데이터가 있어서 삭제가 안됩니다';
        } else if (e.error.response.status == 403) {
          e.error.message = '권한이 없습니다';
        }
      },
      async setWarehouse(newData, value, currentRowData) {
        const warehouseList = vars.dataSource.warehouse;
        for (let i = 0; i < warehouseList.length; i++) {
          if (warehouseList[i].wh_name == value) {
            newData.warehouse = {};
            Object.assign(newData.warehouse, warehouseList[i]);
            newData.warehouse_code = warehouseList[i].wh_code;

            const { currentStock, availableStock } = await getStock(
              currentRowData.item_code,
              newData.warehouse_code
            );
            newData.current_stock = currentStock;
            newData.available_stock = availableStock;
            console.log(currentStock);
            break;
          }
        }
      },
      setQuantity(newData, value, currentRowData) {
        newData.quantity = value;
        newData.unit_price = currentRowData.unit_price;
      },
      setUnitPrice(newData, value, currentRowData) {
        newData.quantity = currentRowData.quantity;
        newData.unit_price = value;
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.quantity && rowData.unit_price) {
          supply_price = rowData.quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total = sumSupplyPrice(vars.grid.item1, 'quantity', 'unit_price');

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseCode() {
        return baseCodeLoader([
          '부가세구분',
          '입출고구분',
          '입출고유형',
          '결재조건',
        ])
          .then(response => {
            vars.dataSource.payment_terms = response['결재조건'];
            vars.dataSource.type = response['입출고구분'];
            vars.dataSource.type2 = response['입출고유형'];
            vars.dataSource.vat_type = response['부가세구분'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          methods.enableSave();
          vars.disabled.items = false;
        }
      },
      itemPopupClick({ column, data }) {
        if (column.name === 'item_code') {
          vars.itemDetail.id = data.item.id;
          vars.itemDetail.visible = true;
        }
      },
      enableDelete() {
        if (vars.formState.readOnly) {
          vars.disabled.delete = true;
        } else {
          vars.disabled.delete = false;
        }
      },
      enableSave() {
        if (vars.formState.readOnly) {
          vars.disabled.save = true;
        } else {
          vars.disabled.save = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      baseItem,
      baseClient,
      shipmentOrderItem,
      generateItemButtonOption,
    };
  },
};
</script>
