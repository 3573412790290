<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">수불집계</div>
            </dx-item>
          </dx-toolbar>
        </div>
        <div v-if="commonInit">
          <div class="search-status">
            <span class="search-title">일자</span>
            <dx-date-box v-model:value="formdata.startdate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="formdata.enddate" />
            <span class="search-tab"></span>
            <span class="search-bar">창고선택</span>
            <dx-lookup
              v-model:value="formdata.wh_name"
              :data-source="dataSource.warehouse"
              value-expr="wh_name"
              display-expr="wh_name"
            />
            <span class="search-bar">자산구분</span>
            <dx-lookup
              v-model:value="formdata.asset_type"
              :data-source="dataSource.assettype"
              value-expr="code_name"
              display-expr="code_name"
            />
            <span class="search-tab"></span>
            <dx-button text="검색" icon="search" @click="searchDateRange()" />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          key-expr="id"
          height="calc(100vh - 240px)"
          :data-source="dataSource.status"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="false"
          @initialized="evt => initialized(evt, 'status-list')"
          @row-dbl-click="goDetail"
        >
          <dx-column
            data-field="asset_type"
            caption="자산구분"
            :filter-operations="['contain', '=']"
          />
          <dx-column caption="품목코드" data-field="item_code" />
          <dx-column caption="품명" data-field="item_name" />
          <dx-column caption="규격" data-field="item_standard" />
          <dx-column caption="이월재고" data-field="past_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="입고량" data-field="receiving_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="출고량" data-field="release_stock" data-type="number"  format="fixedPoint"/>
          <dx-column caption="마감재고" data-field="closing_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="가용재고" data-field="available_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="안전재고" data-field="safety_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="품목대분류" data-field="main_category" />
          <dx-column caption="품목중분류" data-field="middle_category" />
          <dx-column caption="품목소분류" data-field="sub_category" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>

    <dx-popup
      v-model:visible="dialog"
      content-template="popup-content"
      title="상품수불내역"
      :width="820"
      :height="660"
      :resize-enabled="true"
      @initialized="evt => initialized(evt, 'item-popup')"
    >
      <template #popup-content>
        <dx-data-grid
          :data-source="dataSource.detail"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
          @initialized="evt => initialized(evt, 'payment-status-detail')"
        >
          <dx-column caption="수불일자" data-field="action_date" data-type="date" format="yyyy-MM-dd" />
          <dx-column caption="입출고유형" data-field="inout_type" />
          <dx-column caption="업체" data-field="company" />
          <dx-column caption="단가" data-field="unit_price" data-type="number" format="currency" />
          <dx-column caption="이월재고" data-field="past_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="입고수량" data-field="receiving_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="출고수량" data-field="release_stock" data-type="number" format="fixedPoint" />
          <dx-column caption="누적재고" data-field="sum_by_case" data-type="number" format="fixedPoint" />
          <dx-column caption="프로젝트번호" data-field="project_number" />
          
          <dx-paging :page-size="10" />
        </dx-data-grid>

        <table class="summary-table">
          <tr>
            <td>누계</td>
            <th>이월재고</th>
            <td>{{ dataSource.sum.past_stock }}</td>
            <th>입고수량</th>
            <td>{{ dataSource.sum.receiving_stock }}</td>
            <th>출고수량</th>
            <td>{{ dataSource.sum.release_stock }}</td>
            <th>누적재고</th>
            <td>{{ dataSource.sum.sum_by_case }}</td>
          </tr>
        </table>
      </template>
    </dx-popup>
  </div>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxLookup from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import ApiService from '../../utils/api-service';
import { ref, reactive } from 'vue';
import { baseCodeLoader } from '../../data-source/base';
import authService from '../../auth';
import { loadWarehouse } from '../../utils/data-loader';
import moment from 'moment';

const api = new ApiService('/api/mes/v1/stock/statistics');
const commonInit = ref(false);
const dialog = ref(false);
const startdate = new Date();
startdate.setDate(startdate.getDate() - 7);

const formdata = reactive({
  startdate: startdate,
  enddate: new Date(),
  wh_name: '',
  asset_type: '전체',
});

const components = {};

const dataSource = reactive({
  warehouse: [],
  assettype: [],
  status: [],
  detail: [],
  sum: {
    past_stock: 0,
    receiving_stock: 0,
    release_stock: 0,
    sum_by_case: 0,
  },
});

!(async () => {
  await baseCodeLoader(['자산구분'], authService.getCompanyId()).then(
    response => {
      dataSource.assettype = response['자산구분'];
      dataSource.assettype.unshift({ code_name: '전체' });
    }
  );

  await loadWarehouse(dataSource);
  //dataSource.warehouse.unshift({'wh_name':'전체'});

  formdata.wh_name = dataSource.warehouse[0].wh_name;
  formdata.asset_type = '전체';

  commonInit.value = true;
})();

function initialized(evt, key) {
  components[key] = evt.component;
}

async function goDetail({ data }) {
  const params = {
    start: moment(formdata.startdate).format('YYYYMMDD'),
    end: moment(formdata.enddate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  };
  const { data: response } = await api.get(String(data.item_id), { params });

  let sumByCase = 0;
  response.objects.forEach(row => {
    sumByCase =
      sumByCase + (parseInt(row.past_stock, 10) + row.receiving_stock - row.release_stock);
    row.sum_by_case = sumByCase;
  });
  dataSource.detail = response.objects;
  dataSource.sum.past_stock = response.objects.reduce(
    (sum, row) => sum + parseInt(row.past_stock, 10),
    0
  );
  dataSource.sum.receiving_stock = response.objects.reduce(
    (sum, row) => sum + row.receiving_stock,
    0
  );
  dataSource.sum.release_stock = response.objects.reduce(
    (sum, row) => sum + row.release_stock,
    0
  );
  dataSource.sum.sum_by_case = sumByCase;
  dialog.value = true;
}

async function searchDateRange() {
  const params = {
    start: moment(formdata.startdate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    end: moment(formdata.enddate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    wh_name: formdata.wh_name === '전체' ? '' : formdata.wh_name,
    asset_type: formdata.asset_type === '전체' ? '' : formdata.asset_type,
  };
  const { data } = await api.get('', { params });
  dataSource.status = data.objects;
}
</script>

<style>
.summary-table {
  width: 100%;
  margin-top: 10px;
}
</style>
